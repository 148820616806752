import React, { useContext, useMemo, useState } from 'react'
import TaxonomyService, { Taxonomy } from '../services/taxonomy'
import { Link, useSearchParams } from 'react-router-dom'
import ProductService from '../services/product'
import { PrettyNumber } from './PrettyNumber'
import { Lightning, Nut } from '@phosphor-icons/react'
import Utils from '../services/utils'
import { TransportIcons } from './Icons/TransportIcons'
import { InventoryIcon } from './Icons/InventoryIcon'
import { ApplicationContext } from '../context'
import { SearchBox } from './Input/SearchBox'

export const TaxonomySidebar = (props: {
    currentTaxonomy: Taxonomy | null
    itemProps?: TaxonomyItemProps
    filterInputClassName?: string
    onSelect: (taxonomy: Taxonomy) => void
}) => {
    const context = useContext(ApplicationContext)
    const [filteringBy, setFilteringBy] = useState<string>()

    const levels = useMemo(
        () =>
            TaxonomyService.topLevel
                ?.filter((tlt) => tlt.productCount)
                ?.map((tlt) => (
                    <TaxonomyItem
                        key={`tx-${tlt.uuid}`}
                        {...props.itemProps}
                        taxonomy={tlt}
                        filteringBy={filteringBy}
                        currentTaxonomy={props.currentTaxonomy}
                    />
                )),
        [filteringBy, props.currentTaxonomy, props.itemProps, context.stores.ui?.taxonomyReady],
    )

    return (
        <>
            <SearchBox
                extraClassName='py-0 mb-1'
                inputFieldProps={{
                    placeholder: 'Filter categories',
                    onChange: (searchTerm) => setFilteringBy(searchTerm),
                }}
            />
            {levels}
        </>
    )
}

export type TaxonomyItemProps = {
    currentTaxonomy?: Taxonomy | null
    taxonomy?: Taxonomy
    filteringBy?: string
    className?: string
    extraClassName?: string
    activeClassName?: string
    showProductCount?: boolean
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>, taxonomy: Taxonomy) => void
}

export const TaxonomyItem = (props: TaxonomyItemProps) => {
    const [qs] = useSearchParams()

    const _queryString = useMemo(() => {
        const _qs = new URLSearchParams(qs)
        _qs.delete('page')
        return _qs
    }, [qs])

    const taxonomy = useMemo(() => TaxonomyService.getById(props.taxonomy?.uuid), [props.taxonomy?.uuid])

    const childNames = useMemo(() => {
        const getChildName = (taxonomy?: Taxonomy): string[] => {
            if (!taxonomy) return []
            const _childNames = taxonomy.children?.map((c) => c.name || '')
            if (!_childNames) return []
            return _childNames.concat(taxonomy.children?.map((c) => getChildName(c)).flat() || [])
        }
        const childNames = getChildName(taxonomy)
        return childNames.map((c) => c.toLowerCase()).join(' ')
    }, [taxonomy])

    const showChildren = useMemo(() => {
        if (!taxonomy) return false
        if (props.filteringBy) {
            return Utils.filterItem([taxonomy?.name, childNames], props.filteringBy)
        }
        return TaxonomyService.isInTaxonomyTree(taxonomy, props.currentTaxonomy)
    }, [taxonomy, props.currentTaxonomy, props.filteringBy, childNames])

    const icon = useMemo(() => {
        switch (taxonomy?.path) {
            case 'transport':
                return <TransportIcons className='nt--2 ns-1 flex-shrink-0' />
            case 'material':
                return <InventoryIcon className='nt--2 ns-1 flex-shrink-0' />
            case 'energy':
                return <Lightning className='nt--2 ns-1 flex-shrink-0' />
            case 'process':
                return <Nut className='nt--2 ns-1 flex-shrink-0' />
        }
        return null
    }, [taxonomy?.path])

    if (!taxonomy?.uuid) return null

    return (
        <>
            <Link
                to={`${ProductService.webRootDatabase}/${taxonomy.path}?${_queryString.toString()}`}
                onClick={(e) => props.onClick?.(e, taxonomy)}
                className={[
                    props.className ||
                        'd-flex align-items-center justify-content-start gap-2 px-2 py-1 bg-primary-hover rounded-2',
                    !props.filteringBy || showChildren ? 'd-flex' : 'd-none',
                    props.extraClassName || '',
                    TaxonomyService.isInTaxonomyTree(taxonomy, props.currentTaxonomy)
                        ? props.activeClassName || 'fw-bold'
                        : '',
                ].join(' ')}
            >
                {icon}
                {taxonomy.name}
                {props.showProductCount !== false && !!taxonomy.productCount && (
                    <PrettyNumber num={taxonomy.productCount} className='ms-auto' />
                )}
            </Link>
            <div className={['border-start ms-3', taxonomy.path?.split('/').length === 1 ? 'ps-2' : 'ps-1'].join(' ')}>
                {showChildren &&
                    taxonomy.children
                        ?.filter((c) => c.productCount)
                        ?.map((c) => (
                            <TaxonomyItem
                                key={`tx-item-${c.uuid}`}
                                {...props}
                                taxonomy={c}
                                filteringBy={props.filteringBy}
                            />
                        ))}
            </div>
        </>
    )
}
