import DromoReactUploader from 'dromo-uploader-react'
import { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react'
import { ActivityDirection } from '../../services/activity'
import { useNavigate } from 'react-router-dom'
import { DataRequest } from '../../services/dataRequest'
import DataImportService, { DataImportResponse } from '../../services/dataImport'
import TaxonomyService, { Taxonomy } from '../../services/taxonomy'
import { useDataImport } from '../../hooks/useDataImport'

export const LogisticsImport = (props: {
    children?: ReactNode
    showButton?: boolean
    className?: string
    dataRequest?: DataRequest
    defaultDirection?: ActivityDirection
    dromoRef: MutableRefObject<any>
    onDone?: (result: DataImportResponse) => void
    onCancel?: () => void
    onError?: (e: any) => void
}) => {
    const navigate = useNavigate()
    const dataImportService = useDataImport(props.defaultDirection)
    const [transportTaxonomies, setTransportTaxonomies] = useState<Taxonomy[]>()
    const [transportTaxonomy, setTransportTaxonomy] = useState<Taxonomy>()
    const IMPORT_TYPE = 'Activity: Logistics'

    useEffect(() => {
        const transport = TaxonomyService.getByPath('transport')
        setTransportTaxonomy(transport)
        setTransportTaxonomies(transport?.children)
    }, [])

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return 'Import data'
    }, [props.children, props.showButton])

    if (!transportTaxonomy || !transportTaxonomies) return null

    return (
        <DromoReactUploader
            schemaName='Logistics Import'
            schemaId='logistics-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            fields={[
                dataImportService.purchaseSaleField,
                dataImportService.transportTypeField(),
                dataImportService.dateField('Start Date', 'startDate'),
                dataImportService.dateField('End Date', 'endDate', true),
                dataImportService.descriptionField('Description'),
                dataImportService.companyNameField('Logistics Provider'),
                dataImportService.logisticsNumberOfShipmentsField,
                dataImportService.quantityField('Tonne Kilometers (tkm)'),
                dataImportService.taxonomySubCategory(transportTaxonomies),
                dataImportService.itemNameField('Emission Factor'),
                dataImportService.logisticsWeightInKgField,
                dataImportService.logisticsDistanceInKmField,
                dataImportService.logisticsAirKgCo2e,
                dataImportService.logisticsRoadKgCo2e,
                dataImportService.logisticsSeaKgCo2e,
                dataImportService.logisticsWarehouseKgCo2e,
                dataImportService.totalCo2eField('Total kgCO2e WtW'),
            ]}
            stepHooks={[
                dataImportService.uploadStepHook(IMPORT_TYPE),
                dataImportService.activityReviewStepHook(IMPORT_TYPE, props.dataRequest),
                dataImportService.addTaxonomyIdHook(),
            ]}
            columnHooks={[
                dataImportService.numberColumnHook(dataImportService.quantityField().key),
                dataImportService.numberColumnHook(dataImportService.logisticsNumberOfShipmentsField.key),
                dataImportService.numberColumnHook(dataImportService.logisticsWeightInKgField.key),
                dataImportService.numberColumnHook(dataImportService.logisticsDistanceInKmField.key),
                dataImportService.numberColumnHook(dataImportService.totalCo2eField().key),
                dataImportService.numberColumnHook(dataImportService.logisticsAirKgCo2e.key),
                dataImportService.numberColumnHook(dataImportService.logisticsRoadKgCo2e.key),
                dataImportService.numberColumnHook(dataImportService.logisticsSeaKgCo2e.key),
                dataImportService.numberColumnHook(dataImportService.logisticsWarehouseKgCo2e.key),
                dataImportService.dateColumnHook('endDate'),
                dataImportService.directionColumnHook(props.dataRequest),
                dataImportService.companyNameColumnHook(props.dataRequest),
                dataImportService.companyIdColumnHook(props.dataRequest),
                dataImportService.taxonomyColumnHook(transportTaxonomy),
            ]}
            settings={dataImportService.dromoSettings({
                importIdentifier: IMPORT_TYPE,
                templateDownloadFilename: 'Transport_Data_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={dataImportService.standardResult({
                importType: IMPORT_TYPE,
                dataRequest: props.dataRequest,
                navigate: navigate,
                onDone: props.onDone,
                onError: props.onError,
            })}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
