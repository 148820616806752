import { User } from './user-context'
import { Product } from './product'
import ActivityService, { ActivityDirection, ActivityItem } from './activity'
import Utils from './utils'
import { Company } from './company'
import { ND, VariableBaseNode } from '../types'
import { AnalyticsService } from './analytics'
import DataRequestService, { DataRequest } from './dataRequest'
import { Id, ToastContent, ToastOptions } from 'react-toastify'
import { Taxonomy } from './taxonomy'
import { NavigateFunction } from 'react-router-dom'
import { ActionMap, ApplicationContextInterface } from '../context'
import { DataImportNodes } from '../components/DataImport/DataImportNodes'
import Button from '../components/Input/Button'
import {
    IColumnHook,
    IDeveloperField,
    IDeveloperSettings,
    IResultMetadata,
    IStepHook,
    IUser,
    IValidatorField,
} from 'dromo-uploader-react'
import UnitService from './unit'
import VariableService from './service'
import { DataSource } from './dataSource'
import TransportService from './transport'
import PartService from './part'
import { UIOptionActionType } from './ui'
import { PrettyNumber } from '../components/PrettyNumber'
import { Input } from './input'

export type ImportableNode = Product | Company | ActivityItem | Input

export enum DataImportType {
    Unknown = 'unknown',
    Products = 'products',
    Materials = 'materials',
    Inputs = 'BOM',
    Suppliers = 'suppliers',
    Activity = 'activity',
    AirTravel = 'air-travel',
    Transport = 'transport',
}

export type DataImportStatus = 'created' | 'processing' | 'stopped' | 'done' | 'error'

export interface DataImportNode {
    type: ND
    node?: ImportableNode
    nodes?: ImportableNode[]
    count?: number
}

export interface DataImport extends VariableBaseNode {
    status?: DataImportStatus
    errorMessage?: string
    createdBy?: User
    totalCount?: number
    types?: string[]
    nodes?: DataImportNode[]
    syncType?: string
    importFor?: DataSource
    co2e?: number
    upstreamCo2e?: number
    downstreamCo2e?: number
}

export interface VariableDromoSettings {
    importIdentifier: string
    templateDownloadFilename?: string
}

export enum DataImportActionType {
    Set = 'SetDataImports',
    UpdateImport = 'UpdateDataImport',
    RemoveImportById = 'RemoveDataImportById',
}

export interface DataImportResponse {
    dataImport: DataImport
    imported: number
}

type DataImportActionPayload = {
    [DataImportActionType.Set]: DataImport[]
    [DataImportActionType.UpdateImport]: DataImport
    [DataImportActionType.RemoveImportById]: string
}

export type DataImportActions = ActionMap<DataImportActionPayload>[keyof ActionMap<DataImportActionPayload>]

export const DataImportReducer = (state: DataImport[], action: DataImportActions): DataImport[] => {
    switch (action.type) {
        case DataImportActionType.Set:
            return action.payload
        case DataImportActionType.UpdateImport:
            return state.map((di) => (di.uuid === action.payload.uuid ? action.payload : di))
        case DataImportActionType.RemoveImportById:
            return state.filter((di) => di.uuid !== action.payload)
        default:
            return state
    }
}

export default class DataImportService extends VariableService {
    private basePath: string = '/imports'
    private dataRequestService: DataRequestService
    private analyticsService: AnalyticsService
    private activityService: ActivityService
    private partService: PartService
    private transportService: TransportService
    private readonly defaultDirection: ActivityDirection = ActivityDirection.INPUT
    private static _dromoDateLocale: string | undefined
    private static _dromoNumberLocale: string | undefined

    public static webRoot = '/imports'
    public static webRootCreate = '/imports?start=true'
    public static webRootImport = '/import'

    constructor(context: ApplicationContextInterface, direction?: ActivityDirection) {
        super(context)
        this.dataRequestService = new DataRequestService(context)
        this.activityService = new ActivityService(context)
        this.partService = new PartService(context)
        this.transportService = new TransportService(context)
        this.analyticsService = new AnalyticsService(context)
        if (direction) {
            this.defaultDirection = direction
        }
    }

    public defaultImportTypes(): DataImportType[] {
        return [
            DataImportType.Products,
            DataImportType.Materials,
            DataImportType.Suppliers,
            DataImportType.Activity,
            DataImportType.AirTravel,
            DataImportType.Transport,
        ]
    }

    public elementImportTypes(): DataImportType[] {
        return [DataImportType.Products, DataImportType.Materials, DataImportType.Suppliers, DataImportType.Inputs]
    }

    public activityImportTypes(): DataImportType[] {
        return [DataImportType.Activity, DataImportType.AirTravel, DataImportType.Transport]
    }

    public showStartImport(dataImportType?: DataImportType): void {
        this.context.dispatch({ type: UIOptionActionType.ShowStartImport, payload: dataImportType })
    }

    public setDataImportResult(result: DataImportResponse): void {
        this.context.dispatch({ type: UIOptionActionType.SetDataImportResult, payload: result })
    }

    public static getDataImportUrl(dataImport?: DataImport, fullUrl: boolean = false): string {
        let url = `${DataImportService.webRootImport}/${dataImport?.uuid}`
        if (fullUrl) {
            url = `${document.location.origin}${url}`
        }
        return url
    }

    public async get(skipCache?: boolean): Promise<DataImport[]> {
        let url = this.basePath
        if (skipCache) {
            url += `?rnd=${Math.random()}`
        }
        const dataImports = await this.httpService.get<DataImport[]>(url)
        this.context.dispatch({
            type: DataImportActionType.Set,
            payload: dataImports?.filter((di) => {
                return di.nodes?.reduce((acc, node) => acc + (node.count || 0), 0) || 0
            }),
        })
        return dataImports
    }

    public async getById(importId: string, withNodes: boolean = false): Promise<DataImport> {
        let qs = Utils.queryString({ nodes: withNodes })
        return this.httpService.get<DataImport>(`${this.basePath}/${importId}${qs}`).then((di) => {
            this.context.dispatch({ type: DataImportActionType.UpdateImport, payload: di })
            return di
        })
    }

    public async update(dataImport?: DataImport, properties?: Partial<DataImport>): Promise<DataImport> {
        if (!dataImport || !properties) {
            return Promise.reject('Missing dataImport or properties')
        }
        return this.httpService.patch<DataImport>(`${this.basePath}/${dataImport.uuid}`, {
            body: JSON.stringify(properties),
        })
    }

    public stopImport(dataImport: DataImport): Promise<DataImport> {
        return this.httpService.put<DataImport>(`${this.basePath}/${dataImport.uuid}`, {
            body: JSON.stringify({
                dataImportId: dataImport.uuid,
                stopImport: true,
            }),
        })
    }

    public async deleteImportById(importId: string): Promise<DataImport> {
        return this.httpService.delete<DataImport>(`${this.basePath}/${importId}`).then((di) => {
            this.context.dispatch({ type: DataImportActionType.RemoveImportById, payload: importId })
            return di
        })
    }

    public static get dromoDateLocale(): string | undefined {
        return this._dromoDateLocale
    }

    public static set dromoDateLocale(dateLocale: string | undefined) {
        this._dromoDateLocale = dateLocale
    }

    public static get dromoNumberLocale(): string | undefined {
        return this._dromoNumberLocale
    }

    public static set dromoNumberLocale(numberLocale: string | undefined) {
        this._dromoNumberLocale = numberLocale
    }

    public dromoSettings = (opts: VariableDromoSettings): IDeveloperSettings => {
        return {
            developmentMode: process.env.REACT_APP_VARIABLE_ENV === 'local',
            displayEncoding: true,
            backendSyncMode: 'DISABLED',
            invalidDataBehavior: 'BLOCK_SUBMIT',
            reviewStep: {
                processingText: 'Analyzing data...',
            },
            styleOverrides: {
                global: {
                    textColor: Utils.bodyColor,
                    customFontFamily: "'Object Sans', normal",
                },
                primaryButton: {
                    borderRadius: '0.25rem',
                    backgroundColor: Utils.secondaryBackgroundColor,
                    textColor: Utils.secondaryTextColor,
                    border: `1px solid ${Utils.secondaryBackgroundColor}`,
                    hoverBackgroundColor: Utils.secondaryBackgroundColor,
                    hoverTextColor: Utils.secondaryTextColor,
                    hoverBorder: `1px solid ${Utils.secondaryBackgroundColor}`,
                },
                secondaryButton: {
                    borderRadius: '0.25rem',
                    backgroundColor: Utils.primaryBackgroundColor,
                    textColor: Utils.white,
                    border: `1px solid ${Utils.primaryBackgroundColor}`,
                    hoverBackgroundColor: Utils.primaryBackgroundColor,
                    hoverTextColor: Utils.white,
                    hoverBorder: `1px solid ${Utils.primaryBackgroundColor}`,
                },
            },
            ...opts,
        }
    }

    public static dromoLicenseKey = process.env.REACT_APP_DROMO_KEY!

    public dromoUser = (): IUser => {
        return {
            id: this.context.stores.user?.uuid || '1',
            name: `${this.context.stores.user?.name}`,
            email: this.context.stores.user?.email,
            companyId: this.context.stores.company?.uuid,
            companyName: this.context.stores.company?.name,
        }
    }

    public dromoValidatorJavascriptNumber: IValidatorField = {
        validate: 'regex_match',
        regex: Utils.javascriptNumber,
        errorMessage: Utils.javascriptNumberFormatExplanation,
    }

    public dromoInvalidDateMessage = `Dates should be in this format: ${Utils.exampleDate}`

    public descriptionField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Description',
            key: 'name',
            alternateMatches: ['text', 'tekst', 'description', 'name', 'kontonavn', 'navn', 'konto'],
        }
    }

    public dateField = (label?: string, key?: string, required?: boolean): IDeveloperField => {
        const validators: IValidatorField[] = []
        if (required) validators.push({ validate: 'required', errorMessage: 'This field is required' })
        return {
            label: label || 'Date',
            key: key || 'date',
            type: ['date', { locale: DataImportService.dromoDateLocale || Utils.dateLocale }],
            alternateMatches: [
                'datetime',
                'activity date',
                'departure date',
                'time',
                'dato',
                'forfallsdato',
                'bilagsdato',
                'ordredato',
                'utreisedato',
                'leveringsdato',
            ],
            invalidValueMessage: this.dromoInvalidDateMessage,
            validators: validators,
        }
    }

    public quantityField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Quantity',
            key: 'quantity',
            // type: ['number', { outputFormat: DataImportService.dromoNumberLocale }],
            alternateMatches: [
                'spend',
                'amount',
                'qty',
                'quantity',
                'distance',
                'flight distance',
                'total distance',
                'price',
                'total',
                'price total',
                'qty total',
                'beløp',
            ],
            validators: [this.dromoValidatorJavascriptNumber],
        }
    }

    public unitField = (label?: string, key?: string, props?: Partial<IDeveloperField>): IDeveloperField => {
        const _units = UnitService.units.map((unit) => ({
            value: unit.code,
            label: `${unit.code} (${unit.name})`,
        }))
        _units.unshift({ value: '', label: 'None' })
        return {
            label: label || 'Unit',
            key: key || 'unit',
            type: 'select',
            selectOptions: _units,
            validators: props?.validators || [],
        }
    }

    public purchaseSaleField: IDeveloperField = {
        label: 'Purchase/Sale',
        key: 'direction',
        alternateMatches: ['type'],
        type: 'select',
        selectOptions: [
            { value: 'in', label: 'Purchase' },
            { value: 'out', label: 'Sale' },
        ],
    }

    public transportTypeField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Transport Lane',
            key: 'transportTypeName',
            alternateMatches: ['lane', 'leg', 'route', 'transport'],
        }
    }

    public itemNameField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Item Name',
            key: 'productName',
            alternateMatches: ['Spend based emission factor', 'emission factor', 'factor', 'varegruppe', 'varenavn'],
        }
    }

    public itemIdField: IDeveloperField = {
        label: 'Item ID',
        key: 'productSku',
        alternateMatches: ['part number', 'product number', 'sku', 'part id', 'product id'],
    }

    public companyNameField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Other Company Name',
            key: 'companyName',
            alternateMatches: [
                'supplier',
                'supplier name',
                'customer',
                'customer name',
                'lev.navn',
                'leverandør',
                'lev',
            ],
        }
    }

    public companyIdField: IDeveloperField = {
        label: 'Other Company ID',
        key: 'companyId',
        alternateMatches: ['supplier id', 'customer id'],
    }

    public totalCo2eField = (label?: string): IDeveloperField => {
        return {
            label: label || 'Total kgCO2e',
            key: 'co2e',
            // type: 'number',
            alternateMatches: ['co2e', 'kgCo2e', 'emission', 'emissions', 'emissions total'],
            validators: [this.dromoValidatorJavascriptNumber],
        }
    }

    public taxonomyIdField: IDeveloperField = {
        label: 'Category',
        key: 'taxonomyId',
    }

    public taxonomySubCategory = (taxonomies: Taxonomy[]): IDeveloperField => {
        return {
            label: 'Type',
            key: 'taxonomySubCategoryId',
            alternateMatches: ['type', 'mode', 'category'],
            type: 'select',
            selectOptions: taxonomies.map((tx) => {
                return {
                    value: tx.uuid!,
                    label: tx.name!,
                }
            }),
        }
    }

    public bookingReferenceField: IDeveloperField = {
        label: 'Booking Reference',
        key: 'bookingReference',
        alternateMatches: ['booking number', 'booking code', 'referanse'],
    }

    public flightCodeField: IDeveloperField = {
        label: 'Flight Code',
        key: 'flightCode',
        alternateMatches: ['code', 'flight code', 'flight'],
    }

    public flightAirlineField: IDeveloperField = {
        label: 'Airline',
        key: 'flightAirline',
        alternateMatches: ['airline', 'leverandør'],
    }

    public flightRouteField: IDeveloperField = {
        label: 'Route',
        key: 'flightRoute',
        alternateMatches: ['route', 'leg'],
    }

    public flightDomesticInternational: IDeveloperField = {
        label: 'Domestic / International',
        key: 'flightDomesticInternational',
        alternateMatches: ['område'],
        type: 'select',
        selectOptions: [
            { value: 'domestic', label: 'Domestic' },
            { value: 'international', label: 'International' },
        ],
    }

    public flightDepartureDateField: IDeveloperField = {
        label: 'Departure Date',
        key: 'flightDepartureDate',
        type: ['date', { locale: DataImportService.dromoDateLocale || Utils.dateLocale }],
        alternateMatches: ['departure date', 'utreisedato'],
        invalidValueMessage: this.dromoInvalidDateMessage,
    }

    public flightCabinClassField: IDeveloperField = {
        label: 'Cabin Class',
        key: 'flightCabinClass',
        alternateMatches: ['class', 'cabin', 'kabinklasse', 'cabin class'],
        type: 'select',
        selectOptions: [
            { value: 'economy', label: 'Economy' },
            { value: 'premium-economy', label: 'Premium Economy' },
            { value: 'business', label: 'Business' },
            { value: 'first', label: 'First Class' },
        ],
    }

    public flightDistanceField: IDeveloperField = {
        label: 'Flight Distance',
        key: 'quantity',
        // type: 'number',
        alternateMatches: ['distance', 'km', 'total km', 'antall'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsNumberOfShipmentsField: IDeveloperField = {
        label: 'Number of shipments',
        key: 'logisticsNumberOfShipments',
        // type: 'number',
        alternateMatches: ['shipments'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsWeightInKgField: IDeveloperField = {
        label: 'Weight (in kg)',
        key: 'logisticsWeightInKg',
        // type: 'number',
        alternateMatches: ['weight'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsDistanceInKmField: IDeveloperField = {
        label: 'Distance (in km)',
        key: 'logisticsDistanceInKm',
        // type: 'number',
        alternateMatches: ['distance'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsAirKgCo2e: IDeveloperField = {
        label: 'Air CO2e (in kg)',
        key: 'logisticsAirKgCo2e',
        // type: 'number',
        alternateMatches: ['air'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsRoadKgCo2e: IDeveloperField = {
        label: 'Road CO2e (in kg)',
        key: 'logisticsRoadKgCo2e',
        // type: 'number',
        alternateMatches: ['road'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsSeaKgCo2e: IDeveloperField = {
        label: 'Sea CO2e (in kg)',
        key: 'logisticsSeaKgCo2e',
        // type: 'number',
        alternateMatches: ['sea'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public logisticsWarehouseKgCo2e: IDeveloperField = {
        label: 'Warehouse CO2e (in kg)',
        key: 'logisticsWarehouseKgCo2e',
        // type: 'number',
        alternateMatches: ['warehouse'],
        validators: [this.dromoValidatorJavascriptNumber],
    }

    public uploadStepHook = (importType: string): IStepHook => {
        return {
            // type: EStepHook.UPLOAD_STEP,
            type: 'UPLOAD_STEP',
            callback: (_, data) => {
                this.analyticsService.track(`${importType} Import: Uploaded CSV`, {
                    rows: (data as any)?.dataPreview?.length,
                })
            },
        }
    }

    public activityReviewStepHook = (importType: string, dataRequest?: DataRequest): IStepHook => {
        return {
            type: 'REVIEW_STEP',
            callback: async (instance, data) => {
                this.analyticsService.track(`${importType} Import: Review Data`, {
                    rows: (data as any)?.dataPreview?.length,
                })
                const d = data as any //was: IReviewStepData
                if (!d.fields[this.purchaseSaleField.key]) {
                    await instance.addField(this.purchaseSaleField)
                }
                if (!d.fields[this.companyIdField.key] && dataRequest) {
                    await instance.addField(this.companyIdField)
                }
                if (!d.fields[this.companyNameField().key] && dataRequest) {
                    await instance.addField(this.companyNameField('Company'))
                }
            },
        }
    }

    public bomReviewStepHook = (importType: string): IStepHook => {
        return {
            type: 'REVIEW_STEP',
            callback: async (instance, data) => {
                this.analyticsService.track(`${importType} Import: Review Data`, {
                    rows: (data as any)?.dataPreview?.length,
                })
                const d = data as any // IReviewStepData
                if (d.fields['quantity'] && !d.fields['unitCode']) {
                    await instance.addField(
                        this.unitField('Unit', 'unitCode', {
                            validators: [{ validate: 'required', errorMessage: 'This field is required' }],
                        }),
                    )
                }
            },
        }
    }

    public addTaxonomyIdHook = (): IStepHook => {
        return {
            type: 'REVIEW_STEP',
            callback: async (instance, data) => {
                const d = data as any
                if (!d.fields[this.taxonomyIdField.key]) {
                    await instance.addField(this.taxonomyIdField)
                }
            },
        }
    }

    public numberColumnHook = (fieldName: string): IColumnHook => {
        return {
            fieldName: fieldName,
            callback: (values) => {
                return values.map((row) => {
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    let [value, changed] = Utils.toJavascriptNumber(row.value, DataImportService.dromoNumberLocale)
                    if (changed) {
                        info.push({
                            message: `Number formatting has been updated. Please check to make sure it looks right.`,
                            level: 'warning',
                        })
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public dateColumnHook = (key?: string, required?: boolean, minDate?: number, maxDate?: number): IColumnHook => {
        return {
            fieldName: this.dateField(undefined, key).key,
            callback: (values) => {
                return values.map((row) => {
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    let value = row.value
                    if (!value && required) {
                        value = Utils.toDateString(new Date().valueOf(), DataImportService._dromoDateLocale)
                        info.push({
                            message: `Today's date has been added. If you'd like to specify a better date, please upload with Start_Date or End_Date column filled out.`,
                            level: 'warning',
                        })
                    }
                    if (value && (minDate || maxDate)) {
                        const dateValue = new Date(value).valueOf()
                        const dateErrorMessage = [
                            'All dates should be on or between',
                            Utils.toDateString(minDate, DataImportService._dromoDateLocale),
                            'and',
                            Utils.toDateString(maxDate, DataImportService._dromoDateLocale),
                            '. Please check this date.',
                        ].join(' ')
                        if (minDate && dateValue < minDate) info.push({ message: dateErrorMessage, level: 'error' })
                        if (maxDate && dateValue > maxDate) info.push({ message: dateErrorMessage, level: 'error' })
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public directionColumnHook = (dataRequest?: DataRequest): IColumnHook => {
        const drPerspective = dataRequest && this.dataRequestService.getDataRequestPerspective(dataRequest)
        return {
            fieldName: 'direction',
            callback: (values) => {
                return values.map((row) => {
                    let value = row.value
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    if (dataRequest?.uuid && drPerspective?.incoming) {
                        value = 'Purchase'
                        info.push({
                            message: 'Setting direction based on Request',
                            level: 'info',
                        })
                    } else if (dataRequest?.uuid && drPerspective?.outgoing) {
                        value = 'Sale'
                        info.push({
                            message: 'Setting direction based on Request',
                            level: 'info',
                        })
                    } else if (!value) {
                        value = this.defaultDirection === ActivityDirection.INPUT ? 'Purchase' : 'Sale'
                        info.push({
                            message: `No data provided, defaulting to "${value}"`,
                            level: 'info',
                        })
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public taxonomyColumnHook = (taxonomy: Taxonomy): IColumnHook => {
        return {
            fieldName: this.taxonomyIdField.key,
            callback: (values) => {
                return values.map((row) => {
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    let value = row.value
                    if (!row.value) {
                        value = taxonomy?.uuid
                        info.push({
                            message: 'Set automatically. Do not change this field.',
                            level: 'info',
                        })
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public companyNameColumnHook = (dataRequest?: DataRequest): IColumnHook => {
        const drPerspective = dataRequest && this.dataRequestService.getDataRequestPerspective(dataRequest)
        return {
            fieldName: 'companyName',
            callback: (values) => {
                return values.map((row) => {
                    let value = row.value
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    if (dataRequest?.uuid && drPerspective) {
                        if (drPerspective?.incoming && drPerspective.supplier?.name) {
                            value = drPerspective.supplier?.name
                            info.push({
                                message: 'Setting company based on Request',
                                level: 'info',
                            })
                        } else if (drPerspective?.outgoing && drPerspective.customer?.name) {
                            value = drPerspective.customer.name
                            info.push({
                                message: 'Setting company based on Request',
                                level: 'info',
                            })
                        }
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public companyIdColumnHook = (dataRequest?: DataRequest): IColumnHook => {
        const drPerspective = dataRequest && this.dataRequestService.getDataRequestPerspective(dataRequest)
        return {
            fieldName: 'companyId',
            callback: (values) => {
                return values.map((row) => {
                    let value = row.value
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    if (dataRequest?.uuid && drPerspective) {
                        if (drPerspective?.incoming && drPerspective.supplier?.uuid) {
                            value = drPerspective.supplier?.uuid
                            info.push({
                                message: 'Setting company based on Request',
                                level: 'info',
                            })
                        } else if (drPerspective?.outgoing && drPerspective.customer?.uuid) {
                            value = drPerspective.customer.uuid
                            info.push({
                                message: 'Setting company based on Request',
                                level: 'info',
                            })
                        }
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public unitColumnHook = (key?: string, defaultValue?: string): IColumnHook => {
        return {
            fieldName: key || 'unit',
            callback: (values) => {
                return values.map((row) => {
                    const info: any[] = []
                    let level = 'info'
                    let message = 'Unit automatically matched.'
                    let value = row.value
                    if (!value || value === 'None') {
                        value = defaultValue || 'item'
                        level = 'warning'
                        message = 'No unit found, using default.'
                    }
                    const unitValue = UnitService.unitByCode[value]
                    if (unitValue) {
                        info.push({ message, level })
                        value = `${unitValue.code} (${unitValue.name})`
                    }
                    return { index: row.index, value, info }
                })
            },
        }
    }

    public emailColumnHook = (fieldName: string): IColumnHook => {
        return {
            fieldName: fieldName,
            callback: (values) => {
                return values.map((row) => {
                    // const info: ITableMessage[] = []
                    const info: any[] = []
                    if (row.value) {
                        const valid = Utils.isValidEmail(row.value)
                        if (!valid) {
                            info.push({
                                message: 'This email address does not look right. Please check it.',
                                level: 'error',
                            })
                        }
                    }
                    return { index: row.index, value: row.value, info }
                })
            },
        }
    }

    private inProgressToast: Id | undefined
    private isImporting = false
    public onStartImport = (lines: number) => {
        this.isImporting = true
        this.inProgressToast = Utils.infoToast(
            <div>
                {lines > 0 && (
                    <>
                        Importing {lines} rows
                        <br />
                    </>
                )}
                {lines <= 0 && 'Starting Import'}
                <small className='d-block pt-1 mt-1 border-top'>
                    You can safely navigate away from this page. We'll email you when the import has completed.
                </small>
            </div>,
            { position: 'top-right', autoClose: false, theme: Utils.colorMode === 'dark' ? 'dark' : 'light' },
        )
    }

    public dismissInProgressToast = () => {
        this.isImporting = false
        Utils.dismissToast(this.inProgressToast)
        this.inProgressToast = undefined
    }

    public updateInProgressToast = (msg: ToastContent, opts: ToastOptions) => {
        if (this.inProgressToast) {
            Utils.updateToast(this.inProgressToast, msg, { ...opts })
        } else {
            this.inProgressToast = Utils.infoToast(msg, {
                ...opts,
                onClose: () => {
                    this.inProgressToast = undefined
                },
            })
        }
    }

    public onImportDone = (result: DataImportResponse, importType: string) => {
        this.analyticsService.track(`${importType} Import: Success`, {
            nodesCreated: result?.imported,
            nodeTypes: result?.dataImport?.types,
        })
    }

    public onImportError = (errorMessage: any, importType: string) => {
        this.dismissInProgressToast()
        Utils.errorToast(errorMessage, errorMessage?.message || 'We had a problem importing the data', {
            autoClose: false,
        })
        this.analyticsService.track(`${importType} Import: Error`, { errorMessage })
    }

    public checkDataImportStatus(
        dataImportId: string,
        rows: number,
        throttle: number,
        nodeType?: ND,
        onDone?: (result: DataImportResponse) => void,
        onError?: (e: any) => void,
    ) {
        this.getById(dataImportId)
            .then((dir) => {
                if (dir.status === 'done' && onDone) {
                    this.dismissInProgressToast()
                    onDone({ dataImport: dir, imported: dir.nodes?.length || 0 })
                } else if (dir.status === 'error' && onError) {
                    this.dismissInProgressToast()
                    onError(new Error(dir.errorMessage || 'import failed'))
                } else {
                    if (this.isImporting && this.inProgressToast && throttle >= 1000) {
                        let msg = <>Working on it</>
                        const totalNodes = dir.nodes?.reduce((prev, curr) => (curr.count || 0) + prev, 0)
                        if (totalNodes) {
                            msg = <DataImportNodes dataImport={dir} />
                        }
                        if (rows) {
                            const activityNodes = dir.nodes?.find((n) => n.type === nodeType)
                            const [importedPercent, importedPercentNumber] = Utils.percentOfTotal(
                                activityNodes?.count || 0,
                                rows,
                            )
                            if (importedPercentNumber) {
                                msg = <PrettyNumber num={importedPercent} suffix='%' />
                            }
                        }
                        this.updateInProgressToast(
                            <div>
                                {msg}
                                <span className='spinner-border spinner-border-sm ms-2' />
                                <br />
                                <small className='d-block pt-1 mt-1 border-top'>
                                    You can safely navigate away from this page. We'll email you when the import has
                                    completed.
                                </small>
                            </div>,
                            { position: 'top-right', autoClose: false },
                        )
                    }
                    const _throttle = Math.min(throttle * 2, 3000)
                    setTimeout(() => {
                        this.checkDataImportStatus(dataImportId, rows, _throttle, nodeType, onDone, onError)
                    }, _throttle)
                }
            })
            .catch((err) => {
                this.dismissInProgressToast()
                onError && onError(err)
            })
    }

    public dromoOnResults = (
        response: any,
        _: IResultMetadata,
        importType: string,
        linkText: string,
        dataRequest?: DataRequest,
        onDone?: (result: DataImportResponse) => void,
        onViewClick?: (result: DataImportResponse) => void,
        onError?: (e: any) => void,
    ): void => {
        response = response?.map((r: any) => {
            return { ...r, dataRequestId: dataRequest?.uuid }
        })
        this.analyticsService.track(`${importType} Import: Submitted Data`, { rows: response.length })
        this.onStartImport(response.length)
        this.activityService
            .importActivities(response)
            .then((result) => {
                this.checkDataImportStatus(
                    result.dataImport.uuid!,
                    response.length,
                    250,
                    ND.ActivityItem,
                    (result) => {
                        this.onImportDone(result, importType)
                        onDone && onDone(result)
                        this.partService.fetchInventory().then()
                        this.transportService.getTransportTypes(undefined, true).then()
                        const doneToast = Utils.successToast(
                            <div>
                                Import complete
                                <br />
                                <Button
                                    onClick={() => {
                                        onViewClick && onViewClick(result)
                                        Utils.dismissToast(doneToast)
                                    }}
                                    className='btn btn-sm btn-link text-decoration-underline p-0'
                                >
                                    {linkText || 'View data'}
                                </Button>
                            </div>,
                            {
                                autoClose: 3000,
                                closeOnClick: false,
                                theme: Utils.colorMode === 'dark' ? 'dark' : 'light',
                            },
                        )
                    },
                    (err) => {
                        this.onImportError(err, importType)
                        onError && onError(err)
                    },
                )
            })
            .catch((err) => {
                this.onImportError(err, importType)
                onError && onError(err)
            })
    }

    public standardResult = (props: {
        importType: string
        dataRequest?: DataRequest
        navigate: NavigateFunction
        onDone?: (result: DataImportResponse) => void
        onError?: (e: any) => void
    }) => {
        return (response: any, metadata: IResultMetadata) => {
            this.dromoOnResults(
                response,
                metadata,
                props.importType,
                'View Activities',
                props.dataRequest,
                props.onDone,
                (result) => {
                    if (props.dataRequest) {
                        props.navigate(DataRequestService.getDataRequestUrl(props.dataRequest))
                    } else if (result.dataImport?.uuid) {
                        props.navigate(DataImportService.getDataImportUrl(result.dataImport))
                    } else {
                        props.navigate(ActivityService.webRootActivities)
                    }
                },
                props.onError,
            )
        }
    }
}
