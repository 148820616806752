import DromoReactUploader from 'dromo-uploader-react'
import { MutableRefObject, ReactNode, useMemo } from 'react'
import { ActivityDirection } from '../../services/activity'
import { useNavigate } from 'react-router-dom'
import { DataRequest } from '../../services/dataRequest'
import DataImportService, { DataImportResponse } from '../../services/dataImport'
import { useDataImport } from '../../hooks/useDataImport'

export const ActivityImport = (props: {
    children?: ReactNode
    showButton?: boolean
    className?: string
    dataRequest?: DataRequest
    defaultDirection?: ActivityDirection
    dromoRef: MutableRefObject<any>
    onDone?: (result: DataImportResponse) => void
    onCancel?: () => void
    onError?: (e: any) => void
}) => {
    const navigate = useNavigate()
    const dataImportService = useDataImport(props.defaultDirection)
    const IMPORT_TYPE = 'Activity'

    const button = useMemo(() => {
        if (props.children) return props.children
        if (props.showButton === false) return null
        return 'Import data'
    }, [props.children, props.showButton])

    return (
        <DromoReactUploader
            schemaName='Activity Import'
            schemaId='activity-import'
            ref={props.dromoRef}
            className={props.className || 'btn btn-primary'}
            licenseKey={DataImportService.dromoLicenseKey}
            fields={[
                dataImportService.descriptionField('Activity Description'),
                dataImportService.dateField('Start Date', 'startDate'),
                dataImportService.dateField('End Date', 'endDate', true),
                dataImportService.purchaseSaleField,
                dataImportService.quantityField(),
                dataImportService.unitField(),
                dataImportService.itemNameField(),
                dataImportService.itemIdField,
                dataImportService.companyNameField(),
                dataImportService.companyIdField,
                dataImportService.totalCo2eField(),
            ]}
            stepHooks={[
                dataImportService.uploadStepHook(IMPORT_TYPE),
                dataImportService.activityReviewStepHook(IMPORT_TYPE, props.dataRequest),
            ]}
            columnHooks={[
                dataImportService.numberColumnHook(dataImportService.quantityField().key),
                dataImportService.numberColumnHook(dataImportService.totalCo2eField().key),
                dataImportService.dateColumnHook(
                    'startDate',
                    false,
                    props.dataRequest?.startDate,
                    props.dataRequest?.endDate,
                ),
                dataImportService.dateColumnHook(
                    'endDate',
                    true,
                    props.dataRequest?.startDate,
                    props.dataRequest?.endDate,
                ),
                dataImportService.directionColumnHook(props.dataRequest),
                dataImportService.companyNameColumnHook(props.dataRequest),
                dataImportService.companyIdColumnHook(props.dataRequest),
            ]}
            settings={dataImportService.dromoSettings({
                importIdentifier: IMPORT_TYPE,
                templateDownloadFilename: 'Data_Import.csv',
            })}
            user={dataImportService.dromoUser()}
            onResults={dataImportService.standardResult({
                importType: IMPORT_TYPE,
                dataRequest: props.dataRequest,
                navigate: navigate,
                onDone: props.onDone,
                onError: props.onError,
            })}
            onCancel={props.onCancel}
        >
            {button}
        </DromoReactUploader>
    )
}
