import { SlideIn } from '../SlideIn'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { ApplicationContext } from '../../context'
import Utils from '../../services/utils'
import Delete from '../Delete'
import { Input, InputActionType } from '../../services/input'
import InputField from '../Input/InputField'
import { AmountInput } from '../Input/Amount'
import { LifecycleStageSelector } from './LifecycleStageSelector'
import { VariableServicesContext } from '../../services'
import CO2e from '../CO2e'
import { DebugData } from '../Footnotes'
import { useSave } from '../../hooks/useSave'
import { useInput } from '../../hooks/useInput'
import { useProduct } from '../../hooks/useProduct'

export const InputEditor = () => {
    const context = useContext(ApplicationContext)
    const { inputService } = useContext(VariableServicesContext)
    const nameRef = useRef<HTMLInputElement>()
    const inputRef = useRef<HTMLInputElement>()

    const input = useInput({ inputId: context.stores.input?.currentId })
    const sourceProduct = useProduct({ product: input?.sourceProduct })

    const partialSaveFn = useCallback(
        async (properties?: Partial<Input>) => {
            if (!properties) return
            return inputService.updateInput(properties).then((i) => {
                // if (i.transportedVia) updateTransportedVia(i).then()
                return i
            })
        },
        [input],
    )

    const { pSave, saving } = useSave({
        node: input,
        partialSaveFn,
        onError: () => Utils.errorToast('Could not save'),
    })

    useEffect(() => {
        if (input?.uuid && context.stores.input.action === 'edit') {
            if (nameRef.current) nameRef.current.value = input?.name || ''
            Utils.sleep(100).then(() => {
                if (!input?.name) {
                    nameRef.current?.focus()
                } else {
                    inputRef.current?.focus()
                }
            })
        }
    }, [input?.uuid])

    return (
        <SlideIn
            show={context.stores.input !== undefined && context.stores.input.action === 'edit'}
            onVisibilityChange={(v) => !v && context.dispatch({ type: InputActionType.CloseInput })}
            useBackdrop={true}
            header={
                <InputField
                    passedRef={nameRef}
                    placeholder='Name'
                    className='variable-form-control bg-light fs-6 w-100'
                    defaultValue={input?.name}
                    onChanged={(name) => pSave({ name })}
                />
            }
        >
            <div className='d-flex flex-column gap-3 flex-grow-1'>
                {input && (
                    <>
                        <div className='row'>
                            <div className='col-4 small'>Quantity</div>
                            <div className='col-8'>
                                <AmountInput
                                    inputFieldProps={{ passedRef: inputRef }}
                                    unitSelectorProps={{
                                        className: 'variable-form-select bg-light',
                                        placement: 'bottom-end',
                                        unitType: sourceProduct?.unit?.type || input.part?.unit?.type,
                                    }}
                                    amount={{ quantity: input?.quantity || 0, unit: input?.unit }}
                                    onChange={(newValue) =>
                                        pSave({
                                            quantity: newValue?.quantity || 0,
                                            unit: newValue?.unit,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 small'>Life-cycle stage</div>
                            <div className='col-8'>
                                <LifecycleStageSelector
                                    placeholder='Select'
                                    placement='bottom-end'
                                    className='variable-form-control bg-light'
                                    category={input?.useStageCategory}
                                    onChange={(useStageCategory) => pSave({ useStageCategory })}
                                />
                            </div>
                        </div>
                        <div className='row small border-top pt-3'>
                            <div className='col-4'>{Utils.co2e}</div>
                            <div className='col-8 text-end'>
                                <CO2e co2e={input.co2e} />
                            </div>
                        </div>
                    </>
                )}
                <div className='fs-base text-center mt-auto'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='small text-very-muted'>
                            Last saved: {Utils.toDateTimeString(input?.updated || input?.created)}
                            <span hidden={!saving} className='ms-1 spinner-border spinner-border-sm' />
                        </div>
                        <div hidden={!context.stores.input?.currentId} className='small'>
                            <Delete
                                disabled={!input?.uuid}
                                className='text-muted'
                                deleteFn={() => inputService.removeInput(input?.uuid).then()}
                            />
                        </div>
                    </div>
                    <DebugData node={input} />
                </div>
            </div>
        </SlideIn>
    )
}
