import { useContext, useMemo } from 'react'
import { InventoryService } from '../services/inventory'
import { ApplicationContext } from '../context'
import { Part } from '../services/part'

export const usePart = (props: { part?: Part | null; partId?: string }) => {
    const context = useContext(ApplicationContext)
    return useMemo(
        () => InventoryService.getById(props.partId || props.part?.uuid)?.originalPart || props.part || undefined,
        [props.partId, props.part?.uuid, context.stores.inventory.updated],
    )
}
