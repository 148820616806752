import { ActionMap } from '../context'
import { KeyValuePair, VariableBaseNode } from '../types'
import VariableService from './service'

export interface Onboarding extends VariableBaseNode {
    version?: string
    guess?: string
    step?: number
}

export enum OnboardingStep {
    // START = 'Onboarding: Start',
    COMPANY = 'Onboarding: Company Setup',
    AMBITION = 'Onboarding: Ambition',
    PCF_START = 'Onboarding: Footprint Start',
    PCF_GUESS = 'Onboarding: Footprint Guess',
    PCF_BODY = 'Onboarding: Footprint Body',
    PCF_MANUFACTURE_LOCATION = 'Onboarding: Footprint Manufacturing Location',
    PCF_USE_LOCATION = 'Onboarding: Footprint Use Location',
    PCF_REVEAL = 'Onboarding: Footprint Reveal',
}

export const onboardingStepSequence: OnboardingStep[] = [
    // OnboardingStep.START,
    OnboardingStep.COMPANY,
    OnboardingStep.AMBITION,
    OnboardingStep.PCF_START,
    OnboardingStep.PCF_GUESS,
    OnboardingStep.PCF_BODY,
    OnboardingStep.PCF_MANUFACTURE_LOCATION,
    OnboardingStep.PCF_USE_LOCATION,
    OnboardingStep.PCF_REVEAL,
]
export const onboardingGuesses: KeyValuePair<string>[] = [
    { name: 'Materials', value: 'materials', description: 'the materials' },
    { name: 'Manufacturing process', value: 'manufacturing', description: 'the manufacturing process' },
    { name: 'Electricity used when the kettle is on', value: 'use', description: 'the use of the product' },
]
export const onboardingManufactureLocations = [
    { order: 1, name: 'US', value: 'US', footprintSyncId: 'recgvFYIRzyV9f39P' },
    { order: 2, name: 'France', value: 'France', footprintSyncId: 'rec5TLZ8i4XmtBfNZ' },
    { order: 3, name: 'Poland', value: 'Poland', footprintSyncId: 'recBIXFpIoisoIMDD' },
    { order: 4, name: 'China', value: 'China', footprintSyncId: 'recLZ8zzusKQl7GNC' },
]
export const onboardingUseLocations = [
    { order: 1, name: 'Norway', value: 'Norway', footprintSyncId: 'recoqQI7dMbbmzzon' },
    { order: 2, name: 'US', value: 'US', footprintSyncId: 'recgvFYIRzyV9f39P' },
    { order: 3, name: 'France', value: 'France', footprintSyncId: 'rec5TLZ8i4XmtBfNZ' },
    { order: 4, name: 'Chile', value: 'Chile', footprintSyncId: 'recPK30RwI31GNS93' },
]

export const titleOptions = [
    { order: 1, name: 'Sustainability', value: 'Sustainability' },
    { order: 2, name: 'R&D', value: 'R&D' },
    { order: 3, name: 'Sourcing / Procurement', value: 'Sourcing / Procurement' },
    { order: 4, name: 'Operations', value: 'Operations' },
    { order: 5, name: 'Finance', value: 'Finance' },
    { order: 6, name: 'CEO', value: 'CEO' },
    { order: 7, name: 'Other', value: 'Other' },
]

export enum OnboardingActionType {
    Set = 'SetOnboarding',
}

type OnboardingActionPayload = {
    [OnboardingActionType.Set]: Onboarding | undefined
}
export type OnboardingActions = ActionMap<OnboardingActionPayload>[keyof ActionMap<OnboardingActionPayload>]
export const OnboardingReducer = (state: Onboarding | undefined, action: OnboardingActions): Onboarding | undefined => {
    switch (action.type) {
        case OnboardingActionType.Set:
            return action.payload
        default:
            return state
    }
}

export default class OnboardingService extends VariableService {
    public getOnboarding(): Promise<Onboarding> {
        return this.httpService.get<Onboarding>(`/user/onboarding`)
    }

    public saveOnboarding(onboarding: any): Promise<Onboarding> {
        return this.httpService.put<Onboarding>(`/user/onboarding`, { body: JSON.stringify({ onboarding }) })
    }
}
