import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import ProcessingService, { ProcessingType } from '../services/processing'

export const useProcessingType = (props: {
    processingType?: ProcessingType | null
    processingTypeId?: string
    condition?: boolean
}) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.processingTypeId || props.processingType?.uuid || ''
        if (id === ProcessingService.newId) return ProcessingService.getEmptyProcessingType()
        return ProcessingService.getById(id) || props.processingType || undefined
    }, [props.processingTypeId, props.processingType?.uuid, context.stores.processingType.updates, props.condition])
}
