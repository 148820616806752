import { ND, UsedIn } from '../types'
import LocationService from '../services/location'
import { Link } from 'react-router-dom'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { VariableServicesContext } from '../services'
import Utils from '../services/utils'
import { PartIcon } from './Icons/PartIcon'
import { Buildings, Factory } from '@phosphor-icons/react'
import { Company } from '../services/company'
import PartService from '../services/part'
import { ApplicationContext } from '../context'
import { useProduct } from '../hooks/useProduct'
import { usePart } from '../hooks/usePart'
import { useTransportType } from '../hooks/useTransportType'
import { ElementBadge } from './Product/ElementBadge'
import { TransportBadge } from './Transport/TransportBadge'

export const UsedInItem = (props: { usedIn: UsedIn; followLink?: boolean; extraOnClick?: () => void }) => {
    const context = useContext(ApplicationContext)
    const { companyService, locationService } = useContext(VariableServicesContext)
    const [name, setName] = useState<string | undefined>(props.usedIn.name)
    const [icon, setIcon] = useState<ReactNode>()
    const [link, setLink] = useState<string>('#')

    const product = useProduct({ productId: props.usedIn.uuid })
    const part = usePart({ partId: props.usedIn.uuid })
    const transportType = useTransportType({ transportTypeId: props.usedIn.uuid })

    const location = useMemo(
        () => LocationService.getById(props.usedIn.uuid),
        [props.usedIn.uuid, context.stores.ui?.locationsUpdated],
    )

    const elementCard = useMemo(() => {
        if (product) {
            return (
                <ElementBadge
                    product={product}
                    noTooltip={true}
                    followLink={props.followLink}
                    extraOnClick={props.extraOnClick}
                    className=' '
                    nameClassName='underline-on-hover'
                />
            )
        }
        if (transportType) {
            return <TransportBadge transportType={transportType} />
        }
        return null
    }, [product, transportType])

    useEffect(() => {
        // console.log(props.usedIn)
        if (props.usedIn.companyType) {
            if (props.usedIn.companyType === 'supplier') {
                setIcon(<Factory />)
            } else {
                setIcon(<Buildings />)
            }
            setLink(companyService.getCompanyUrl(props.usedIn as unknown as Company, props.usedIn.companyType))
            setName(props.usedIn.name)
        } else if (props.usedIn.type === ND.Part) {
            setIcon(<PartIcon part={part} />)
            setLink(PartService.getPartUrl(part))
            if (part?.name) setName(part.name)
        } else if (props.usedIn.type === ND.Location) {
            setIcon(LocationService.getLocationTypeIcon(location))
            setName(LocationService.getLocationName(location))
        }
    }, [props.usedIn, product, part, transportType, location])

    return (
        elementCard || (
            <Link
                to={link}
                className='d-inline-flex align-items-center gap-1 hover-parent-direct'
                onClick={(e) => {
                    if (Utils.isModifierKey(e)) return
                    props.extraOnClick?.()
                    if (props.followLink) return
                    if (props.usedIn.companyType) return
                    if ([ND.Part, ND.ActivityItem].includes(props.usedIn.type)) return
                    e.preventDefault()
                    e.stopPropagation()
                    switch (props.usedIn.type) {
                        case ND.Location:
                            if (location?.uuid) locationService.openLocation(location?.uuid)
                            break
                        default:
                            locationService.openLocation(undefined)
                            break
                    }
                }}
            >
                {icon !== undefined ? <span className='flex-shrink-0'>{icon}</span> : null}
                <span className='underline-on-hover'>{name}</span>
            </Link>
        )
    )
}
