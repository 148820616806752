import { VariableBaseNode } from '../types'
import VariableService from './service'
import { UIOptionActionType } from './ui'

export interface CpcCode extends VariableBaseNode {
    code: string
}

export default class CpcService extends VariableService {
    private basePath: string = '/cpc'

    private static byCode: Map<string, CpcCode> = new Map<string, CpcCode>()
    public static list: CpcCode[] = []

    public static getByCode(code?: string | null): CpcCode | undefined {
        if (!code) return undefined
        return CpcService.byCode.get(code)
    }

    public async getAll(): Promise<CpcCode[]> {
        if (CpcService.byCode.size > 0) return Array.from(CpcService.byCode.values())
        return this.httpService.get<CpcCode[]>(this.basePath).then((cpcCodes) => {
            cpcCodes.forEach((cpcCode) => CpcService.byCode.set(cpcCode.code, cpcCode))
            CpcService.list = cpcCodes
            this.context.dispatch({ type: UIOptionActionType.CpcCodesReady })
            return cpcCodes
        })
    }
}
