import CalculatorService, {
    Calculation,
    CalculationValue,
    Calculator,
    CalculatorInput,
    CalculatorInputType,
    EmptyCalculation,
    EmptyCalculationValue,
} from '../../services/calculator'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import CO2e from '../CO2e'
import InputField from '../Input/InputField'
import Card from '../Card'
import { Link } from 'react-router-dom'
import Utils from '../../services/utils'
import Spinner from '../Spinner'
import { UnitSelector } from '../Input/UnitSelector'
import { Selector } from '../Input/Selector'
import Button from '../Input/Button'
import { VariableServicesContext } from '../../services'
import { Product } from '../../services/product'

export const CalculatorInstance = (props: {
    calculator?: Calculator
    calculatorId?: string
    calculationId?: string
    onResult: (calculation: Calculation) => void
    cancelable?: boolean
    onCancel?: () => void
    className?: string
    style?: CSSProperties
    hidden?: boolean
}) => {
    const { calculatorService } = useContext(VariableServicesContext)
    const [calculator, setCalculator] = useState<Calculator>()
    const [calculation, setCalculation] = useState<Calculation>(EmptyCalculation)
    const [shouldSave, setShouldSave] = useState<boolean>(false)

    useEffect(() => {
        if (props.calculator) {
            setCalculator(props.calculator)
        }
    }, [props.calculator])

    useEffect(() => {
        if (props.calculationId) {
            if (props.calculationId !== calculation?.uuid) {
                calculatorService.getInstance(props.calculationId).then((calc) => {
                    setCalculation(calc)
                    setCalculator(calc.calculator)
                })
            }
        } else if (props.calculatorId) {
            calculatorService.getCalculator(props.calculatorId).then((calc) => {
                setCalculator(calc)
                setCalculation((state) => ({
                    ...state,
                    calculator: calc,
                    data: [],
                }))
            })
        }
    }, [props.calculatorId, props.calculationId])

    useEffect(() => {
        if (calculation && calculator) {
            const co2e = CalculatorService.getResult(calculator, calculation.data)
            setCalculation((state) => ({
                ...state,
                co2e: co2e.toString(),
            }))
        }
    }, [calculation.data])

    useEffect(() => {
        if (shouldSave && calculation) {
            setShouldSave(false)
            props.onResult(calculation)
        }
    }, [shouldSave])

    if (!calculator || !calculation) {
        return (
            <Card>
                <Spinner />
            </Card>
        )
    }

    return (
        <Card className={props.className} style={props.style} hidden={props.hidden}>
            <h4>{calculator.name}</h4>

            {CalculatorService.getCalculatorInputsSortedByFormula(calculator)?.map((input, idx) => {
                return (
                    <CalculatorInstanceLineItem
                        key={`ciLineItem-${input.uuid}`}
                        calculation={calculation}
                        input={input}
                        onChange={(cv) => {
                            const calculationData = [...calculation.data]
                            calculationData[idx] = cv
                            setCalculation((state) => ({
                                ...state,
                                data: calculationData,
                            }))
                        }}
                    />
                )
            })}

            <div className='d-flex align-items-end'>
                <div className='w-100 overflow-visible'>
                    <div className='mt-3 very-small'>{CalculatorService.getHtml(calculator)}</div>
                    <div className='d-flex mt-1'>
                        <div>{CalculatorService.getFormula(calculator, calculation.data)}</div>
                        <span className='mx-2'>=</span>
                        <CO2e className='bg-light border border-primary px-1' co2e={calculation?.co2e} />
                    </div>
                </div>

                <div>
                    <Button className='btn btn-sm btn-primary text-nowrap' onClick={() => setShouldSave(true)}>
                        Use Result
                    </Button>
                    {props.cancelable && (
                        <Link
                            to=''
                            className='ms-2 small text-muted'
                            onClick={() => props.onCancel && props.onCancel()}
                        >
                            Cancel
                        </Link>
                    )}
                </div>
            </div>
        </Card>
    )
}

const CalculatorInstanceLineItem = (props: {
    calculation: Calculation
    input: CalculatorInput
    onChange: (input: CalculationValue) => void
}) => {
    const [calculationValue, setCalculationValue] = useState<CalculationValue>({
        ...EmptyCalculationValue,
        ...CalculatorService.getValueForInput(props.input, props.calculation?.data),
    })

    useEffect(() => {
        const inputValue = CalculatorService.getValueForInput(props.input, props.calculation?.data)
        if (!calculationValue?.product) {
            setCalculationValue((state) => ({
                ...state,
                ...inputValue,
                product: props.input.products?.[0],
            }))
        }
        if (!calculationValue?.input) {
            setCalculationValue((state) => ({
                ...state,
                ...inputValue,
                input: props.input,
            }))
        }
    }, [props.calculation.uuid, props.input.uuid])

    const getLabelKey = (product?: Product): string => {
        return `${product?.name} (${Utils.toFixedFloat(product?.co2e)} kgCO2e / ${product?.unit?.name})`
    }

    useEffect(() => props.onChange(calculationValue), [calculationValue])

    return (
        <>
            <strong className='text-nowrap'>{props.input.name}</strong>
            {props.input.type === CalculatorInputType.FACTOR && (
                <Selector
                    className='form-select form-select-sm'
                    placement='bottom-start'
                    options={props.input.products}
                    option={calculationValue.product}
                    label={<>{getLabelKey(calculationValue?.product)}</>}
                    onSelect={(value) => setCalculationValue((state) => ({ ...state, product: value }))}
                    renderItemValue={getLabelKey}
                />
            )}
            {props.input.type === CalculatorInputType.ENUM && (
                <select
                    className='form-select form-select-sm'
                    defaultValue={calculationValue.value}
                    onChange={(e) => {
                        setCalculationValue((state) => ({
                            ...state,
                            value: e.target.value,
                        }))
                    }}
                >
                    {props.input.options?.map((item) => {
                        const [display, value] = CalculatorService.getEnumValue(item)
                        return (
                            <option key={`ciEnum-${value}`} value={value}>
                                {display}
                            </option>
                        )
                    })}
                </select>
            )}
            <div className='d-flex'>
                {(props.input.type === CalculatorInputType.VALUE ||
                    props.input.type === CalculatorInputType.PERCENT ||
                    props.input.type === CalculatorInputType.UNIT) && (
                    <div className='input-group input-group-sm'>
                        <InputField
                            type='text'
                            defaultValue={calculationValue.value}
                            placeholder={`e.g. ${props.input.example?.toString() || '1'}`}
                            className='form-control form-control-sm'
                            onChange={(value) => {
                                setCalculationValue((state) => ({
                                    ...state,
                                    value: value,
                                }))
                            }}
                        />
                        {props.input.type === CalculatorInputType.PERCENT && (
                            <span className='input-group-text'>%</span>
                        )}
                    </div>
                )}
                {props.input.type === CalculatorInputType.UNIT && (
                    <UnitSelector
                        className='d-inline-block px-2 py-1 bg-light bg-primary-hover border rounded-1'
                        unit={calculationValue.unit}
                        comparisonUnit={props.input.baseUnit}
                        onChange={(u) => {
                            setCalculationValue((state) => ({
                                ...state,
                                unit: u,
                            }))
                        }}
                    />
                )}
            </div>
        </>
    )
}
