import CO2e from '../CO2e'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ElectricityService, { ElectricityFactors } from '../../services/electricity'
import { VariableServicesContext } from '../../services'
import ProductService, { Product } from '../../services/product'
import InputField from '../Input/InputField'
import { CalendarDots, ListBullets, Pulse } from '@phosphor-icons/react'
import { ND, StandardAttributes, UsedIn } from '../../types'
import GoogleMap from '../GoogleMap'
import LocationService from '../../services/location'
import { CompanyFilter } from '../Company/CompanyFilter'
import { Footnotes } from '../Footnotes'
import { ImageUploader } from '../Input/ImageUploader'
import { Tab, TabContent, Tabs } from '../Tabs'
import { useActivityContext } from '../../hooks/useActivityContext'
import { ActivityActionType } from '../../services/activityContext'
import { PrettyNumber } from '../PrettyNumber'
import { useNavigate, useParams } from 'react-router-dom'
import Utils from '../../services/utils'
import { ActivityTable, defaultActivityTableColumns } from '../Activity/ActivityTable'
import { ActivitySlider } from '../Activity/ActivitySlider'
import usePaginate from '../../hooks/usePaginate'
import Card from '../Card'
import { UsedInItem } from '../UsedInItem'
import EnergyService from '../../services/energy'
import { EnergyIcon } from '../Icons/EnergyIcon'
import { useSave } from '../../hooks/useSave'
import { LocationSelector } from '../Location/LocationSelector'
import { InventoryService } from '../../services/inventory'
import { useProduct } from '../../hooks/useProduct'
import { TitleEditor } from '../Input/TitleEditor'
import Delete from '../Delete'
import { EtcMenu } from '../EtcMenu'

export const ElectricityDetail = (props: StandardAttributes & { product?: Product; isPreview?: boolean }) => {
    const { tab } = useParams()
    const navigate = useNavigate()
    const { uiService, productService, electricityService, activityService, locationService } =
        useContext(VariableServicesContext)
    const { activityContext, resetActivityContext } = useActivityContext()
    const [electricity, setElectricity] = useState<ElectricityFactors>()
    const { queryString } = usePaginate()

    const product = useProduct({ product: props.product })

    useEffect(() => {
        fetchActivities()
        if (!props.product?.uuid || electricity) return
        if (!props.isPreview) uiService.setNavPath(EnergyService.webRoot)
        uiService.setInventoryNavPath(InventoryService.productToInventory(props.product))
    }, [])

    useEffect(() => {
        electricityService.getElectricity(product?.uuid).then(setElectricity)
    }, [product?.geoLocation?.uuid])

    const partialSaveFn = useCallback(
        async (properties: Partial<Product>) => {
            if (!product) return
            return productService.update(properties)
        },
        [product],
    )

    const { pSave, saving } = useSave({ node: product, partialSaveFn })

    const header = useMemo(() => {
        if (props.isPreview) return null
        return (
            <div className='d-flex align-items-center gap-2'>
                <div className='flex-grow-0'>
                    <ImageUploader
                        nodeType={ND.Product}
                        nodeId={product?.uuid}
                        filePath={product?.productImageUrl}
                        style={{ width: '60px', height: 'auto', minHeight: '60px' }}
                        onUpload={(productImageUrl) => pSave({ productImageUrl })}
                    />
                </div>
                <CO2e
                    product={product}
                    functionalUnit={product?.unit?.code}
                    numberClassName='fs-4'
                    unitsClassName='small'
                />
            </div>
        )
    }, [product?.name, product?.co2e, product?.unit?.code, product?.productImageUrl])

    const fetchActivities = useCallback(() => {
        if (!product?.uuid) return
        const qs = new URLSearchParams(queryString)
        qs.set('when', 'all')
        qs.set('inventory', product?.uuid)
        // qs.set('activityStates', ActivityService.allStates.join(','))
        activityContext.dispatch({ type: ActivityActionType.SetLoading, payload: true })
        activityService.get(qs.toString()).then((ais) => {
            activityContext.dispatch({ type: ActivityActionType.SetActivityData, payload: ais })
        })
    }, [product?.uuid, queryString])

    // useEffect(() => {
    //     if (tab === 'activities') fetchActivities()
    // }, [tab])

    useEffect(() => {
        return () => resetActivityContext()
    }, [queryString])

    // const locationFactor = useMemo(() => electricity?.location?.[0], [electricity?.location])

    const marketFactor = useMemo(() => electricity?.market?.[0], [electricity?.market])

    const usedIn = useMemo(() => {
        const data = [...(electricity?.location || []), ...(electricity?.market || []), ...(electricity?.custom || [])]
        let ui: Map<string, UsedIn> = new Map()
        product?.usedIn?.forEach((u) => ui.set(u.uuid, u))
        if (!product?.productOf) {
            data?.forEach((l) => l.usedIn?.forEach((u) => u.uuid !== product?.uuid && ui.set(u.uuid, u)))
        }
        return Array.from(ui.values())
    }, [product?.usedIn, product?.productOf, electricity])

    const gridName = useMemo(() => {
        const addressParts = product?.geoLocation?.formattedAddress?.split(',')?.map((p) => p.trim())
        if (!addressParts?.length) return null
        let lastPart = addressParts.pop()
        if (lastPart === 'USA') lastPart = addressParts.pop()
        return <>{lastPart}</>
    }, [product?.geoLocation?.formattedAddress])

    const footprintDisplay = useCallback(
        (fp?: Product, co2e?: string | null, showYear: boolean = true, preview: boolean = false) => {
            return (
                <div
                    role='button'
                    tabIndex={0}
                    key={`loc-${fp?.uuid}`}
                    onClick={() => preview && productService.openPreview(fp)}
                    onKeyDown={() => preview && productService.openPreview(fp)}
                >
                    <CO2e co2e={co2e} onZero={co2e === undefined ? 'dash' : undefined} unitsClassName='small' />
                    <span className='very-small ms-2' hidden={!fp?.startDate || !showYear}>
                        <CalendarDots />{' '}
                        <span className='font-monospace'>{Utils.dayjs(fp?.startDate).format('YYYY')}</span>
                    </span>
                </div>
            )
        },
        [],
    )

    const locations = useMemo(() => {
        return locationService.getAllLocations().filter((l) => {
            if (l.geoLocation?.countryCode === 'US') {
                const state = ElectricityService.getElectricityState(l.geoLocation)
                const productState = ElectricityService.getElectricityState(product?.geoLocation)
                return state === productState
            }
            return l.geoLocation?.countryCode === product?.geoLocation?.countryCode
        })
    }, [product?.geoLocation?.countryCode])

    const titleClassName = useMemo(() => 'd-block text-muted small mb-2', [])

    const elementDataTab = useMemo(
        () => (
            <div className='row mt-4'>
                <div className='col-6 col-md d-flex flex-column gap-4'>
                    <div>
                        <div className={titleClassName}>{Utils.co2e} intensity (location-based actual)</div>
                        {footprintDisplay(
                            product,
                            product?.renewableCo2e !== undefined ? product?.renewableCo2e : product?.co2e,
                            false,
                        )}
                    </div>

                    <div hidden={!marketFactor}>
                        <div className={titleClassName}>{Utils.co2e} intensity (market-based actual)</div>
                        {footprintDisplay(
                            product,
                            product?.coveredCo2e !== undefined ? product?.coveredCo2e : marketFactor?.co2e,
                            false,
                        )}
                    </div>

                    <div>
                        <label htmlFor='meterId' className={titleClassName}>
                            Meter ID
                        </label>
                        <InputField
                            id='meterId'
                            placeholder='Ex: 1234567890'
                            className='variable-form-control ms--2 w-100 fs-body'
                            disabled={props.disabled}
                            defaultValue={product?.sku}
                            onChanged={(sku) => pSave({ sku })}
                        />
                    </div>

                    <div>
                        <label htmlFor='productOf' className={titleClassName}>
                            Supplier
                        </label>
                        <CompanyFilter
                            label='Select'
                            perspective='supplier'
                            company={product?.productOf || undefined}
                            disabled={props.disabled}
                            placement='bottom-start'
                            className='bg-light-hover rounded-1 p-2 m--2'
                            allowCreate={true}
                            showGoTo={true}
                            onSelect={(productOf) => pSave({ productOf })}
                            onClear={() => pSave({ productOf: null })}
                        />
                    </div>

                    <div className='d-flex flex-column align-items-start'>
                        <div className={titleClassName}>Links</div>
                        {usedIn
                            .filter((ui) => [ND.Product, ND.Part, ND.TransportType].includes(ui.type))
                            .map((ui) => (
                                <UsedInItem usedIn={ui} key={`pu-${ui.uuid}`} />
                            ))}
                    </div>
                </div>
                <div className='col-6 col-md d-flex flex-column gap-4'>
                    <div>
                        <label htmlFor='renewablePercentage' className={titleClassName}>
                            On-site renewable production
                        </label>
                        <PrettyNumber
                            num={product?.renewablePercentage}
                            onZero='simple-dash'
                            className=' '
                            suffix='%'
                        />
                    </div>
                    <div hidden={!marketFactor}>
                        <label htmlFor='renewablePercentage' className={titleClassName}>
                            Renewable certificate coverage
                        </label>
                        <PrettyNumber num={product?.coveredPercentage} onZero='simple-dash' className=' ' suffix='%' />
                    </div>
                </div>
                <div className='col-12 col-md-6 d-flex flex-column gap-4'>
                    {!props.isPreview && product?.geoLocation && (
                        <div style={{ height: '250px' }}>
                            <GoogleMap
                                locations={[LocationService.geoToLocation(product?.geoLocation)]}
                                height={250}
                                zoom={3}
                                clickForDynamic={true}
                            />
                        </div>
                    )}
                    <div className='row'>
                        <div className='col'>
                            <div className={titleClassName}>Location</div>
                            <LocationSelector
                                locations={locations}
                                location={product?.location}
                                geoLocation={product?.geoLocation}
                                disabled={props.disabled}
                                className='bg-light-hover rounded-1 p-2 m--2'
                                onLocation={(geoLocation, location) => pSave({ location, geoLocation })}
                            />
                        </div>
                        <div className='col'>
                            <div className={titleClassName}>Electricity grid</div>
                            {gridName}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className={titleClassName}>{Utils.co2e} intensity (location-based grid mix)</div>
                            {electricity?.location?.map((fp) => footprintDisplay(fp, fp?.co2e, true, true))}
                        </div>
                        {electricity?.market?.[0] && (
                            <div className='col'>
                                <div className={titleClassName}>
                                    {Utils.co2e} intensity (market-based, residual grid mix)
                                </div>
                                {electricity?.market?.map((fp) => footprintDisplay(fp, fp?.co2e, true, true))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ),
        [
            electricity,
            gridName,
            product?.renewablePercentage,
            product?.renewableCo2e,
            product?.coveredPercentage,
            product?.coveredCo2e,
            product?.sku,
            product?.productOf,
        ],
    )

    const tabs: Tab[] = useMemo(
        () => [
            {
                slug: '',
                link: ProductService.getProductUrl(product),
                icon: <ListBullets />,
                name: 'Properties',
                content: elementDataTab,
            },
            {
                slug: 'activities',
                link: `${ProductService.getProductUrl(product)}/activities`,
                icon: <Pulse />,
                name: (
                    <>
                        Activity data <PrettyNumber num={activityContext.totalCount} surround='()' className=' ' />
                    </>
                ),
                content: (
                    <Card>
                        <ActivityTable cols={defaultActivityTableColumns} />
                    </Card>
                ),
            },
        ],
        [product, elementDataTab, activityContext.totalCount],
    )

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex align-items-center gap-1'>
                <EnergyIcon size={Utils.mediumIconSize} className='nt--2' />
                <TitleEditor
                    node={product}
                    inputExtraClassName='h5 fw-bold m-0 text-start text-inherit'
                    onChanged={(name) => pSave({ name })}
                    disabled={props.disabled}
                />
                <EtcMenu extraClassName='ms-auto'>
                    <Delete
                        className='dropdown-item'
                        disabled={props.disabled || !!product?.usedIn?.length}
                        deleteFn={() =>
                            productService.deleteProduct(product?.uuid).then(() => {
                                Utils.deletedToast(`${ProductService.elementTitle()} has been deleted`)
                                navigate(InventoryService.getCurrentView())
                            })
                        }
                    />
                </EtcMenu>
            </div>
            {header}
            <Tabs hidden={props.isPreview} tabs={tabs} tabSlug={tab || ''} />
            <TabContent tabs={tabs} tabSlug={tab || ''} />
            <Footnotes node={product} saving={saving} extraClassName='mt-auto' />
            <ActivitySlider />
        </div>
    )
}
