import { KeyValuePair, UsedIn, VariableBaseNode } from '../types'
import GeoLocationService, { GeoLocation } from './geoLocation'
import VariableService from './service'
import Utils from './utils'
import { UIOptionActionType } from './ui'
import {
    Building,
    Factory,
    GlobeSimple,
    IconProps,
    MapPinArea,
    MapPinLine,
    ShippingContainer,
    Warehouse,
} from '@phosphor-icons/react'
import { ReactNode } from 'react'
import { Product } from './product'
import { ProcessingType } from './processing'
import { UseStageType } from './useStage'

export type LocationType = 'geo' | 'factory' | 'warehouse' | 'office' | 'port' | 'other'

export interface Location extends VariableBaseNode {
    name: string
    address1?: string
    address2?: string
    postCode?: string
    city?: string
    county?: string
    state?: string
    country?: string
    type?: LocationType
    geoLocation?: GeoLocation
    usedIn?: UsedIn[]
}

export default class LocationService extends VariableService {
    private basePath: string = '/location'
    public static webRootList: string = '/locations'
    public static webRootDetail: string = '/location'
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Location', plural ? 2 : 1)

    public static locations: Location[] = []
    private static locationById: Map<string, Location> = new Map<string, Location>()

    public static getById(locationId?: string): Location | undefined {
        if (!locationId) return undefined
        return LocationService.locationById.get(locationId)
    }

    public getAllLocations(): Location[] {
        let _list = [...LocationService.locations]
        GeoLocationService.list.forEach((gl) => _list.push(LocationService.geoToLocation(gl)))
        return _list.sort((a, b) => Utils.sortByFormattedName(a, b, LocationService.getLocationName))
    }

    public static locationTypes: KeyValuePair<LocationType>[] = [
        { value: 'factory', name: 'Factory', icon: <Factory /> },
        { value: 'warehouse', name: 'Warehouse', icon: <Warehouse /> },
        { value: 'office', name: 'Office', icon: <Building /> },
        { value: 'port', name: 'Port', icon: <ShippingContainer /> },
        { value: 'other', name: 'Other', icon: <GlobeSimple /> },
    ]

    public static locationFilter(o?: any, searchText?: string): boolean {
        if (searchText) {
            let st = searchText.replace(/[^a-zA-Z0-9\s]/g, '')
            const searchData: string[] = [LocationService.getLocationName(o)]
            if (o?.geoLocation?.aka) searchData.push(...o.geoLocation.aka)
            if (o?.geoLocation?.formattedAddress) searchData.push(o.geoLocation.formattedAddress)
            return Utils.filterItem(searchData, st)
        }
        return true
    }

    public static getNodeLocation(node?: Product | ProcessingType | UseStageType): Location | undefined {
        if (node?.location) return node?.location
        if (node?.geoLocation) return LocationService.geoToLocation(node?.geoLocation)
        return undefined
    }

    public static getLocationName(
        location?: Location | null,
        geoLocation?: GeoLocation | null,
        includeGeoLocation?: boolean,
    ): string {
        if (location?.type === 'geo') return GeoLocationService.getName(location.geoLocation)
        if (!location && geoLocation) return GeoLocationService.getName(geoLocation)
        let locationString: string[] = []
        if (location?.name) locationString.push(location.name)
        else if (location?.type) locationString.push(LocationService.getLocationType(location))
        if (location?.geoLocation && includeGeoLocation !== false) {
            locationString.push(`(${GeoLocationService.getName(location.geoLocation)})`)
        }
        return locationString.join(' ')
    }

    public static getLocationType(location?: Location): string {
        if (location?.type === 'geo') {
            if (location.geoLocation?.locationType === 'ROOFTOP') return 'Address'
            return 'Geography'
        }
        return (LocationService.locationTypes.find((t) => t.value === location?.type)?.name || '') as string
    }

    public static getLocationTypeIcon(location?: Partial<Location>, iconProps?: Partial<IconProps>): ReactNode {
        switch (location?.type) {
            case 'factory':
                return <Factory {...iconProps} />
            case 'warehouse':
                return <Warehouse {...iconProps} />
            case 'office':
                return <Building {...iconProps} />
            case 'port':
                return <ShippingContainer {...iconProps} />
        }
        if (location?.type === 'geo') {
            if (location?.geoLocation?.locationType === 'ROOFTOP') return <MapPinLine />
            return <MapPinArea {...iconProps} />
        }
        return <GlobeSimple {...iconProps} />
    }

    public static geoToLocation(geo: GeoLocation): Location {
        return {
            uuid: geo.uuid,
            name: GeoLocationService.getName(geo),
            type: 'geo',
            usedIn: geo.usedIn,
            geoLocation: geo,
        }
    }

    public openLocation(locationId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetLocationId, payload: locationId })
    }

    private setLocations(locations: Location[]): void {
        locations.forEach((l) => l.uuid && LocationService.locationById.set(l.uuid, l))
        LocationService.locations = Array.from(LocationService.locationById.values()).sort((a, b) =>
            Utils.sortByFormattedName(a, b, LocationService.getLocationName),
        )
        this.context.dispatch({ type: UIOptionActionType.LocationsUpdated })
    }

    public async getLocations(): Promise<Location[]> {
        return this.httpService.get<Location[]>(this.basePath).then((l) => {
            this.setLocations(l)
            return l
        })
    }

    public async getLocation(locationId: string): Promise<Location> {
        return this.httpService.get<Location>(`${this.basePath}/${locationId}`)
    }

    public async saveLocation(location: Partial<Location>): Promise<Location> {
        return this.httpService.put<Location>(this.basePath, { body: JSON.stringify({ location }) }).then((l) => {
            if (l.uuid) this.setLocations([l])
            return l
        })
    }

    public async deleteLocation(locationId: string): Promise<void> {
        return this.httpService.delete(`${this.basePath}/${locationId}`).then(() => {
            LocationService.locationById.delete(locationId)
            this.openLocation(undefined)
            this.setLocations([])
        })
    }

    public async moveGeoLocationsToLocation(locationId: string, usedInIds?: string[]): Promise<void> {
        if (!usedInIds) return
        return this.httpService.post(this.basePath, { body: JSON.stringify({ locationId, usedInIds }) })
    }
}
