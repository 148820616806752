import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import InputService, { Input } from '../services/input'

export const useInput = (props: { input?: Input | null; inputId?: string; condition?: boolean }) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        return InputService.getById(props.inputId || props.input?.uuid) || props.input || undefined
    }, [props.inputId, props.input?.uuid, props.condition, context.stores.input.updates, context.stores.parts.updates])
}

export const useInputs = (props: { inputIds?: string[]; condition?: boolean }): Input[] => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return []
        const inputList = props.inputIds?.map((inputId) => InputService.getById(inputId))
        return (inputList?.filter((input) => input) as Input[]) || []
    }, [props.inputIds, props.condition, context.stores.input.updates, context.stores.parts.updates])
}
