import { IconProps, MagnifyingGlass, X } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import InputField, { InputFieldProps } from './InputField'
import Button, { ButtonProps } from './Button'
import { useEffect, useMemo, useRef, useState } from 'react'
import { StandardAttributes } from '../../types'

export interface SearchBoxProps extends StandardAttributes {
    searchTerm?: string
    clearIconProps?: Partial<IconProps>
    searchIconProps?: Partial<IconProps>
    buttonProps?: Partial<ButtonProps>
    inputFieldProps?: Partial<InputFieldProps>
    onChange?: (searchTerm?: string) => void
    onClear?: () => void
}

export const SearchBox = (props: SearchBoxProps) => {
    const [searchTerm, setSearchTerm] = useState<string | number | undefined>(props.inputFieldProps?.defaultValue)
    const internalRef = useRef<HTMLInputElement>(null)
    const searchRef = props.inputFieldProps?.passedRef || internalRef
    const [id] = useState<string>(props.inputFieldProps?.id || `search-box-${Math.random()}`)

    useEffect(() => setSearchTerm(props.inputFieldProps?.defaultValue), [props.inputFieldProps?.defaultValue])

    const className = useMemo(
        () =>
            [
                props.className || 'd-flex align-items-center gap-1 clickable',
                'variable-form-control bg-white w-100 border',
                props.extraClassName,
            ].join(' '),
        [props.className, props.extraClassName],
    )

    const clearButton = useMemo(() => {
        return (
            <Button
                className={[
                    !searchTerm ? 'position-absolute visibility-hidden' : '',
                    'btn btn-sm btn-plain flex-shrink-0 flex-grow-0',
                ].join(' ')}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setSearchTerm(undefined)
                    if (searchRef.current) {
                        searchRef.current.value = ''
                        searchRef.current.focus()
                    }
                    props.inputFieldProps?.onChange?.('')
                    props.inputFieldProps?.onChanged?.('')
                    props.onClear?.()
                }}
                {...props.buttonProps}
            >
                <X size={Utils.verySmallIconSize} {...props.clearIconProps} />
            </Button>
        )
    }, [searchTerm, searchRef, props.buttonProps])

    return (
        <label htmlFor={id} className={className} style={props.style}>
            <MagnifyingGlass
                size={Utils.verySmallIconSize}
                color={Utils.bodyColor}
                className='flex-shrink-0 flex-grow-0'
                {...props.searchIconProps}
            />
            <InputField
                id={id}
                passedRef={searchRef}
                ariaLabel='Search'
                placeholder='Search'
                className='flex-grow-1 border-0 bg-transparent outline-0 w-100'
                maxLength={100}
                {...props.inputFieldProps}
                onChange={(st) => {
                    setSearchTerm(st)
                    props.inputFieldProps?.onChange?.(st)
                }}
            />
            {clearButton}
        </label>
    )
}
