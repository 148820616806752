import { Handle, NodeProps, Position } from 'reactflow'
import { FlowService, INPUT_NODE_HEIGHT, INPUT_NODE_WIDTH } from '../../services/flow'
import Utils from '../../services/utils'
import { InputActionType } from '../../services/input'
import CO2e from '../CO2e'
import { Lightning, Plug, Wrench } from '@phosphor-icons/react'
import { TransportIcons } from '../Icons/TransportIcons'
import { UseStageSummary } from '../Input/UseStage'
import { PrettyNumber } from '../PrettyNumber'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import Button from '../Input/Button'
import { useInputType } from '../../hooks/useInputType'
import { VariableServicesContext } from '../../services'
import { InventoryIcon } from '../Icons/InventoryIcon'
import LocationService from '../../services/location'
import { ProcessingCalculator } from '../Input/Processing'
import { InventoryType } from '../../services/inventory'
import { useInput } from '../../hooks/useInput'
import { useProduct } from '../../hooks/useProduct'
import { useTransportInstance } from '../../hooks/useTransportInstance'
import { useProcessingType } from '../../hooks/useProcessingType'
import { useUseStageType } from '../../hooks/useUseStageType'
import { useTransportType } from '../../hooks/useTransportType'

export const InputNode = ({ id, zIndex, data }: NodeProps) => {
    const context = useContext(ApplicationContext)
    const { processingService, useStageService } = useContext(VariableServicesContext)

    const input = useInput({ input: data?.input })
    const product = useProduct({ product: data?.product })
    const transportInstance = useTransportInstance({ transportInstance: input?.transportInstance })
    const transportType = useTransportType({ transportType: transportInstance?.transportType })
    const processingType = useProcessingType({ processingType: input?.processingType })
    const useStageType = useUseStageType({ useStageType: input?.useStageType })

    const _isDirect = useMemo(() => input?.useStageCategory?.type === 'Direct', [input?.useStageCategory])
    const _isDownstream = useMemo(() => input?.useStageCategory?.type === 'Downstream', [input?.useStageCategory])
    const _isInputType = useMemo(() => processingType || transportInstance, [processingType, transportInstance])

    const {
        createTransportInstance,
        creatingTransport,
        editTransportInstance,
        createProcessingType,
        creatingProcessing,
    } = useInputType({ instanceId: input?.uuid || '' })

    const icon = useMemo(() => {
        if (transportInstance) {
            return <TransportIcons node={input} transportType={transportType} iconProps={{ color: Utils.fadedColor }} />
        } else if (['A2', 'A4', 'C1'].includes(input?.useStageCategory?.code || '')) {
            return <TransportIcons iconProps={{ color: Utils.fadedColor }} />
        } else if (useStageType || ['B1'].includes(input?.useStageCategory?.code || '')) {
            return <Lightning color={Utils.fadedColor} />
        } else if (input?.useStageCategory?.name === 'Production') {
            return <Wrench color={Utils.fadedColor} />
        }
        return <InventoryIcon color={Utils.fadedColor} />
    }, [transportInstance, useStageType, input?.useStageCategory])

    const className = useMemo(() => {
        if (!input) return ''
        if (
            (context.stores.input?.currentId && context.stores.input?.currentId === input?.uuid && !_isInputType) ||
            (context.stores.transport?.instanceId &&
                context.stores.transport?.instanceId === input.transportInstance?.uuid) ||
            (context.stores.processingType?.processingId &&
                !context.stores.processingType.byproductId &&
                context.stores.processingType?.processingId === processingType?.uuid) ||
            (context.stores.useStageType?.useStageId && context.stores.useStageType.useStageId === useStageType?.uuid)
        ) {
            return FlowService.nodeHighlightClass
        }
        return ''
    }, [
        input?.uuid,
        transportInstance?.uuid,
        processingType?.uuid,
        useStageType?.uuid,
        context.stores.input?.currentId,
        context.stores.transport?.instanceId,
        context.stores.processingType.processingId,
        context.stores.processingType.byproductId,
        context.stores.useStageType.useStageId,
        _isInputType,
    ])

    const transportBody = useMemo(() => {
        if (!input) return null
        return (
            <Button
                className={[
                    'position-relative hover-parent p-1 px-2 bg-white text-center bg-light-hover',
                    _isDirect ? 'border' : 'border border-primary rounded-2 ',
                    className,
                    transportInstance?.uuid ? '' : 'border-dashed',
                ].join(' ')}
                saving={creatingTransport}
                style={{ width: `${INPUT_NODE_WIDTH}px`, height: `${INPUT_NODE_HEIGHT}px`, zIndex: zIndex }}
                onClick={async () => {
                    if (transportInstance?.uuid) {
                        await editTransportInstance(input)
                    } else {
                        await createTransportInstance(product, input)
                    }
                }}
            >
                {!transportInstance?.uuid && (
                    <div>
                        <TransportIcons /> Add Transport
                    </div>
                )}
                {transportInstance?.uuid && (
                    <div className='d-flex align-items-center gap-3'>
                        <div className='text-start small overflow-hidden'>
                            <div className='d-flex align-items-center gap-1'>
                                <TransportIcons transportType={transportType} node={input} />
                                <span className='text-overflow-ellipsis'>
                                    {transportInstance.name || transportType?.name || input?.name}
                                </span>
                            </div>
                            <div>
                                <PrettyNumber num={transportInstance.weight?.quantity} />{' '}
                                {transportInstance.weight?.unit?.code}, <PrettyNumber num={transportInstance.tkm} /> tkm
                            </div>
                        </div>
                        <CO2e
                            co2e={transportInstance?.co2e}
                            className='text-end ms-auto small'
                            unitsClassName='d-block text-muted small'
                        />
                    </div>
                )}
                <Handle hidden={_isDirect} type='target' position={Position.Left} id={id} />
                <Handle hidden={_isDirect} type='source' position={Position.Right} id={id} />
            </Button>
        )
    }, [
        transportInstance,
        creatingTransport,
        product,
        input,
        className,
        zIndex,
        _isDirect,
        id,
        editTransportInstance,
        createTransportInstance,
    ])

    const processingLocation = useMemo(
        () => LocationService.getNodeLocation(processingType || undefined),
        [processingType],
    )

    const energyBody = useMemo(() => {
        if (!input || data?.label !== 'Energy') return null
        return (
            <Button
                className='position-relative hover-parent border border-scope1 rounded-2 p-1 px-2 small bg-white text-center bg-light-hover'
                style={{ width: `${INPUT_NODE_WIDTH}px`, zIndex: zIndex }}
                saving={creatingProcessing}
                onClick={() => processingService.editProcessingType({ node: input })}
            >
                <div className='d-flex align-items-center gap-3'>
                    <div className='text-start'>
                        <Lightning /> <PrettyNumber num={processingType?.energy?.quantity} />{' '}
                        {processingType?.energy?.unit?.code}
                        <div>{LocationService.getLocationName(processingLocation)}</div>
                    </div>
                    <CO2e
                        co2e={processingType?.co2e}
                        className='text-end ms-auto'
                        unitsClassName='d-block text-muted small'
                    />
                </div>
                <Handle type='source' position={Position.Bottom} id='energySource' />
            </Button>
        )
    }, [input, processingType, creatingProcessing, processingLocation, zIndex, data?.label])

    const processingBody = useMemo(() => {
        if (!input) return null
        return (
            <Button
                className={[
                    'position-relative bg-white text-center px-2 py-1 bg-light-hover',
                    className,
                    _isDirect && !input.processingFor ? 'border' : 'border border-primary rounded-2 ',
                    processingType?.uuid ? '' : 'border-dashed',
                ].join(' ')}
                style={{ width: `${INPUT_NODE_WIDTH}px`, height: `${INPUT_NODE_HEIGHT}px`, zIndex: zIndex }}
                onClick={async () => {
                    if (processingType?.uuid) {
                        processingService.editProcessingType({ node: input })
                    } else {
                        await createProcessingType(product, input)
                    }
                }}
            >
                {!processingType?.uuid && (
                    <div>
                        <InventoryIcon inventoryType={InventoryType.Energy} /> Add Process
                    </div>
                )}
                {processingType?.uuid && (
                    <div className='d-flex align-items-center justify-content-between gap-2 small'>
                        <ProcessingCalculator
                            node={input}
                            startOpen={true}
                            processingType={processingType}
                            bodyOnly={true}
                        />
                        <CO2e
                            co2e={processingType?.co2e}
                            className='text-end ms-auto'
                            unitsClassName='d-block text-muted small'
                        />
                    </div>
                )}
                <Handle type='target' position={Position.Left} id={id} />
                <Handle type='source' position={Position.Right} id={id} />
                {/*<Handle hidden={!processingType?.uuid} type='target' position={Position.Top} id='energyTarget' />*/}
            </Button>
        )
    }, [input, processingType, processingLocation, zIndex, id, className, product, _isDirect])

    const useStageBody = useMemo(() => {
        if (!input) return null
        return (
            <Button
                className={[
                    'position-relative hover-parent border bg-white bg-light-hover text-center px-2 py-1',
                    className,
                ].join(' ')}
                style={{ width: `${INPUT_NODE_WIDTH}px`, height: `${INPUT_NODE_HEIGHT}px`, zIndex: zIndex }}
                onClick={() => useStageService.editUseStageType({ node: input })}
            >
                <div className='d-flex align-items-center gap-1 small'>
                    <div className='text-start overflow-hidden'>
                        <div className='text-overflow-ellipsis'>
                            <Plug /> {useStageType?.name || input.name || 'Use Stage'}
                        </div>
                        <UseStageSummary useStageType={useStageType} className='d-block text-overflow-ellipsis' />
                    </div>
                    <CO2e
                        className='ms-auto text-end'
                        co2e={input?.co2e}
                        numberClassName='d-block font-monospace'
                        unitsClassName='d-block text-muted small'
                    />
                </div>
            </Button>
        )
    }, [useStageType, input, className, zIndex, useStageService])

    const inputBody = useMemo(() => {
        if (!input) return null
        return (
            <Button
                className={[
                    'position-relative hover-parent border border-1 p-2 bg-white text-start bg-light-hover z-index-popover',
                    className,
                ].join(' ')}
                style={{ width: `${INPUT_NODE_WIDTH}px`, height: `${INPUT_NODE_HEIGHT}px`, zIndex: zIndex }}
                onClick={() => context.dispatch({ type: InputActionType.SetInputId, payload: input?.uuid })}
            >
                <div className='d-flex align-items-center justify-content-between gap-2 small overflow-hidden'>
                    <span>{icon}</span>
                    <div className='text-overflow-ellipsis'>
                        <div className='text-overflow-ellipsis' title={input?.name}>
                            {input?.name || <span className='text-very-muted'>(Unnamed Input)</span>}
                        </div>
                        <div className='text-overflow-ellipsis' hidden={!input?.useStageCategory?.code}>
                            {input?.useStageCategory?.code}: {input?.useStageCategory?.name}
                        </div>
                    </div>
                    <div className='ms-auto text-end'>
                        <div className='text-nowrap'>
                            <PrettyNumber num={input?.quantity} /> {input?.unit?.code}
                        </div>
                        <CO2e co2e={input?.co2e} />
                    </div>
                </div>
                <Handle hidden={!_isDownstream} type='source' position={Position.Right} id={id} />
                <Handle hidden={_isDownstream} type='target' position={Position.Left} id={id} />
            </Button>
        )
    }, [input, icon, className, zIndex, _isDownstream, id])

    if (!input) return null

    if (transportInstance?.uuid || data?.label === 'Transport') {
        return transportBody
    }

    if (data?.label === 'Energy') {
        return energyBody
    } else if (processingType?.uuid || data?.label === 'Processing') {
        return processingBody
    }

    if (useStageType?.uuid) {
        return useStageBody
    }

    return inputBody
}
