import { Selector, SelectorProps } from './Selector'
import { useContext, useEffect, useMemo, useState } from 'react'
import { VariableServicesContext } from '../../services'
import { KeyValuePair } from '../../types'
import TaxonomyService, { Taxonomy } from '../../services/taxonomy'
import { ApplicationContext } from '../../context'
import { InventoryService } from '../../services/inventory'
import Button from './Button'

interface TaxonomySelectorProps extends SelectorProps {
    filterList?: (o: KeyValuePair<string>) => boolean
    onTaxonomy?: (taxonomy?: Taxonomy) => void
    showOnlyUsed?: boolean
}

export const TaxonomySelector = (props: TaxonomySelectorProps) => {
    const context = useContext(ApplicationContext)
    const { taxonomyService } = useContext(VariableServicesContext)
    const [showAll, setShowAll] = useState<boolean>(false)

    useEffect(() => {
        taxonomyService.get().then()
    }, [])

    const options = useMemo(() => {
        let list = [...TaxonomyService.optionList]
        if (props.filterList) list = list.filter(props.filterList)
        if (props.showOnlyUsed && !showAll)
            list = list.filter((o) => {
                const tx = o.node as Taxonomy
                return tx?.path && InventoryService.taxonomyPathInUse.has(tx?.path)
            })
        return list
    }, [props.filterList, context.stores.ui?.taxonomyReady, context.stores.inventory.updated, showAll])

    const footer = useMemo(() => {
        if (!props.showOnlyUsed) return null
        return (
            <div className='border-top p-1 pt-2 px-3 mx--2'>
                <Button className='btn btn-sm btn-light border' onClick={() => setShowAll(!showAll)}>
                    {!showAll ? 'Show all categories' : 'Show categories in use'}
                </Button>
            </div>
        )
    }, [props.showOnlyUsed, showAll])

    return (
        <Selector
            placeholder='Category'
            placement='bottom-start'
            options={options}
            {...props}
            onSelect={(newValue) => {
                props.onSelect(newValue)
                props.onTaxonomy?.(TaxonomyService.getPlainTaxonomy(newValue.node))
            }}
            footer={footer}
        />
    )
}
