import { ReactNode, useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { KeyValuePair } from '../../types'
import { Selector, SelectorProps } from '../Input/Selector'
import TransportService, { TransportSegment } from '../../services/transport'
import { TransportIcons } from '../Icons/TransportIcons'
import { InventoryService } from '../../services/inventory'

type TransportModeSelectorProps = SelectorProps & {
    segment: TransportSegment
}

export const TransportModeSelector = (props: TransportModeSelectorProps) => {
    const context = useContext(ApplicationContext)

    const customTransportModes: KeyValuePair<string>[] = useMemo(() => {
        return InventoryService.getList()
            .filter((i) => i.originalProduct?.uuid && i.taxonomy?.path?.startsWith('transport'))
            .map((i) => ({
                icon: <TransportIcons transportMode={TransportService.getMode(i.taxonomy)} className='nt--1' />,
                name: i.name,
                value: i.uuid as string,
                description: i.productOf?.name,
                node: i.originalProduct,
            }))
    }, [context.stores.inventory?.updated])

    let segmentDistance = useMemo(() => props.segment.distance?.quantity || 0, [props.segment.distance?.quantity])

    const isPremium = useMemo(
        () => context.stores.company?.subscription?.database === 'premium',
        [context.stores.company?.subscription?.database],
    )

    const modeOptions = useMemo(() => {
        const databaseOptions = [...TransportService.transportModes]
            .filter((o) => {
                if (!isPremium || !o.value?.startsWith('air') || ['air-cooling', 'air-freezing'].includes(o.value))
                    return true
                if (segmentDistance > 4000) return o.value === 'air-long'
                if (segmentDistance > 1500) return o.value === 'air-medium'
                if (segmentDistance > 800) return o.value === 'air-short'
                return o.value === 'air-very-short'
            })
            .map((o) => {
                if (o.metaData?.premium && !isPremium) {
                    o.disabled = true
                }
                return o
            })
        if (customTransportModes?.[0]) {
            customTransportModes[0].metaData = 'customStart'
        }
        if (databaseOptions?.[0]) {
            databaseOptions[0].metaData = 'dbStart'
        }
        return [...customTransportModes, ...databaseOptions]
    }, [isPremium, customTransportModes, segmentDistance])

    const optionId = useMemo(() => {
        if (props.segment.customFootprint && props.segment.footprint?.uuid) {
            return props.segment.footprint?.uuid
        }
        return props.segment.footprintSyncId || props.segment.mode
    }, [
        props.segment.customFootprint,
        props.segment.footprint?.uuid,
        props.segment.footprintSyncId,
        props.segment.mode,
    ])

    return (
        <Selector
            placeholder='Select'
            placement='bottom-start'
            className='variable-form-select'
            tooltipStyle={{ width: '250px' }}
            options={modeOptions}
            option={optionId}
            renderValueLabel={(o) => o.name}
            renderItemValue={(option) => {
                let _premiumLabel: ReactNode = null
                if (option.metaData?.premium && !isPremium) {
                    _premiumLabel = <span className='premium-label'>Premium</span>
                }
                return (
                    <div className={option.metaData?.premium && !isPremium ? 'text-very-muted' : ''}>
                        <strong>{option.name}</strong>
                        <div>
                            {option.description} {_premiumLabel}
                        </div>
                    </div>
                )
            }}
            renderItem={(option, item, id) => {
                let extra: ReactNode
                if (option.metaData === 'customStart') {
                    extra = <span className='d-block fw-bold bg-light border-bottom p-1'>Inventory</span>
                }
                if (option.metaData === 'dbStart') {
                    extra = <span className='d-block fw-bold bg-light border-bottom p-1'>Database</span>
                }
                return (
                    <span key={id}>
                        {extra}
                        {item}
                    </span>
                )
            }}
            {...props}
        />
    )
}
