import ProductService, { Product } from '../../services/product'
import Utils from '../../services/utils'
import { Link } from 'react-router-dom'
import { CSSProperties, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import Tooltip, { TooltipProps } from '../Tooltip'
import { MiniPassport } from '../MiniPassport'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { InventoryService } from '../../services/inventory'
import { Factory, IconProps } from '@phosphor-icons/react'
import { useProduct } from '../../hooks/useProduct'
import { useCompany } from '../../hooks/useCompany'
import { ConnectionStatus } from '../Icons/ConnectionStatus'

export const ElementBadge = (props: {
    product?: Product
    className?: string
    showCompany?: boolean
    followLink?: boolean
    notClickable?: boolean
    noTooltip?: boolean
    style?: CSSProperties
    extraClassName?: string
    nameClassName?: string
    showConnectionStatus?: boolean
    iconProps?: Partial<IconProps>
    companyIconProps?: Partial<IconProps>
    tooltipProps?: Partial<TooltipProps>
    onClick?: (product?: Product) => void
    extraOnClick?: () => void
}) => {
    const { productService } = useContext(VariableServicesContext)

    const product = useProduct({ product: props.product })
    const productOf = useCompany({ company: product?.productOf })

    const content = useMemo(
        () => (
            <span className='d-flex flex-column'>
                <span className='d-flex align-items-center gap-1 w-100 text-nowrap'>
                    <InventoryIcon
                        inv={product && InventoryService.productToInventory(product)}
                        className='flex-shrink-0 nt--1'
                        {...props.iconProps}
                    />
                    {props.showConnectionStatus && <ConnectionStatus company={productOf} />}
                    <span
                        className={
                            props.nameClassName || 'd-block text-start text-overflow-ellipsis underline-on-hover-parent'
                        }
                    >
                        {ProductService.getProductName(product)}
                    </span>
                </span>
                {props.showCompany && productOf?.name && (
                    <span className='d-flex align-items-center gap-1 w-100 fs-body align-self-start small'>
                        <Factory className='flex-shrink-0 nt--1' {...props.companyIconProps} />
                        {productOf?.name}
                    </span>
                )}
            </span>
        ),
        [product, productOf, props.nameClassName, props.iconProps],
    )

    const className = useMemo(
        () => props.className || ['input-element hover-parent', props.extraClassName].join(' '),
        [props.className, props.extraClassName],
    )

    if (!product) return null

    if (props.notClickable) {
        return (
            <span className={['not-clickable', className].join(' ')} style={props.style}>
                {content}
            </span>
        )
    }

    return (
        <Tooltip
            trigger='hover'
            placement='top'
            positioning='fixed'
            disabled={props.noTooltip}
            tooltipStyle={{ maxWidth: '400px' }}
            tooltipContent={<MiniPassport product={product} hideIcon={true} />}
            showArrow={false}
            {...props.tooltipProps}
        >
            <Link
                to={ProductService.getProductUrl(product)}
                className={className}
                style={props.style}
                onClick={(e) => {
                    if (!Utils.isModifierKey(e)) {
                        e.preventDefault()
                        if (props.onClick) {
                            props.onClick?.(product)
                        } else {
                            productService.openPreview(product)
                        }
                    }
                }}
            >
                {content}
            </Link>
        </Tooltip>
    )
}
