import { Handle, NodeProps, Position } from 'reactflow'
import CO2e from '../CO2e'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { PrettyNumber } from '../PrettyNumber'
import { Recycle } from '@phosphor-icons/react'
import { FlowService, INPUT_NODE_WIDTH } from '../../services/flow'
import Button from '../Input/Button'
import { VariableServicesContext } from '../../services'
import { useProcessingType } from '../../hooks/useProcessingType'
import { useByproductType } from '../../hooks/useByproductType'
import { useInput } from '../../hooks/useInput'

export const ByproductNode = ({ id, data }: NodeProps) => {
    const context = useContext(ApplicationContext)
    const { processingService } = useContext(VariableServicesContext)

    const input = useInput({ input: data?.input })
    const processingType = useProcessingType({ processingType: data.processingType })
    const byproductType = useByproductType({ byproductType: data?.byproductType })

    const className = useMemo(() => {
        if (context.stores.processingType.byproductId === byproductType?.uuid) {
            return FlowService.nodeHighlightClass
        }
        return ''
    }, [context.stores.processingType.byproductId, byproductType?.uuid])

    if (!input || !byproductType) return null

    return (
        <Button
            className={[
                'border border-primary rounded-2 py-1 px-2 bg-white small overflow-hidden text-start bg-light-hover',
                className,
            ].join(' ')}
            style={{ maxWidth: `${INPUT_NODE_WIDTH}px` }}
            onClick={() => {
                processingService.editProcessingType({
                    node: input,
                    processingType: processingType,
                    byproduct: byproductType,
                })
            }}
        >
            <div className='d-flex align-items-center gap-3 hover-parent overflow-hidden'>
                <div className='overflow-hidden'>
                    <Recycle /> <PrettyNumber num={byproductType.amount?.quantity} /> {byproductType.amount?.unit?.code}
                    <div className='text-overflow-ellipsis'>{byproductType.name || byproductType.footprint?.name}</div>
                </div>
                <CO2e
                    co2e={byproductType?.co2e}
                    className='ms-auto text-end'
                    unitsClassName='d-block text-muted small text-end'
                />
            </div>
            <Handle type='target' position={Position.Left} id={id} />
            <Handle type='source' position={Position.Right} id={id} />
        </Button>
    )
}
