import { User } from '../../services/user-context'
import { Img } from '../Img'
import { Buildings, User as UserIcon } from '@phosphor-icons/react'
import { CSSProperties, ReactNode, useMemo } from 'react'
import { Company } from '../../services/company'

export const UserCard = (props: {
    children?: ReactNode
    user?: Partial<User>
    company?: Partial<Company> | null
    inline?: boolean
    iconOnly?: boolean
    iconSize?: string
    iconClassName?: string
    className?: string
    extraClassName?: string
    placeholderExtraClassName?: string
    style?: CSSProperties
}) => {
    const iconClassName = useMemo(() => {
        if (props.inline) {
            return 'rounded-circle nt-4 flex-shrink-0'
        }
        return 'rounded-circle nt--1 flex-shrink-0'
    }, [props.inline])

    const className = useMemo(() => {
        if (props.inline) {
            return 'd-inline-flex align-items-baseline gap-1 text-height-1'
        }
        return 'd-flex align-items-center gap-1 text-height-1'
    }, [props.inline])

    const title = useMemo(() => {
        let _title = props.user?.name
        if (props.user?.email) _title += ` <${props.user.email}>`
        return _title
    }, [props.user])

    const icon = useMemo(() => {
        if (!props.user) return null
        return (
            <Img
                src={props.user.profileImageUrl}
                alt=''
                size={props.iconSize || '20px'}
                className={props.iconClassName || iconClassName}
                placeholderClassName={[
                    'nt--1 d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 very-small text-muted fw-bold',
                    props.placeholderExtraClassName,
                ].join(' ')}
                placeholderIconClassName='rounded-circle nt--1'
                placeholderIcon={<UserIcon />}
                // initials={true}
            />
        )
    }, [props.iconSize, props.iconClassName, props.placeholderExtraClassName, props.user])

    const content = useMemo(() => {
        if (!props.user) return null

        if (props.user.type === 'guest') {
            return (
                <>
                    <Buildings className={iconClassName} />
                    <span className='text-overflow-ellipsis max-w-100'>{props.company?.name || ''}</span>
                </>
            )
        }

        return (
            <>
                {icon}
                {!props.iconOnly && props.user.name}
                {!props.iconOnly && props.children}
            </>
        )
    }, [props.user?.uuid, props.company?.uuid, props.children, props.iconOnly, icon, iconClassName])

    if (!props.user) return null

    return (
        <span
            className={props.className || [className, props.extraClassName].join(' ')}
            title={title}
            style={props.style}
        >
            {content}
        </span>
    )
}
