import TransportService, { TransportType } from '../../services/transport'
import { TransportIcons } from '../Icons/TransportIcons'
import { StandardAttributes } from '../../types'
import { useContext, useMemo } from 'react'
import { useTransportType } from '../../hooks/useTransportType'
import { Link } from 'react-router-dom'
import { VariableServicesContext } from '../../services'
import Utils from '../../services/utils'

export const TransportBadge = (
    props: StandardAttributes & {
        transportType?: TransportType | null
        extraOnClick?: () => void
    },
) => {
    const { transportService } = useContext(VariableServicesContext)
    const transportType = useTransportType({ transportType: props.transportType })
    const icons = useMemo(() => <TransportIcons transportType={transportType} />, [transportType])
    const name = useMemo(() => TransportService.getTransportTypeName(transportType), [transportType])
    if (!transportType) return null
    return (
        <Link
            to={TransportService.getTransportTypeUrl(transportType)}
            onClick={(e) => {
                if (Utils.isModifierKey(e)) return
                e.preventDefault()
                transportService.setTransportType(transportType)
                props.extraOnClick?.()
            }}
            className={[
                'd-inline-flex flex-nowrap align-items-center gap-1 underline-on-hover',
                props.extraClassName,
            ].join(' ')}
            style={props.style}
        >
            {icons}
            <span className='text-overflow-ellipsis'>{name}</span>
        </Link>
    )
}
