import Utils from '../services/utils'
import { FriendlyNumber, StandardAttributes } from '../types'
import { ReactNode, useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'

const friendlyLargeNumbers: FriendlyNumber[] = [
    { value: 1_000_000_000, name: '1B+' },
    { value: 1_000_000, name: '1M+' },
    { value: 1_000, name: '1K+' },
]

export interface PrettyNumberProps extends StandardAttributes {
    decimalClassName?: string
    precision?: number
    showZeroesToPrecision?: boolean
    showZeroesToNumber?: boolean
    friendlyNumbers?: FriendlyNumber[]
    friendlyLargeNumbers?: boolean
    num?: number | string | null
    prefix?: ReactNode
    pluralizePrefix?: boolean
    suffix?: ReactNode
    pluralizeSuffix?: boolean
    surround?: string
    onZero?: 'fade' | 'dash' | 'simple-dash'
    zeroValue?: ReactNode
    onUndefined?: 'spinner' | 'empty' | 'dash' | 'simple-dash'
    undefinedValue?: ReactNode
}

export const PrettyNumber = (props: PrettyNumberProps) => {
    const context = useContext(ApplicationContext)
    const prefix = useMemo(() => {
        let _prefix = props.prefix
        if (!_prefix && props.surround) _prefix = props.surround.split('')?.[0]
        if (props.pluralizePrefix && typeof _prefix === 'string') {
            return Utils.pluralize(_prefix, props.num as number)
        }
        return _prefix
    }, [props.prefix, props.pluralizePrefix, props.num, props.surround])

    const suffix = useMemo(() => {
        let _suffix = props.suffix
        if (!_suffix && props.surround) _suffix = props.surround.split('')?.[1]
        if (props.pluralizeSuffix && typeof _suffix === 'string') {
            return Utils.pluralize(_suffix, props.num as number)
        }
        return _suffix
    }, [props.suffix, props.pluralizeSuffix, props.num, props.surround])

    const hasNoValue = useMemo(
        () => props.num === 0 || props.num === '0' || props.num === null || props.num === undefined,
        [props.num],
    )

    const precision = useMemo(
        () => context.stores.ui?.decimalPrecision || props.precision || 2,
        [props.precision, context.stores.ui?.decimalPrecision],
    )

    const showZeroesToNumber = useMemo(() => {
        if (context.stores.ui?.decimalPrecision) return true
        return props.showZeroesToNumber !== false
    }, [props.showZeroesToNumber, context.stores.ui?.decimalPrecision])

    const _numString = useMemo(
        () =>
            Utils.toFixedFloat(
                props.num,
                precision,
                props.showZeroesToPrecision === undefined ? precision !== 2 : props.showZeroesToPrecision,
                undefined,
                showZeroesToNumber,
                props.friendlyNumbers,
            ),
        [props.num, precision, props.showZeroesToPrecision, showZeroesToNumber, props.friendlyNumbers],
    )
    const _decimalSeparator = useMemo(
        () => Utils.getPreferredDecimal(),
        [context.stores.user?.uiPreferences?.numberLocale],
    )

    const noValue = useMemo(() => {
        if (!hasNoValue) return null
        if (props.zeroValue) return <>{props.zeroValue}</>
        if (!props.onZero?.includes('dash')) return null
        const _dash = props.onZero === 'dash' ? '--.--' : '–'
        return (
            <span className={[props.className, 'text-very-muted not-clickable', props.extraClassName].join(' ')}>
                {_dash}
            </span>
        )
    }, [hasNoValue, props.zeroValue, props.onZero, props.className, props.extraClassName])

    const undefinedValue = useMemo(() => {
        if (props.num !== undefined) return null
        if (props.onUndefined === 'spinner') return <span className='spinner-border spinner-border-sm' />
        if (props.onUndefined === 'empty') return null
        if (!props.onUndefined?.includes('dash')) return null
        const _dash = props.onUndefined === 'dash' ? '--.--' : '–'
        return (
            <span className={[props.className, 'text-very-muted not-clickable', props.extraClassName].join(' ')}>
                {_dash}
            </span>
        )
    }, [props.num, props.onUndefined, props.className, props.extraClassName])

    const largeNumber = useMemo(() => {
        if (!props.friendlyLargeNumbers || !props.num) return null
        const _num = parseInt(props.num as string, 10)
        let _largeString: ReactNode = null
        friendlyLargeNumbers.forEach((fn) => {
            if (!_largeString && _num >= fn.value) {
                _largeString = (
                    <span
                        className={[props.className, props.extraClassName].join(' ')}
                        title={`${Utils.toFixedFloat(props.num, Utils.MAX_DECIMAL_PRECISION)}`}
                    >
                        {prefix}
                        {fn.name}
                        {suffix}
                    </span>
                )
            }
        })
        return _largeString
    }, [props.friendlyLargeNumbers, props.num, prefix, suffix, props.className, props.extraClassName])

    const prettyStr = useMemo(() => {
        if (!_numString) return null
        const priceParts = _numString.split(/(\d+)/)
        let isLastPart = false
        return priceParts.map((part, idx) => {
            const key = `prettyNumber-${idx}`
            if (part === _decimalSeparator) isLastPart = true
            const _num = parseInt(part, 10)
            if (!isNaN(_num)) {
                return (
                    <span key={key} className={isLastPart ? props.decimalClassName : ''}>
                        {part}
                    </span>
                )
            }
            return <span key={key}>{part}</span>
        })
    }, [_numString, props.decimalClassName, _decimalSeparator])

    if (props.hidden) return null

    return (
        undefinedValue ||
        noValue ||
        largeNumber || (
            <span
                aria-label={props.ariaLabel}
                className={[props.className || 'font-monospace', props.extraClassName].join(' ')}
                style={props.style}
                title={Utils.toFixedFloat(props.num, Utils.MAX_DECIMAL_PRECISION)}
            >
                {prefix}
                {prettyStr}
                {suffix}
            </span>
        )
    )
}
