import { HttpMethod } from './http'
import { Amount, ListResponse, UsedIn, VariableBaseNode } from '../types'
import { Factor, Product } from './product'
import { SharingToken } from './token'
import { UserWithoutID } from './user-context'
import { ActivityItem } from './activity'
import Utils from './utils'
import { Unit } from './unit'
import { Company } from './company'
import { ActionMap } from '../context'
import { InventoryActionType, InventoryService } from './inventory'
import VariableService from './service'

export enum PartType {
    UNKNOWN = 'unknown',
    SWITCH = 'marketplace',
    MIX = 'pool',
    CONFIG = 'config',
}

export const partTypeOptions = [
    {
        name: 'Switch',
        value: PartType.SWITCH,
        description: 'Switch between multiple sources',
    },
    {
        name: 'Mix',
        value: PartType.MIX,
        description: 'Aggregate multiple sources into one value',
    },
]

export type PartTypeAbbreviation = 'F' | 'S' | 'M' | 'C'

export interface PartRelationships {
    token?: SharingToken
    partOf?: Product[]
    sourceProduct?: Product
    sourceProducts?: Product[]
    suppliers?: Company[]
    emissionFactor?: Factor
    activities?: ActivityItem[]
    unit?: Unit
    weight?: Amount
}

export interface Part extends VariableBaseNode, PartRelationships {
    name: string
    type?: PartType
    imagePath?: string
    liveCo2e?: string | null
    factorCo2e?: string | null
    totalCo2e?: number
    usedIn?: UsedIn[]
    partOfCount?: number
    activityCount?: number
    sourceProductCount?: number
}

export interface PartAndSourceProduct {
    part?: Part
    sourceProduct?: Product
}

export interface PartContext {
    connectId?: string
    selectedId?: string
    inputId?: string
    updates: number
}

export enum PartActionType {
    SelectPartById = 'SelectPartById',
    DeselectPart = 'DeselectPart',
    SetInputId = 'SetPartInput',
    ShowConnect = 'ShowConnect',
}

type PartActionPayload = {
    [PartActionType.SelectPartById]: string | undefined
    [PartActionType.DeselectPart]: undefined
    [PartActionType.SetInputId]: string
    [PartActionType.ShowConnect]: string | undefined
}

export type PartActions = ActionMap<PartActionPayload>[keyof ActionMap<PartActionPayload>]

export const PartReducer = (state: PartContext, action: PartActions): PartContext => {
    switch (action.type) {
        case PartActionType.SelectPartById:
            return { ...state, selectedId: action.payload, updates: 0 }
        case PartActionType.DeselectPart:
            return { ...state, selectedId: undefined, updates: 0 }
        case PartActionType.SetInputId:
            return { ...state, inputId: action.payload, updates: 0 }
        case PartActionType.ShowConnect:
            return { ...state, connectId: action.payload }
        default:
            return state
    }
}

export default class PartService extends VariableService {
    private basePath: string = '/part'
    public static webRoot: string = '/block'
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Block', plural ? 2 : 1)
    public static switchTitle = (plural: boolean = false): string => Utils.pluralize('Switch', plural ? 2 : 1)
    public static mixTitle = (plural: boolean = false): string => Utils.pluralize('Mix', plural ? 2 : 1)

    public static getPartTypeString(partType?: PartType): string {
        switch (partType) {
            case PartType.CONFIG:
                return 'Config'
            case PartType.SWITCH:
                return 'Switch'
            case PartType.MIX:
                return 'Mix'
            default:
                return 'Factor'
        }
    }

    public static getPartString(part?: Part): string {
        if (!part?.sourceProductCount && part?.type !== PartType.CONFIG) {
            return this.getPartTypeString(PartType.UNKNOWN)
        }
        return this.getPartTypeString(part?.type)
    }

    public static getPartUrl(part?: Part, fullUrl: boolean = false): string {
        let url = `${fullUrl ? document.location.origin : ''}${this.webRoot}`
        if (!part?.uuid) {
            return url
        }
        return `${url}/${part?.uuid}`
    }

    public openPartEditor(part?: Part, inputId?: string) {
        if (inputId) this.context.dispatch({ type: PartActionType.SetInputId, payload: inputId })
        this.context.dispatch({ type: PartActionType.SelectPartById, payload: part?.uuid })
    }

    public showConnectModal(part?: Part) {
        this.context.dispatch({ type: PartActionType.ShowConnect, payload: part?.uuid })
    }

    public getSlimParts() {
        const qs = new URLSearchParams()
        // 'return=uuid,name,type,liveCo2e,totalCo2e,usedIn,partOfCount,sourceProductCount,createdBy,updatedBy&limit=-1'
        qs.set(
            'return',
            [
                'uuid',
                'name',
                'unit',
                'type',
                'liveCo2e',
                'totalCo2e',
                'usedIn',
                'partOfCount',
                'sourceProductCount',
                'createdBy',
                'updatedBy',
            ].join(','),
        )
        qs.set('limit', '-1')
        this.getParts(qs.toString()).then((plr) => this.updateContext(plr.data))
    }

    private updateContext(parts?: Part[]) {
        if (!parts?.length) return
        InventoryService.mergeInventory(parts.map(InventoryService.partToInventory))
        this.context.dispatch({ type: InventoryActionType.SetInventoryUpdated })
    }

    private updateInventory(products?: Product[]) {
        if (!products?.length) return
        InventoryService.mergeInventory(products.map(InventoryService.productToInventory))
        this.context.dispatch({ type: InventoryActionType.SetInventoryUpdated })
    }

    public async getParts(queryString?: string): Promise<ListResponse<Part>> {
        return this.httpService.get<ListResponse<Part>>(`/part?${queryString}`).then((plr) => {
            this.updateContext(plr.data)
            return plr
        })
    }

    public async getPart(partId: string, queryString?: string): Promise<Part> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<Part>(`${this.basePath}/${partId}?${qs.toString()}`).then((p) => {
            this.updateContext([p])
            return p
        })
    }

    public async getAllSources(): Promise<Product[]> {
        return this.httpService.get<Product[]>(`${this.basePath}/source`).then((products) => {
            this.updateInventory(products)
            return products
        })
    }

    public async fetchInventory() {
        await Promise.all([await this.getAllSources(), await this.getParts('limit=-1')])
    }

    public updateSources(footprint: Product) {
        if (!footprint?.uuid) return
        this.httpService
            .put<void>(`${this.basePath}/source`, { body: JSON.stringify({ footprintId: footprint?.uuid }) })
            .then(() => this.updateInventory([footprint]))
    }

    public removeSource(footprintId: string) {
        this.httpService.delete<void>(`${this.basePath}/source/${footprintId}`).then(() => {
            InventoryService.removeById(footprintId)
            this.context.dispatch({ type: InventoryActionType.SetInventoryUpdated })
        })
    }

    public async createOrUpdatePart(part: Part): Promise<Part> {
        let url = this.basePath
        let method: HttpMethod = 'post'
        if (part?.uuid) {
            url += `/${part.uuid}`
            method = 'put'
        }
        return this.httpService.fetch<Part>(method, url, { body: JSON.stringify({ part: part }) }).then((p) => {
            this.updateContext([p])
            return p
        })
    }

    public async deletePart(part: Part): Promise<Part> {
        return this.httpService.delete<Part>(`${this.basePath}/${part.uuid}`).then((p) => {
            if (part.uuid) {
                InventoryService.removeById(part.uuid)
                this.context.dispatch({ type: InventoryActionType.SetInventoryUpdated })
            }
            return p
        })
    }

    public async getSourceProducts(part: Part): Promise<Product[]> {
        return this.httpService
            .get<Product[]>(`${this.basePath}/${part.uuid}/source`, { ttlInSeconds: 0 })
            .then((sourceProducts) => {
                this.updateContext([{ ...part, sourceProducts }])
                this.updateInventory(sourceProducts)
                return sourceProducts
            })
    }

    public async addSourceProduct(part: Part, sourceProduct: Product): Promise<Product[]> {
        return this.httpService
            .put<Product[]>(`${this.basePath}/${part.uuid}`, {
                body: JSON.stringify({ sourceProductId: sourceProduct.uuid }),
            })
            .then((sourceProducts) => {
                this.updateContext([{ ...part, sourceProducts }])
                this.updateInventory(sourceProducts)
                return sourceProducts
            })
    }

    public async getPartsBySourceProduct(sourceProduct: Product): Promise<Part[]> {
        return this.httpService.get<Part[]>(`${this.basePath}?sourceProduct=${sourceProduct.uuid}`)
    }

    public async updateSourceProducts(part: Part, sourceProducts: Product[]): Promise<Part> {
        return this.httpService
            .put<Part>(`${this.basePath}/${part.uuid}`, { body: JSON.stringify({ sourceProducts }) })
            .then((p) => {
                this.updateContext([p])
                this.updateInventory(p.sourceProducts)
                return p
            })
    }

    public async removeSourceProduct(part: Part, sourceProduct: Product): Promise<Product[]> {
        return this.httpService
            .fetch<Product[]>('delete', `${this.basePath}/${part.uuid}/source/${sourceProduct.uuid}`)
            .then((sourceProducts) => {
                this.updateContext([{ ...part, sourceProducts }])
                this.updateInventory(sourceProducts)
                return sourceProducts
            })
    }

    public sharePart(part: Part): Promise<SharingToken> {
        return this.httpService.put<SharingToken>(`${this.basePath}/${part.uuid}`, {
            body: JSON.stringify({ share: part.uuid }),
        })
    }

    public connectVia(user: UserWithoutID, part: Part, customMessage: string): Promise<Part> {
        return this.httpService.post<Part>(this.basePath, {
            body: JSON.stringify({
                part: part,
                connectVia: user,
                customMessage: customMessage,
            }),
        })
    }
}
