import { Part, PartType } from '../../services/part'
import { IconProps, Nut, Stack, StackSimple } from '@phosphor-icons/react'

export const PartIcon = (
    props: IconProps & {
        part?: Part
        isMix?: boolean
        isSwitch?: boolean
        isConfig?: boolean
    },
) => {
    if (props.isMix || props.part?.type === PartType.MIX) return <Stack {...props} />
    if (props.isConfig || props.part?.type === PartType.CONFIG) return <Nut {...props} />
    return <StackSimple {...props} />
}
