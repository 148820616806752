import { Product } from '../../services/product'
import CO2e from '../CO2e'
import Utils from '../../services/utils'
import { useContext, useMemo } from 'react'
import InputService from '../../services/input'
import { ApplicationContext } from '../../context'
import { UnitHeader } from '../UnitHeader'
import { KeyValuePair } from '../../types'

export const ProductCO2eDebug = (props: { product?: Product }) => {
    useContext(ApplicationContext)
    if (!Utils.inDebugMode() || !props.product) return null

    const summaries: KeyValuePair<string>[] = useMemo(
        () => [
            { name: 'Total', value: 'totalCarbonFootprint' },
            { name: 'Upstream', value: 'upstream' },
            { name: 'Direct', value: 'direct' },
            { name: 'Downstream', value: 'downstream' },
            { name: 'PCF', value: 'productCarbonFootprint' },
        ],
        [],
    )
    return (
        <div className='d-grid small text-very-muted mt-1' style={{ gridTemplate: 'auto / [name] auto [co2e] auto' }}>
            <span />
            <UnitHeader unitSize='small' className='text-end' />
            {summaries.map((summary) => {
                const co2e = props.product?.footprint?.CO2e?.[summary.value]
                if (co2e === undefined) return null
                return (
                    <span
                        key={`lca-value-${summary.value}`}
                        className='d-grid'
                        style={{ gridColumn: 'name / span 2', gridTemplateColumns: 'subgrid' }}
                    >
                        {summary.name}:
                        <CO2e co2e={co2e} className='text-end' showUnit={false} />
                    </span>
                )
            })}
            <hr className='m-0' style={{ gridColumn: 'name / span 2' }} />
            Embodied/Upstream:
            <CO2e co2e={props.product.upstreamCo2e || props.product.co2e} className='text-end' showUnit={false} />
            <hr className='m-0' style={{ gridColumn: 'name / span 2' }} />
            {InputService.lcaCodes.map((lcaCode) => {
                const co2e = props.product?.footprint?.CO2e?.[lcaCode]
                if (co2e === undefined) return null
                return (
                    <span
                        key={`lca-value-${lcaCode}`}
                        className='d-grid'
                        style={{ gridColumn: 'name / span 2', gridTemplateColumns: 'subgrid' }}
                    >
                        {lcaCode}:
                        <CO2e co2e={co2e} className='text-end' showUnit={false} />
                    </span>
                )
            })}
            <span>Premium</span>
            <span className='text-end'>{props.product?.premium ? 'Yes' : 'No'}</span>
            <span>Has CO2e</span>
            <span className='text-end'>{props.product?.hasCo2e ? 'Yes' : 'No'}</span>
        </div>
    )
}
