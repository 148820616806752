import { Handle, NodeProps, Position } from 'reactflow'
import { Img } from '../Img'
import PartService, { PartActionType } from '../../services/part'
import { FlowService, INPUT_NODE_WIDTH } from '../../services/flow'
import { Database } from '@phosphor-icons/react'
import Utils from '../../services/utils'
import CO2e from '../CO2e'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import Button from '../Input/Button'
import { PrettyNumber } from '../PrettyNumber'
import { PartIcon } from '../Icons/PartIcon'
import { usePart } from '../../hooks/usePart'
import { useInput } from '../../hooks/useInput'
import ProductService from '../../services/product'

export const PartNode = ({ id, zIndex, data }: NodeProps) => {
    const context = useContext(ApplicationContext)

    const part = usePart({ part: data.part })
    const partInput = useInput({ input: data.input })

    const icon = useMemo(() => {
        if (part) return <PartIcon part={part} />
        return <Database />
    }, [part])

    const hlClassName = useMemo(() => {
        if (context.stores.parts.selectedId === part?.uuid) {
            return FlowService.nodeHighlightClass
        }
        return ''
    }, [context.stores.parts.selectedId, part?.uuid])

    return (
        <Button
            className={[
                'position-relative hover-parent rounded-2 p-2 bg-white bg-light-hover border border-primary text-start',
                hlClassName,
            ].join(' ')}
            style={{ width: `${INPUT_NODE_WIDTH}px`, zIndex: zIndex }}
            onClick={() => {
                if (partInput?.uuid) context.dispatch({ type: PartActionType.SetInputId, payload: partInput.uuid })
                context.dispatch({ type: PartActionType.SelectPartById, payload: part?.uuid })
            }}
        >
            <div className='d-flex align-items-center gap-2 small overflow-hidden h-100'>
                <span className='flex-shrink-0'>
                    <Img src={part?.imagePath} className='rounded-2' size='40px' placeholderIcon={icon} />
                </span>
                <span className='overflow-hidden'>
                    <span className='element-badge w-auto px-1 me-1'>{PartService.getPartString(part)}</span>
                    <span>
                        <PartIcon part={part} color={Utils.fadedColor} />{' '}
                        <PrettyNumber num={part?.sourceProducts?.length} />
                    </span>
                    <div className='text-overflow-ellipsis' title={part?.name}>
                        {partInput?.sourceProduct?.name || part?.name}
                    </div>
                </span>
                <CO2e
                    part={part}
                    co2eDisplay='Embodied'
                    isPremium={ProductService.isPremium(partInput?.sourceProduct)}
                    functionalUnit={part?.unit?.code}
                    className='text-end ms-auto flex-shrink-0'
                    unitsClassName='d-block text-muted small'
                />
            </div>
            <Handle type='target' position={Position.Left} id={id} />
            <Handle type='source' position={Position.Right} id={id} />
        </Button>
    )
}
