import Utils from '../../services/utils'
import Button from './Button'
import { SlideIn } from '../SlideIn'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { AmountInput } from './Amount'
import { UnitType } from '../../services/unit'
import CO2e from '../CO2e'
import { ApplicationContext } from '../../context'
import ProductService, { Product } from '../../services/product'
import Delete from '../Delete'
import { VariableServicesContext } from '../../services'
import { Selector } from './Selector'
import { MiniPassport } from '../MiniPassport'
import InputField from './InputField'
import { AddSourceProduct } from '../Part/AddSourceProduct'
import { Footnotes } from '../Footnotes'
import ProcessingService, { ProcessingType, ProcessingTypeMethod } from '../../services/processing'
import FlagService, { FlagType } from '../../services/flag'
import { ElectricitySelector } from './ElectricitySelector'
import { ByproductItem } from './ByproductItem'
import TaxonomyService from '../../services/taxonomy'
import { useProcessingType } from '../../hooks/useProcessingType'
import { ND } from '../../types'
import { useProduct } from '../../hooks/useProduct'
import { useSave } from '../../hooks/useSave'

export const ProcessingEditor = () => {
    const context = useContext(ApplicationContext)
    const { processingService, inputService, partService } = useContext(VariableServicesContext)
    const nameRef = useRef<any>()
    const inputRef = useRef<any>()

    const processingType = useProcessingType({ processingTypeId: context.stores.processingType.processingId })
    const footprint = useProduct({ productId: processingType?.footprint?.uuid })

    useEffect(() => {
        return () => processingService.clearProcessingType()
    }, [])

    useEffect(() => {
        if (!context.stores.processingType?.byproductId) {
            setTimeout(() => {
                if (!processingType?.name || context.stores.processingType?.focus === 'name') {
                    nameRef.current?.focus()
                } else {
                    inputRef.current?.focus()
                }
            }, 301)
        }
    }, [context.stores.processingType.processingId])

    const nodeId = useMemo(() => context.stores.processingType?.node?.uuid, [context.stores.processingType?.node?.uuid])

    const partialSaveFn = useCallback(
        async (properties?: Partial<ProcessingType>) => {
            if (!processingType || !nodeId) return
            return processingService.updateInputProcessing({ uuid: processingType.uuid, ...properties }, nodeId)
        },
        [processingType, nodeId],
    )

    const { pSave, saving } = useSave({ node: processingType, partialSaveFn })

    const methodContent = useMemo(() => {
        if (!processingType) return null
        if (processingType.method === ProcessingTypeMethod.FOOTPRINT) {
            return (
                <div className='d-flex flex-column gap-2'>
                    {footprint && (
                        <div className='d-flex align-items-center justify-content-between gap-2 hover-parent-direct'>
                            <MiniPassport product={footprint as Product} />
                            <Delete iconOnly={true} deleteFn={() => pSave({ footprint: null }).then()} />
                        </div>
                    )}
                    {!footprint && (
                        <AddSourceProduct
                            placeholder={`Set ${ProductService.elementTitle()}`}
                            btnClassName='btn btn-sm btn-outline-primary w-100'
                            btnHoverClassName=' '
                            btnActiveClassName='btn-primary'
                            targetType={ND.ProcessingType}
                            queryOptions={{
                                unitType: UnitType.ENERGY,
                                taxonomy: TaxonomyService.getByPath('energy')?.uuid,
                            }}
                            node={processingType}
                            onDone={(footprint) => pSave({ footprint })}
                        />
                    )}
                </div>
            )
        }
        if (processingType.method === ProcessingTypeMethod.GEOLOCATION) {
            return (
                <div className='row align-items-center py-1'>
                    <div className='col-4 small text-nowrap'>Location</div>
                    <div className='col-8'>
                        <ElectricitySelector
                            geoLocation={processingType.geoLocation}
                            location={processingType.location}
                            electricity={footprint}
                            showGridSelector={true}
                            onChange={(geoLocation, location, footprints) => {
                                pSave({
                                    geoLocation,
                                    location,
                                    footprint: footprints?.[0],
                                }).then(() => partService.getAllSources())
                            }}
                        />
                    </div>
                </div>
            )
        }
        return null
    }, [processingType?.method, footprint, processingType?.geoLocation, processingType?.location])

    if (!processingType) return null

    return (
        <SlideIn
            ariaLabel='Processing Editor'
            show={true}
            onVisibilityChange={(v) => !v && processingService.clearProcessingType()}
            useBackdrop={true}
            header={
                <InputField
                    passedRef={nameRef}
                    placeholder='Name'
                    className='variable-form-control bg-light fs-6 w-100'
                    defaultValue={processingType?.name || context.stores.processingType.node?.name}
                    onChanged={(name) => pSave({ name })}
                />
            }
        >
            <div className='d-flex flex-column gap-3 flex-grow-1'>
                <div className='row align-items-center'>
                    <div className='col-4 small text-nowrap'>Energy</div>
                    <div className='col-8'>
                        <AmountInput
                            amount={processingType?.energy}
                            inputFieldProps={{ passedRef: inputRef, focusOnRender: true }}
                            unitSelectorProps={{ unitType: footprint?.unit?.type || UnitType.ENERGY }}
                            onChange={(energy) => pSave({ energy })}
                        />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col-4 small text-nowrap'>Method</div>
                    <div className='col-8'>
                        <Selector
                            className='variable-form-select small w-auto'
                            placement='bottom-start'
                            hideTextFilter={true}
                            options={ProcessingService.processingTypeMethods}
                            option={processingType.method}
                            onSelect={(method) => pSave({ method: method.value })}
                        />
                    </div>
                </div>
                <div className='align-items-center'>{methodContent}</div>
                <div className='small border-top pt-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span>Footprint</span>
                        <CO2e co2e={processingType.co2e} />
                    </div>
                </div>
                <div className='d-flex flex-column'>
                    {processingType?.byproducts?.sort(Utils.sortByCreated)?.map((bp) => (
                        <ByproductItem
                            key={`bp-${bp.uuid}`}
                            extraClassName='border-top py-3'
                            processingType={processingType}
                            byproduct={bp}
                            onChange={(_mf) =>
                                pSave({
                                    byproducts: processingType?.byproducts?.map((mf) =>
                                        mf.uuid === _mf.uuid ? _mf : mf,
                                    ),
                                })
                            }
                            onRemove={(_mf) =>
                                pSave({
                                    byproducts: processingType?.byproducts?.filter((mf) => mf.uuid !== _mf.uuid),
                                })
                            }
                        />
                    ))}
                    {FlagService.enabledFlags.has(FlagType.UseByproducts) && (
                        <Button
                            className='d-block w-100 btn btn-sm btn-outline-primary'
                            onClick={() => processingService.addByproduct(processingType)}
                        >
                            Add output
                        </Button>
                    )}
                </div>
                <div className='fs-base text-center mt-auto'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Footnotes node={processingType} saving={saving} />
                        <div hidden={!processingType.forInput?.uuid} className='small'>
                            <Delete
                                deleteFn={() =>
                                    inputService
                                        .removeInput(processingType.node?.uuid)
                                        .then(() => processingService.clearProcessingType())
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SlideIn>
    )
}
