import { useContext, useMemo } from 'react'
import GHGService, { GHGScope } from '../../services/ghg'
import { Selector, SelectorProps } from './Selector'
import { ApplicationContext } from '../../context'

export type ScopeSelectorProps = SelectorProps & {
    scope?: GHGScope | null
    onSelect: (scope: GHGScope) => void
}

export const ScopeSelector = (props: ScopeSelectorProps) => {
    const context = useContext(ApplicationContext)
    const label = useMemo(() => {
        if (props.label) {
            return props.label
        }
        if (props.scope?.uuid) {
            return GHGService.fullScopeName(props.scope)
        }
        return undefined
    }, [props.label, props.scope, props.noValueOption])

    const list = useMemo(() => [...GHGService.list], [context.stores.ui?.ghgReady])

    return (
        <Selector
            {...props}
            placeholder={props.placeholder || 'Scope'}
            options={list}
            option={props.scope === null ? null : props.scope?.uuid}
            label={label}
            itemClassName='dropdown-item text-wrap px-2 text-start text-base-font'
            renderItem={(o, item, id) => {
                let className = ''
                if (o.uuid === 'null') {
                    className = 'border-top'
                } else if (!o.parent) {
                    className = 'bg-light text-very-muted small'
                }
                return (
                    <div key={id} className={className}>
                        {item}
                    </div>
                )
            }}
        />
    )
}
