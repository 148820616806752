import { QueryOptions, VariableBaseNode } from '../types'
import VariableService from './service'
import { ActionMap } from '../context'
import { Inventory } from './inventory'
import Utils from './utils'
import ProductService, { ProductFootprintType, ProductVisibility } from './product'
import TaxonomyService from './taxonomy'
import DataSourceService from './dataSource'

export interface Filter extends VariableBaseNode {
    queryOptions?: QueryOptions
}

export interface IFilterContext {
    list: Filter[]
    showManager?: boolean
}

export enum FilterActionType {
    Set = 'SetFilters',
    ShowManager = 'ShowFilterManager',
}

type FilterActionPayload = {
    [FilterActionType.Set]: Filter[]
    [FilterActionType.ShowManager]: boolean
}

export type FilterActions = ActionMap<FilterActionPayload>[keyof ActionMap<FilterActionPayload>]

export const FilterReducer = (state: IFilterContext, action: FilterActions): IFilterContext => {
    switch (action.type) {
        case FilterActionType.Set:
            return { ...state, list: action.payload }
        case FilterActionType.ShowManager:
            return { ...state, showManager: action.payload }
        default:
            return state
    }
}

export default class FilterService extends VariableService {
    private basePath: string = '/filter'

    public filterItem(inv: Inventory, queryOptions?: QueryOptions): boolean {
        if (queryOptions?.inventoryTypes) {
            if (!queryOptions?.inventoryTypes.includes(inv.type)) {
                return false
            }
        }
        if (Utils.hasValue(queryOptions?.productOf)) {
            if (inv.productOf?.uuid !== queryOptions?.productOf) {
                return false
            }
        }
        if (queryOptions?.archived && inv.originalProduct?.visibility !== ProductVisibility.ARCHIVED) {
            return false
        }
        if (!queryOptions?.archived && inv.originalProduct?.visibility === ProductVisibility.ARCHIVED) {
            return false
        }
        if (Utils.hasValue(queryOptions?.elementType)) {
            let _type = inv.typeString.toLowerCase()
            if (inv.originalPart?.type) {
                _type = inv.originalPart?.type
            } else if (inv.originalProduct?.type !== undefined) {
                _type = inv.originalProduct?.type.toString()
            }

            // if the item is an emission factor from our global database
            if (ProductService.isEmissionFactor(inv.originalProduct, DataSourceService.publicListIds)) {
                if (_type === ProductFootprintType.STATIC.toString()) {
                    // we're filtering for "Imported"
                    _type = ProductFootprintType.FACTOR.toString()
                } else if (_type === ProductFootprintType.FACTOR.toString()) {
                    // we're filtering for "Emission Factors"
                    _type = ProductFootprintType.STATIC.toString()
                }
            }

            // console.log(queryOptions?.elementType?.toString(), _type)
            if (queryOptions?.elementType === 'unknown') {
                if (inv.typeString?.toLowerCase() !== 'unknown') {
                    return false
                }
            } else if (_type !== queryOptions?.elementType?.toString()) {
                return false
            }
        }
        if (Utils.hasValue(queryOptions?.taxonomy)) {
            const _qoTaxonomy = TaxonomyService.getById(queryOptions?.taxonomy)
            if (inv.taxonomy?.uuid !== _qoTaxonomy?.uuid) {
                if (queryOptions?.depth === '0') {
                    return false
                }
                if (!TaxonomyService.isInTaxonomyTree(_qoTaxonomy, inv.taxonomy)) {
                    return false
                }
            }
        }
        if (Utils.hasValue(queryOptions?.source)) {
            if (
                inv.productOf?.uuid?.toString() !== queryOptions?.source &&
                inv.dataSources?.[0]?.uuid !== queryOptions?.source
            ) {
                return false
            }
        }
        if (Utils.hasValue(queryOptions?.unitType)) {
            if (inv.unit?.type !== queryOptions?.unitType) {
                return false
            }
        }
        if (Utils.hasValue(queryOptions?.createdBy)) {
            const _createdBy = inv.originalProduct?.createdBy || inv.originalPart?.createdBy
            if (_createdBy?.uuid !== queryOptions?.createdBy) {
                return false
            }
        }
        if (Utils.hasValue(queryOptions?.method)) {
            if (inv.originalProduct?.method !== queryOptions?.method) {
                return false
            }
        }
        return true
    }

    public async getFilters(queryString: string = ''): Promise<Filter[]> {
        if (!this.context.stores.user?.uuid) return []
        return this.httpService.get<Filter[]>(`${this.basePath}?${queryString}`).then((filters) => {
            if (!queryString) {
                this.context.dispatch({ type: FilterActionType.Set, payload: filters })
            }
            return filters
        })
    }

    public async saveFilter(filter: Filter, queryString: string = ''): Promise<Filter> {
        filter.queryOptions = Utils.sortObjectByKeys(filter.queryOptions || {})
        return this.httpService
            .post<Filter>(`${this.basePath}?${queryString}`, { body: JSON.stringify({ filter: filter }) })
            .then((filter) => {
                this.getFilters()
                return filter
            })
    }

    public async deleteFilter(filter: Filter): Promise<void> {
        if (!filter.uuid) throw new Error('Filter does not have a uuid')
        await this.httpService.delete(`${this.basePath}/${filter.uuid}`)
        await this.getFilters()
    }
}
