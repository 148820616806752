import Button from './Input/Button'
import { Modal } from './Modal'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Utils from '../services/utils'
import CO2e from './CO2e'
import ProductService from '../services/product'
import { FootprintSearchView, NavItem, UIOptionActionType } from '../services/ui'
import { Link, useNavigate } from 'react-router-dom'
import PartService, { Part } from '../services/part'
import { Input } from '../services/input'
import { CO2eDisplay, ND, QueryOptions } from '../types'
import ActivityService, { ActivityItem, ActivityPerspective } from '../services/activity'
import { Img } from './Img'
import { VerifiedIcon } from './Icons/VerifiedIcon'
import { _newThings } from '../hooks/useKeyboard'
import { _navItems } from './Navigation'
import { Inventory, InventoryService } from '../services/inventory'
import { ArrowRight, Compass, Database } from '@phosphor-icons/react'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { PrettyNumber } from './PrettyNumber'
import { DataSource } from '../services/dataSource'
import { Filters, FilterType } from './Input/Filters'
import { TaxonomySidebar } from './TaxonomySidebar'
import { ProductSource } from './Product/ProductSource'
import { LifecycleBar } from './Product/LifecycleBar'
import { InventoryToggle } from './InventoryToggle'
import { InventoryIcon } from './Icons/InventoryIcon'
import TransportService from '../services/transport'
import { TransportIcons } from './Icons/TransportIcons'
import TaxonomyService from '../services/taxonomy'
import { PartIcon } from './Icons/PartIcon'
import { SortSelector } from './SortSelector'
import { UnitHeader } from './UnitHeader'
import { FileIcon } from './Input/FileIcon'
import { Header } from './Header'
import { SearchBox } from './Input/SearchBox'

const sections: { name: ReactNode; value: FootprintSearchView; url: string }[] = [
    {
        name: (
            <span className='d-flex align-items-center gap-1 flex-nowrap'>
                <Compass className='nt--2' />
                Navigate
            </span>
        ),
        value: 'global',
        url: '',
    },
    {
        name: (
            <span className='d-flex align-items-center gap-1 flex-nowrap'>
                <Database className='nt--2' />
                {ProductService.databaseTitle}
            </span>
        ),
        value: 'database',
        url: ProductService.webRootDatabase,
    },
    {
        name: (
            <span className='d-flex align-items-center gap-1 flex-nowrap'>
                <InventoryIcon allInventory={true} className='nt--2' />
                {InventoryService.webTitle()}
            </span>
        ),
        value: 'inventory',
        url: InventoryService.webRoot,
    },
]

let timer: NodeJS.Timeout

export const CommandPalette = () => {
    const context = useContext(ApplicationContext)
    const { uiService, productService, partService, filterService, activityService } =
        useContext(VariableServicesContext)
    const navigate = useNavigate()

    const [view, setView] = useState<FootprintSearchView>()

    const [searching, setSearching] = useState<boolean>(false)
    const [hasDbResults, setHasDbResults] = useState<boolean>(false)
    const [filterText, setFilterText] = useState<string | undefined>()
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
    const [footprints, setFootprints] = useState<Inventory[]>([])
    const [resultCount, setResultCount] = useState<number>()
    const [extraDataSourcesMap, setExtraDataSourcesMap] = useState<Map<string, DataSource>>(new Map())
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [activityPerspective, setActivityPerspective] = useState<ActivityPerspective>()
    const filterRef = useRef<HTMLInputElement>()
    const contentRef = useRef<any>()

    const fsc = useMemo(() => context.stores.ui?.footprintSearchConfig, [context.stores.ui?.footprintSearchConfig])

    const [queryOptions, setQueryOptions] = useState<QueryOptions | undefined>(fsc?.queryOptions)

    const filters: FilterType[] = useMemo(() => ['elementType', 'source', 'unitType', 'createdBy', 'taxonomy'], [])

    const _setQueryOptions = useCallback(
        (options?: Partial<QueryOptions>, exclusive: boolean = false) => {
            if (exclusive) {
                setQueryOptions(() => options)
            } else if (!options) {
                setQueryOptions(() => undefined)
            } else {
                setQueryOptions(() => Utils.mergeObjects(queryOptions, options))
            }
        },
        [queryOptions],
    )

    const inventoryTypes = useMemo(() => fsc?.inventoryTypes, [fsc?.inventoryTypes])

    const targetType = useMemo(() => fsc?.targetType, [fsc?.targetType])

    const targetNode = useMemo(() => fsc?.targetNode, [fsc?.targetNode])

    const taxonomy = useMemo(() => {
        const _tx = TaxonomyService.getById(queryOptions?.taxonomy)
        _setQueryOptions({ taxonomy: _tx?.uuid })
        return _tx || null
    }, [queryOptions?.taxonomy, context.stores.ui?.taxonomyReady])

    useEffect(() => {
        if (fsc?.queryOptions?.t && fsc?.queryOptions?.t !== filterText) setFilterText(fsc?.queryOptions.t)
    }, [fsc?.queryOptions?.t])

    const navInventory = useMemo(() => {
        const _navInv = _navItems
            .filter((ni) => !ni.hidden && ni.typeString !== 'header')
            .map(InventoryService.navItemToInventory)
        const subNav = _navItems
            .filter((i) => i.subNav)
            .flatMap((i) => i.subNav)
            .filter((ni) => !ni?.hidden)
        return _navInv
            .concat(subNav.map((ni, idx) => InventoryService.navItemToInventory(ni as NavItem, idx)))
            .sort(Utils.sortByName)
            .concat(_newThings.map((ni, idx) => InventoryService.navItemToInventory(ni, idx)))
    }, [])

    const elementList = useMemo(
        () => InventoryService.getList().sort(Utils.sortByName),
        [context.stores.inventory?.updated],
    )

    useEffect(() => setView(fsc?.view || 'global'), [fsc?.view])

    useEffect(() => {
        if (context.stores.company?.uuid && !InventoryService.list.length) partService.fetchInventory().then()
    }, [context.stores.company?.uuid])

    useEffect(() => {
        if (contentRef.current) {
            const container = contentRef.current as HTMLDivElement
            const elm = container.querySelector(`:scope #fp-${highlightedIndex}`) as HTMLAnchorElement
            elm?.scrollIntoView({ block: 'nearest' })
        }
    }, [highlightedIndex])

    useEffect(() => {
        if (contentRef.current) contentRef.current.scrollTop = 0
    }, [footprints])

    useEffect(() => {
        switch (view) {
            case 'global':
                _setQueryOptions({ taxonomy: undefined })
                _setFootprints(navInventory)
                break
            case 'inventory':
                setShowFilters(true)
                _setFootprints(elementList)
                break
            case 'database':
                setShowFilters(true)
                _setFootprints([])
                searchDatabase()
                break
        }
        filterRef.current?.focus()
    }, [view])

    useEffect(() => {
        switch (view) {
            case 'global':
                setShowFilters(!!filterText)
                _setFootprints(navInventory)
                setTimeout(() => searchDatabase(), 300)
                break
            case 'inventory':
                _setFootprints(elementList)
                break
            case 'database':
                searchDatabase()
                break
        }
        setHighlightedIndex(-1)
    }, [filterText, inventoryTypes, taxonomy, queryOptions])

    useEffect(() => {
        if (targetType === ND.Input) {
            if (targetNode?.name) {
                setFilterText(targetNode.name)
            }
        }
        if (targetType === ND.ActivityItem) {
            setActivityPerspective(activityService.getActivityPerspective(targetNode as ActivityItem))
        } else {
            setActivityPerspective(undefined)
        }
    }, [targetType, targetNode])

    useEffect(() => {
        _setQueryOptions(fsc?.queryOptions)
    }, [fsc?.queryOptions])

    const _filterForConfig = useCallback(
        (i: Inventory) => {
            if (!i?.uuid) {
                return false
            }
            if (i.dataSources) {
                setExtraDataSourcesMap((state) => {
                    i.dataSources?.forEach((ds) => state.set(ds.uuid!, ds))
                    return state
                })
            }
            if (i.productOf?.uuid) {
                setExtraDataSourcesMap((state) => {
                    state.set(i.productOf?.uuid!, i.productOf as DataSource)
                    return state
                })
            }
            if (!filterService.filterItem(i, queryOptions)) {
                return false
            }
            if (inventoryTypes && !inventoryTypes.includes(i.type)) {
                return false
            }
            if (targetType === ND.ActivityItem) {
                if (activityPerspective?.incoming && i?.productOf?.uuid === context.stores.company?.uuid) {
                    return false
                }
                if (activityPerspective?.outgoing && i?.productOf?.uuid !== context.stores.company?.uuid) {
                    return false
                }
            }
            if (filterText) {
                return InventoryService.inventoryMatch(filterText, i)
            }
            return true
        },
        [filterText, inventoryTypes, queryOptions, activityPerspective, targetType],
    )

    const _setFootprints = useCallback(
        (_footprints: Inventory[], _resultCount?: number) => {
            setExtraDataSourcesMap(new Map())
            let _filteredList = _footprints.filter(_filterForConfig)
            if (_filteredList.length !== _footprints.length) {
                setResultCount(_filteredList.length)
            } else {
                setResultCount(_resultCount || _filteredList.length)
            }
            if (view === 'inventory' && queryOptions?.orderBy) {
                _filteredList = _filteredList.sort((a, b) => {
                    if (queryOptions.orderBy === 'name') {
                        return a.name.localeCompare(b.name)
                    }
                    if (queryOptions.orderBy === 'co2e') {
                        return parseFloat(a.co2e || '0') - parseFloat(b.co2e || '0')
                    }
                    return 0
                })
                if (queryOptions.orderDir === 'DESC') {
                    _filteredList = _filteredList.reverse()
                }
            }
            setFootprints(_filteredList.slice(0, 1000))
        },
        [_filterForConfig, queryOptions, view],
    )

    const _clearFilter = useCallback(() => {
        setFilterText(undefined)
        setTimeout(() => filterRef.current && (filterRef.current.value = ''))
    }, [filterRef.current])

    const _onSelect = useCallback(
        (inv: Inventory) => {
            if (targetNode) {
                const _sourceFor = inv?.originalProduct?.usedIn?.filter((ui) => ui.type === ND.Part)
                let _part: Part | undefined = undefined
                if (_sourceFor?.[0]?.uuid) _part = InventoryService.getById(_sourceFor?.[0]?.uuid)?.originalPart
                context.dispatch({
                    type: UIOptionActionType.SelectNode,
                    payload: {
                        product: inv?.originalProduct,
                        part: inv?.originalPart || _part,
                        transportType: inv?.originalTransportType,
                    },
                })
            } else {
                clearTimeout(timer)
                navigate(inv.url)
                context.dispatch({ type: UIOptionActionType.HideFootprintSearch })
            }
            _clearFilter()
        },
        [_clearFilter, targetNode, fsc, context.stores.inventory.updated],
    )

    const searchDatabase = useCallback(() => {
        if (timer) clearTimeout(timer)
        if (!filterText && !taxonomy?.uuid && Object.keys(queryOptions || {}).length === 0) {
            setSearching(false)
            return
        }
        setSearching(true)
        timer = setTimeout(() => {
            const sqo = { ...queryOptions, limit: 200 }
            if (context.stores.products.productId) sqo.excludeIds = [context.stores.products.productId]
            productService
                .search({ searchTerm: filterText, queryOptions: sqo })
                .then((plr) => {
                    if (filterRef.current?.value && plr.query !== filterRef.current?.value) {
                        return
                    }
                    let results = plr.data.map(InventoryService.productToInventory)
                    if (view === 'global') {
                        results = navInventory.concat(results)
                    }
                    _setFootprints(results, plr.count)
                    setHasDbResults(true)
                })
                .finally(() => setSearching(false))
        }, 300)
    }, [filterText, queryOptions, navInventory, _setFootprints, filterRef.current, taxonomy?.uuid, view])

    const buttonCell = useCallback(
        (footprint: Inventory, isSelected: boolean) => {
            if (targetType) {
                const _className = 'btn btn-sm bg-primary-hover small py-0 px-1 shadow-none'
                if (isSelected) {
                    return <span className={`${_className} disabled opacity-50 border`}>Select</span>
                } else {
                    return <span className={`${_className} border`}>Select</span>
                }
            }
            return <InventoryToggle product={footprint.originalProduct} iconProps={{ size: Utils.verySmallIconSize }} />
        },
        [targetType],
    )

    const isSelected = useCallback(
        (footprint: Inventory) => {
            if (!targetNode) return false
            if (targetType === ND.Input) {
                return (targetNode as Input)?.sourceProduct?.uuid === footprint.uuid
            } else if (targetType === ND.ActivityItem) {
                return (targetNode as ActivityItem)?.product?.uuid === footprint.uuid
            } else if (targetType === ND.Part) {
                return (targetNode as Part)?.sourceProducts?.find((sp) => sp.uuid === footprint.uuid) !== undefined
            }
            return false
        },
        [targetType, targetNode],
    )

    const footprintResult = useCallback(
        (footprint: Inventory, idx: number) => {
            const _isSelected = isSelected(footprint)

            let name = (
                <span className={['d-flex align-items-center gap-1', _isSelected ? 'fw-bold' : ''].join(' ')}>
                    {footprint.labels?.includes(ND.EPD) && <span className='label-epd'>EPD</span>}
                    {ProductService.getProductName(footprint)}
                    {footprint.isVerified && <VerifiedIcon className='nt--1' />}
                    {footprint.isSolution && <span className='label-solution ms-1'>Solution</span>}
                    {footprint.quality === 'model' && <span className='label-model ms-1'>Model</span>}
                </span>
            )
            if (footprint.originalPart) {
                name = (
                    <>
                        {PartService.getPartString(footprint.originalPart)}: {footprint.name || ''}
                    </>
                )
            } else if (footprint.originalTransportType) {
                name = <>{TransportService.getTransportTypeName(footprint.originalTransportType)}</>
            }

            const sourceFor = footprint?.usedIn?.filter((ui) => ui.type === ND.Part)

            const co2eDisplay: CO2eDisplay = view === 'inventory' ? 'Upstream' : 'Total'

            return (
                <Link
                    key={`inv-${footprint?.uuid}`}
                    id={`fp-${footprint?.uuid || idx}`}
                    to={footprint.url}
                    onClick={(e) => {
                        if (Utils.isModifierKey(e)) return
                        e.preventDefault()
                        _onSelect(footprint)
                    }}
                    className={[
                        highlightedIndex === idx ? 'bg-light' : '',
                        'd-table-row table-body w-100 clickable hover-parent bg-light-hover',
                    ].join(' ')}
                >
                    <div className='d-table-cell align-middle text-start pe-0'>
                        {buttonCell(footprint, _isSelected)}
                    </div>
                    <div className='d-table-cell py-3 align-middle'>
                        <div className='d-flex align-items-start'>
                            <div
                                className='flex-shrink-0 d-flex align-items-center justify-content-center me-2'
                                style={{ width: '3rem' }}
                            >
                                <Img
                                    src={footprint.image}
                                    size='3rem'
                                    placeholderIcon={<InventoryIcon size={Utils.largeIconSize} inv={footprint} />}
                                />
                            </div>
                            <span>
                                {name}
                                {footprint.description && (
                                    <span
                                        className='d-block small text-muted overflow-hidden text-word-break-all'
                                        style={{ maxHeight: '2rem' }}
                                    >
                                        {footprint.description}
                                    </span>
                                )}
                                {footprint.originalTransportType && (
                                    <TransportIcons transportType={footprint.originalTransportType} />
                                )}
                                {productService.isPrimary(footprint) && (
                                    <span className='d-block small mt-1 text-muted'>Primary data</span>
                                )}
                                {footprint.originalPart?.uuid && !!footprint.originalPart.sourceProductCount && (
                                    <div className='text-muted'>
                                        {footprint.originalPart?.sourceProductCount}{' '}
                                        {Utils.pluralize('source', footprint.originalPart?.sourceProductCount)}
                                    </div>
                                )}
                                <ProductSource inv={footprint} />
                                {!!sourceFor?.length && (
                                    <span className='d-block small mt-1 text-muted'>
                                        {sourceFor?.map((sf) => (
                                            <span
                                                key={`inv-sf-${sf.uuid}`}
                                                className='d-inline-block bg-primary bg-opacity-5 px-1 py-0 rounded-1'
                                            >
                                                <PartIcon
                                                    size={Utils.verySmallIconSize}
                                                    part={{ uuid: sf.uuid, name: sf.name, type: sf.partType }}
                                                />{' '}
                                                {sf.name}
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className='d-table-cell py-3 text-end'>
                        <div className='d-flex flex-column align-items-end justify-content-center'>
                            <CO2e
                                co2e={footprint.co2e}
                                part={footprint.originalPart}
                                product={footprint.originalProduct}
                                co2eDisplay={co2eDisplay || 'Embodied'}
                                functionalUnitClassName='ms-1'
                                unitsClassName='d-block small text-muted'
                                functionalUnit={footprint.unit?.code}
                            />
                            <LifecycleBar
                                product={footprint.originalProduct}
                                co2eDisplay={co2eDisplay}
                                className='d-block'
                                style={{ width: '50px' }}
                            />
                            {!!footprint.originalProduct?.documents?.length && (
                                <FileIcon size={20} extraClassName='mt-1 text-muted' />
                            )}
                        </div>
                    </div>
                </Link>
            )
        },
        [highlightedIndex, targetType, targetNode, view, context.stores.inventory?.updated],
    )

    const tableFooter = useMemo(() => {
        const tableFooterClassName = 'w-100 btn btn-sm p-2 rounded-0 underline-on-hover text-start bg-opacity-10'
        if (!searching) {
            if (view === 'inventory') {
                return (
                    <Button onClick={() => setView('database')} className={`${tableFooterClassName} bg-secondary`}>
                        Don't see what you're looking for? Click here to <strong>search the global database</strong>.
                    </Button>
                )
            } else if (filterText && !footprints?.length && taxonomy?.uuid) {
                return (
                    <Button
                        onClick={() => _setQueryOptions({ taxonomy: undefined })}
                        className={`${tableFooterClassName} bg-secondary`}
                    >
                        You're searching within "{taxonomy?.name}".
                        <br />
                        <strong>Click here to search the entire database.</strong>
                    </Button>
                )
            } else if (hasDbResults) {
                return (
                    <Button
                        onClick={() => uiService.showRequestFootprintForm(true, filterText)}
                        className={`${tableFooterClassName} bg-info`}
                    >
                        Can't find what you're looking for. <strong>Request a Footprint.</strong>
                    </Button>
                )
            }
        }
        return null
    }, [view, searching, filterText, filters, hasDbResults, footprints?.length, taxonomy?.uuid])

    const content = useMemo(() => {
        if (view === 'global') {
            return (
                <>
                    <div className='d-table w-100 m-0 small' onMouseLeave={() => setHighlightedIndex(-1)}>
                        {footprints.map((footprint, idx) => {
                            if (footprint.typeString === 'nav' || footprint.typeString === 'create') {
                                return (
                                    <Link
                                        key={`inv-${footprint?.uuid}`}
                                        id={`fp-${footprint?.uuid || idx}`}
                                        to={footprint.url}
                                        onMouseEnter={() => setHighlightedIndex(idx)}
                                        onClick={() =>
                                            context.dispatch({ type: UIOptionActionType.HideFootprintSearch })
                                        }
                                        className={`d-table-row table-body hover-parent ${
                                            highlightedIndex === idx ? 'bg-light' : ''
                                        }`}
                                    >
                                        <span className='d-table-cell pe-0 text-center' style={{ width: '3rem' }}>
                                            <ArrowRight size={Utils.verySmallIconSize} className='me-1 nt--1' />
                                        </span>
                                        <span className='d-table-cell'>
                                            <span className='text-muted'>
                                                {footprint.typeString === 'nav' && 'Navigate to:'}
                                                {footprint.typeString === 'create' && 'Create:'}
                                            </span>{' '}
                                            {footprint.name}
                                        </span>
                                        <span className='d-table-cell text-end'>
                                            {footprint.keyboardShortcut && (
                                                <span className='d-flex align-items-center justify-content-end'>
                                                    <span className='keyboard-shortcut'>
                                                        {footprint.typeString === 'nav' && 'g'}
                                                        {footprint.typeString === 'create' && 'c'}
                                                    </span>
                                                    <span className='keyboard-shortcut'>
                                                        {footprint.keyboardShortcut}
                                                    </span>
                                                </span>
                                            )}
                                        </span>
                                    </Link>
                                )
                            }
                            return footprintResult(footprint, idx)
                        })}
                    </div>
                    <div hidden={!searching} className='p-2 ps-4 opacity-50 small'>
                        <span className='spinner-border spinner-border-sm me-1 nt-1' /> Searching...
                    </div>
                </>
            )
        }

        if (view === 'database' && !filterText && !taxonomy?.uuid && !Object.keys(queryOptions || {}).length) {
            return (
                <div className='bg-secondary bg-opacity-10 rounded-1 p-2 mt-1'>
                    <h6>Variable Global Database</h6>
                    <div>
                        The global database has footprints made by suppliers, emission factors from public and premium
                        databases, and category models made by our experts.
                    </div>
                </div>
            )
        }

        return (
            <>
                <div className='d-table w-100 m-0 small'>
                    <div className='d-table-row table-header'>
                        <div className='d-table-cell position-relative' style={{ width: '2.2rem' }}>
                            <span
                                hidden={!searching}
                                className='position-absolute spinner-border spinner-border-sm'
                                style={{ top: '.65rem', left: '.65rem' }}
                            />
                        </div>
                        <div className='d-table-cell'>
                            {ProductService.elementTitle(resultCount !== 1)}{' '}
                            {!!resultCount && <PrettyNumber num={resultCount} className=' ' surround='()' />}
                        </div>
                        <div className='d-table-cell text-end'>
                            <UnitHeader unitSize='small' extraClassName='fw-bold' />
                        </div>
                    </div>
                    {footprints.map(footprintResult)}
                </div>
                {tableFooter && (
                    <div className='table-footer'>
                        <div className='p-0'>{tableFooter}</div>
                    </div>
                )}
            </>
        )
    }, [
        searching,
        tableFooter,
        resultCount,
        footprints,
        filterText,
        highlightedIndex,
        view,
        taxonomy?.uuid,
        elementList,
    ])

    const defaultSearchText = useMemo(() => {
        if (targetType !== ND.Part && targetNode?.name) {
            return targetNode?.name
        }
        return filterText || ''
    }, [filterText, targetType, targetNode?.name])

    const header = useMemo(() => {
        if (fsc?.title) {
            return fsc?.title
        }
        if (!targetType || !targetNode) {
            return ''
        }
        let _header
        switch (targetType) {
            case ND.Part:
                _header = PartService.webTitle()
                break
            case ND.Product:
                _header = ProductService.elementTitle()
                break
            case ND.ActivityItem:
                _header = ActivityService.webTitle()
                break
            default:
                _header = targetType || ''
                break
        }
        if (targetNode?.name) {
            _header += `: ${targetNode?.name}`
        }
        return _header
    }, [targetType, targetNode?.name, fsc?.title])

    const sidebar = useMemo(() => {
        if (context.stores.ui?.footprintSearchConfig?.showSidebar === false) {
            return null
        }
        return (
            <div className='d-flex flex-column ps-3 py-3 flex-shrink-0' style={{ width: '14rem' }}>
                {sections
                    .filter((section) => {
                        if (targetType) return section.value !== 'global'
                        return true
                    })
                    .map((section) => (
                        <Link
                            key={`section-${section.value}`}
                            aria-label={`Command Palette: ${Utils.toTitleCase(section.value)} View`}
                            to={section.url}
                            className={[
                                view === section.value ? 'fw-bold' : '',
                                'btn btn-sm py-2 bg-primary-hover shadow-none d-block text-start text-height-1',
                            ].join(' ')}
                            onClick={(e) => {
                                if (Utils.isModifierKey(e)) return
                                e.preventDefault()
                                if (view === section.value) {
                                    _setQueryOptions({ taxonomy: undefined })
                                }
                                setView(section.value)
                            }}
                        >
                            {section.name}
                        </Link>
                    ))}
                <div className='overflow-auto flex-grow-1' style={{ height: '45vh' }}>
                    <div className='mt-3' hidden={view === 'global'}>
                        <Header size={6}>Filter</Header>
                        <TaxonomySidebar
                            currentTaxonomy={taxonomy}
                            onSelect={(selectedTaxonomy) => _setQueryOptions({ taxonomy: selectedTaxonomy?.uuid })}
                            itemProps={{
                                showProductCount: false,
                                className: [
                                    'd-flex align-items-center gap-2',
                                    'btn btn-sm bg-primary-hover text-start py-0 shadow-none',
                                ].join(' '),
                                onClick: (e, selectedTaxonomy) => {
                                    if (!Utils.isModifierKey(e)) {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        _setQueryOptions({ taxonomy: selectedTaxonomy?.uuid })
                                    }
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }, [sections, view, targetType, taxonomy, _setQueryOptions, context.stores.ui?.footprintSearchConfig?.showSidebar])

    return (
        <Modal
            ariaLabel='Command Palette'
            size='xl'
            hidden={!fsc?.visible}
            header={header}
            bodyClassName=' '
            content={
                <div className='d-flex'>
                    {sidebar}
                    <div className='flex-grow-1 d-flex flex-column gap-1'>
                        <div className='px-2 pt-3'>
                            <div className='position-relative'>
                                <SearchBox
                                    extraClassName='ps-2'
                                    searchIconProps={{ className: 'd-none' }}
                                    inputFieldProps={{
                                        passedRef: filterRef,
                                        ariaLabel: 'Command Palette: Filter',
                                        placeholder:
                                            view === 'inventory' ? 'Type to filter list' : 'Type a command or search',
                                        focusOnRender: true,
                                        defaultValue: defaultSearchText,
                                        onChange: (newValue) => {
                                            setFilterText(newValue)
                                            setHasDbResults(false)
                                        },
                                        onKeyPress: (e) => {
                                            if (e.key === 'ArrowDown') {
                                                e.preventDefault()
                                                setHighlightedIndex(
                                                    Math.min(highlightedIndex + 1, footprints.length - 1),
                                                )
                                            } else if (e.key === 'ArrowUp') {
                                                e.preventDefault()
                                                setHighlightedIndex(Math.max(highlightedIndex - 1, 0))
                                            } else if (e.key === 'Enter') {
                                                if (!footprints?.length && view === 'inventory') {
                                                    setView('database')
                                                } else {
                                                    _onSelect(footprints[Math.max(0, highlightedIndex)])
                                                }
                                            }
                                        },
                                        onEscapeKey: () => {
                                            if (!filterRef.current?.value) {
                                                context.dispatch({ type: UIOptionActionType.HideFootprintSearch })
                                            } else {
                                                _clearFilter()
                                            }
                                        },
                                    }}
                                    onClear={() => _clearFilter()}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1 mt-1 w-100'>
                                <Filters
                                    hidden={!showFilters}
                                    filters={filters}
                                    showDepthFilter={false}
                                    dataSourceSelectorProps={{
                                        showAllSources: !extraDataSourcesMap.size,
                                        showPublicSources: !extraDataSourcesMap.size,
                                        extraSources: Array.from(extraDataSourcesMap.values()),
                                    }}
                                    queryOptions={queryOptions}
                                    onChange={(newValue) => _setQueryOptions(newValue)}
                                    onSet={(newValue) => _setQueryOptions(newValue, true)}
                                />
                                <SortSelector
                                    hidden={!showFilters}
                                    queryOptions={queryOptions}
                                    className='btn btn-xs ms-auto'
                                    options={productService.getDatabaseSortOptions()}
                                    onSelect={(orderBy, orderDir) => _setQueryOptions({ orderBy, orderDir })}
                                />
                            </div>
                        </div>
                        <div
                            ref={contentRef}
                            className='position-relative overflow-auto flex-grow-1 px-2 pb-3'
                            style={{ height: '55vh' }}
                        >
                            {content}
                        </div>
                    </div>
                </div>
            }
            onVisibilityChange={(isVisible) => {
                if (!isVisible) context.dispatch({ type: UIOptionActionType.ClearFootprintSearch })
            }}
        />
    )
}
