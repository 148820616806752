import { useContext, useState } from 'react'
import OnboardingService, { Onboarding, OnboardingActionType } from '../services/onboarding'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'

export const useOnboarding = () => {
    const context = useContext(ApplicationContext)
    const { analyticsService } = useContext(VariableServicesContext)
    const onboardingService = new OnboardingService(context)
    const [fetching, setFetching] = useState<boolean>(false)

    const setOnboarding = (onboarding: Onboarding) => {
        context.dispatch({ type: OnboardingActionType.Set, payload: onboarding })
    }

    const onStepChange = (step: number) => {
        analyticsService.track(`Onboarding Product Tour: Step ${step}`)
    }

    const saveOnboarding = async (ob?: Onboarding): Promise<void> => {
        if (ob) return onboardingService.saveOnboarding(ob).then(setOnboarding)
    }

    const fetchOnboarding = async (): Promise<void> => {
        if (!fetching && !context.stores.onboarding) {
            setFetching(true)
            return onboardingService
                .getOnboarding()
                .then(setOnboarding)
                .finally(() => setFetching(false))
        }
    }

    const setOnboardingStep = (step: number) => {
        setOnboarding({ ...context.stores.onboarding, step })
        onStepChange(step)
    }

    const previousOnboardingStep = () => {
        const nextStep = (context.stores.onboarding?.step || 0) - 1
        setOnboarding({ ...context.stores.onboarding, step: nextStep })
        onStepChange(nextStep)
    }

    const nextOnboardingStep = () => {
        const nextStep = (context.stores.onboarding?.step || 0) + 1
        setOnboarding({ ...context.stores.onboarding, step: nextStep })
        onStepChange(nextStep)
    }

    return {
        setOnboarding,
        setOnboardingStep,
        previousOnboardingStep,
        nextOnboardingStep,
        fetchOnboarding,
        saveOnboarding,
    }
}
