import { useContext, useEffect, useState } from 'react'
import { ApplicationContext } from '../context'
import { Part } from '../services/part'
import InputService, { Input, InputActionType } from '../services/input'

export const usePartForElementCreator = (props: { onCreate: (part: Part) => void }) => {
    const context = useContext(ApplicationContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [instanceId] = useState<string>(`element-creator-${Math.random()}`)

    useEffect(() => {
        const inputContext = context.stores.input
        const _input = InputService.getById(inputContext?.currentId)
        if (
            !isOpen ||
            !_input?.part?.uuid ||
            inputContext?.instanceId !== instanceId ||
            inputContext.action !== 'create-part'
        ) {
            return
        }
        props.onCreate(_input?.part)
        setIsOpen(false)
    }, [context.stores.input?.currentId, context.stores.input?.updates])

    return (input: Input) => {
        setIsOpen(true)
        // console.log('open', instanceId, input.name)
        context.dispatch({
            type: InputActionType.CreatePartForInput,
            payload: { instanceId, action: 'create-part', currentId: input?.uuid },
        })
    }
}
