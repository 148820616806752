import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../context'
import UseStageService, { UseStageType } from '../services/useStage'

export const useUseStageType = (props: {
    useStageType?: UseStageType | null
    useStageTypeId?: string
    condition?: boolean
}) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.useStageTypeId || props.useStageType?.uuid || ''
        if (id === UseStageService.newId) return UseStageService.getEmptyUseStageType()
        return UseStageService.getById(id) || props.useStageType || undefined
    }, [props.useStageTypeId, props.useStageType?.uuid, context.stores.useStageType?.updates, props.condition])
}
