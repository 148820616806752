import { ListResponse, QueryOptions, VariableBaseNode } from '../types'
import { Org } from './org'
import { User } from './user-context'
import Utils from './utils'
import { Part } from './part'
import VariableService from './service'
import { Product } from './product'
import { TransportType } from './transport'
import { GeoLocation } from './geoLocation'
import { Location } from './location'
import { HttpMethod } from './http'
import { UIOptionActionType } from './ui'

export interface Plan extends VariableBaseNode {
    name: string
    order?: number
    description?: string
    color?: string
    mitigationPotential?: number
    unitCost?: number
    totalCost?: number
    startDate?: number
    deadline?: number
    org?: Org
    part?: Part
    product?: Product
    transportType?: TransportType
    location?: Location | GeoLocation
    owner?: User
}

export interface Timeline extends VariableBaseNode {
    items: TimelineItem[]
    targets: TimelineTarget[]
    baselineYear?: number
    useVariableData?: boolean
    baselineEmissions?: number
    growthRate?: number
    reductionRate?: number
}

export interface TimelineItem extends VariableBaseNode {
    timeline?: Partial<Timeline>
    year: number
    isBaseline?: boolean
    growthRate?: number
    totalEmissions?: number
    reductionRate?: number
    totalReduction?: number
}

export interface TimelineTarget extends VariableBaseNode {
    timeline?: Partial<Timeline>
    year?: number
    reductionRate?: number
    totalReduction?: number
}

export default class PlanService extends VariableService {
    private basePath: string = '/plan'
    public static webRoot: string = '/plan'
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Transition plan', plural ? 2 : 1)

    public static getColor(idx?: number): string {
        return Utils.chartColors[idx || 0]
    }

    private static planById: Map<string, Plan> = new Map()
    private static timelineById: Map<string, Timeline> = new Map()
    private static targetById: Map<string, TimelineTarget> = new Map()

    public static getPlanById(planId?: string): Plan | undefined {
        if (!planId) return undefined
        return PlanService.planById.get(planId)
    }

    public static getTimelineById(timelineId?: string): Timeline | undefined {
        if (!timelineId) return undefined
        return PlanService.timelineById.get(timelineId)
    }

    public static getTargetById(targetId?: string): TimelineTarget | undefined {
        if (!targetId) return undefined
        return PlanService.targetById.get(targetId)
    }

    public static getPlanList(): Plan[] {
        return Array.from(PlanService.planById.values())
    }

    public static updatePlanContext(plans: Plan[]): void {
        plans.forEach((p, idx) => {
            if (p.uuid) {
                if (!p.color) p.color = this.getColor(idx)
                const existing = this.planById.get(p.uuid)
                p.totalCost =
                    p.unitCost && p.mitigationPotential
                        ? Utils.Decimal(p.unitCost || 0)
                              .times(p.mitigationPotential || 0)
                              .toNumber()
                        : undefined
                PlanService.planById.set(p.uuid, { ...existing, ...p })
            }
        })
    }

    public getPlanList(plans: Plan[], queryOptions?: QueryOptions): Plan[] {
        const sortKey = queryOptions?.orderBy
        const _plans: Plan[] = plans.sort((a, b) => {
            let aNumber: number | undefined = undefined
            let bNumber: number | undefined = undefined
            if (sortKey === 'startDate') {
                aNumber = a.startDate || 0
                bNumber = b.startDate || 0
            } else if (sortKey === 'endDate') {
                aNumber = a.deadline || 0
                bNumber = b.deadline || 0
            } else if (sortKey === 'reduction') {
                aNumber = a.mitigationPotential || 0
                bNumber = b.mitigationPotential || 0
            } else if (sortKey === 'cost') {
                aNumber = a.unitCost || 0
                bNumber = b.unitCost || 0
            } else if (sortKey === 'total') {
                aNumber = a.totalCost || 0
                bNumber = b.totalCost || 0
            }
            if (aNumber !== undefined && bNumber !== undefined) {
                return aNumber - bNumber
            }

            let aName = a.name
            let bName = b.name
            if (sortKey === 'org') {
                aName = a.org?.name || ''
                bName = b.org?.name || ''
            } else if (sortKey === 'source') {
                aName = a.product?.name || a.part?.name || a.transportType?.name || a.location?.name || ''
                bName = b.product?.name || b.part?.name || b.transportType?.name || b.location?.name || ''
            } else if (sortKey === 'assignedTo') {
                aName = a.owner?.name || ''
                bName = b.owner?.name || ''
            }
            return aName.localeCompare(bName)
        })
        if (queryOptions?.orderDir === 'DESC') _plans.reverse()
        return _plans
    }

    public setPlan(planId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetPlanId, payload: planId })
    }

    public setTimeline(timelineId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetTimelineId, payload: timelineId })
    }

    public setTarget(targetId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetTargetId, payload: targetId })
    }

    public updatePlanContext(plans: Plan[]): void {
        PlanService.updatePlanContext(plans)
        this.context.dispatch({ type: UIOptionActionType.PlansUpdated })
    }

    public static updateTargetContext(targets: TimelineTarget[]): void {
        targets.forEach((tt) => tt.uuid && PlanService.targetById.set(tt.uuid, tt))
    }

    public updateTargetContext(targets: TimelineTarget[]): void {
        PlanService.updateTargetContext(targets)
        this.context.dispatch({ type: UIOptionActionType.TimelinesUpdated })
    }

    public updateTimelineContext(timelines: Timeline[]): Timeline[] {
        timelines.forEach((t) => {
            if (t.uuid) PlanService.timelineById.set(t.uuid, t)
            PlanService.updateTargetContext(t.targets)
        })
        this.context.dispatch({ type: UIOptionActionType.TimelinesUpdated })
        return timelines
    }

    public async getPlans(queryString?: string): Promise<ListResponse<Plan>> {
        return this.httpService.get<ListResponse<Plan>>(`${this.basePath}?${queryString || ''}`).then((plr) => {
            this.updatePlanContext(plr.data)
            return plr
        })
    }

    public async createOrUpdatePlan(plan: Partial<Plan>): Promise<Plan> {
        const method: HttpMethod = plan.uuid ? 'put' : 'post'
        return this.httpService.fetch<Plan>(method, this.basePath, { body: JSON.stringify({ plan }) }).then((p) => {
            this.updatePlanContext([p])
            return p
        })
    }

    public async deletePlan(plan: Plan): Promise<Plan> {
        return this.httpService.delete<Plan>(`${this.basePath}/${plan.uuid}`).then((p) => {
            plan.uuid && PlanService.planById.delete(plan.uuid)
            this.context.dispatch({ type: UIOptionActionType.PlansUpdated })
            return p
        })
    }

    public async getTimelines(): Promise<Timeline[]> {
        return this.httpService
            .get<Timeline[]>(`${this.basePath}/timeline`)
            .then((tl) => this.updateTimelineContext(tl))
    }

    public async saveTimeline(timeline: Partial<Timeline>): Promise<Timeline> {
        return this.httpService
            .post<Timeline>(`${this.basePath}/timeline`, { body: JSON.stringify({ timeline }) })
            .then((t) => {
                this.updateTimelineContext([t])
                return t
            })
    }

    public async saveTimelineItem(item: Partial<TimelineItem>): Promise<void> {
        this.httpService
            .post<Timeline[]>(`${this.basePath}/timeline/${item.uuid}`, { body: JSON.stringify({ item }) })
            .then((t) => this.updateTimelineContext(t))
    }

    public async saveTarget(target: Partial<TimelineTarget>): Promise<TimelineTarget> {
        return this.httpService
            .post<TimelineTarget>(`${this.basePath}/target`, { body: JSON.stringify({ target }) })
            .then((tt) => {
                if (this.context.stores.ui?.targetId === 'new') this.setTarget(tt.uuid)
                this.updateTargetContext([tt])
                return tt
            })
    }

    public async deleteTarget(targetId: string): Promise<void> {
        if (!targetId) return
        return this.httpService.delete<TimelineTarget>(`${this.basePath}/target/${targetId}`).then(() => {
            PlanService.targetById.delete(targetId)
            this.context.dispatch({ type: UIOptionActionType.TimelinesUpdated })
        })
    }
}
