import { DataQuality, Product, ProductType, ProductVisibility } from './services/product'
import { ActivityState } from './services/activity'
import { Company, CompanyPerspective } from './services/company'
import { Input } from './services/input'
import { Part, PartType } from './services/part'
import { DataRequest, DataRequestState } from './services/dataRequest'
import { Unit } from './services/unit'
import { Org } from './services/org'
import { User } from './services/user-context'
import { CategoryModelConfigValue } from './services/category-model'
import { CSSProperties, ReactNode } from 'react'
import { CalculatorInput } from './services/calculator'
import { ManipulateType } from 'dayjs'
import { DataImport } from './services/dataImport'
import { UseStageCategory } from './services/useStage'
import { InventoryType } from './services/inventory'
import { ElectricityCertificate, ElectricityMethod } from './services/electricity'
import { GoogleMapsGeometryLocationType, GoogleMapsType } from './services/geoLocation'

export interface VariableBaseNode {
    uuid?: string
    varId?: string
    syncId?: string
    databaseId?: string
    dataImportId?: string
    name?: string
    labels?: ND[]
    createdBy?: Partial<User>
    updatedBy?: Partial<User>
    createdIn?: Partial<Company>
    created?: number
    updated?: number
}

export interface VarIdNode extends VariableBaseNode {
    prefix?: string
    prefixes?: string[]
    currentId?: number
}

export enum ND {
    ActivityItem = 'ActivityItem',
    ByproductType = 'ByproductType',
    CalculatorInput = 'CalculatorInput',
    Company = 'Company',
    Contact = 'Contact',
    DataImport = 'DataImport',
    DataRequest = 'DataRequest',
    DataSource = 'DataSource',
    Document = 'Document',
    EPD = 'EPD',
    ElectricityCertificate = 'ElectricityCertificate',
    EmissionFactor = 'EmissionFactor',
    Input = 'Input',
    Location = 'Location',
    Model = 'Model',
    Org = 'Org',
    Part = 'Part',
    ProcessingType = 'ProcessingType',
    Product = 'Product',
    RenewableProduction = 'RenewableProduction',
    Taxonomy = 'Taxonomy',
    TransportType = 'TransportType',
    User = 'User',

    PartToken = 'PartToken',
    SupplierToken = 'SupplierToken',
    CustomerToken = 'CustomerToken',
    DataRequestToken = 'DataRequestToken',
    ProductToken = 'ProductToken',
    EmbedToken = 'EmbedToken',
    ExchangeToken = 'ExchangeToken',
    PassportToken = 'PassportToken',
    DataVizToken = 'DataVizToken',
    UserInviteToken = 'UserInviteToken',
}

export type VariableNode =
    | Product
    | Part
    | Company
    | Input
    | Unit
    | Org
    | User
    | UseStageCategory
    | CategoryModelConfigValue
    | CalculatorInput
    | DataImport
    | DataRequest
    | ElectricityCertificate

export type VariableNodeWithState = VariableNode & { state?: ActivityState }

export type VariableNodeWithSyncId = VariableNode & { syncId?: string }

export interface UsedIn {
    type: ND
    uuid: string
    name: string
    companyType?: CompanyPerspective
    partType?: PartType
    productType?: ProductType
}

export interface StandardAttributes {
    ariaLabel?: string
    className?: string
    extraClassName?: string
    style?: CSSProperties
    hidden?: boolean
    disabled?: boolean
}

export type CO2eDisplay = 'Upstream' | 'Downstream' | 'Embodied' | 'Total'

export interface MeasuredIn extends VariableBaseNode {
    quantity?: number
}

export interface Amount extends MeasuredIn {
    unit?: Unit
}

export const UNLIMITED_AMOUNT = 100_000_000

export type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

export enum HttpErrors {
    UNAUTHORIZED = 'Unauthorized',
    TOKEN_EXPIRED = 'token expired',
}

export interface KeyValuePair<T> {
    isHeader?: boolean
    name: ReactNode
    icon?: ReactNode
    text?: string
    value: T
    description?: ReactNode
    indent?: number
    metaData?: any
    disabled?: boolean
    node?: VariableNode
}

export interface HealthCheck {
    message: string
    apiVersion: string
    uiVersion: string
    uptime: number
    timestamp: string
}

export class GeneralError extends Error {
    public status: number = -1

    public setStatus(status: number) {
        this.status = status
    }
}

export type GenericObject = {
    [key: string | number]: any
}

export interface ListResponse<T> {
    data: T[]
    ids?: string[]
    count: number
    page: number
    pageSize?: number
    totalCo2e?: number
    totalsByUnit?: UnitTotal[]
    uncategorizedCo2e?: number
    queryString?: string
    query?: string
    startDate?: number
    endDate?: number
}

export interface UnitTotal {
    unitCode: string
    quantity: number
}

export interface Key extends VariableBaseNode {
    username?: string
    password?: string
    token?: string
}

export type OrderDir = 'ASC' | 'DESC'

export type QueryWhen = 'all' | 'mtd' | 'ytd' | 'last-year' | 'year' | 'range'

export interface QueryOptions {
    [key: string]: any

    page?: number
    limit?: number
    orderBy?: string
    orderDir?: OrderDir
    offset?: number
    filterBy?: ND | 'state'
    query?: string
    id?: string
    depth?: string
    slim?: boolean
    t?: string

    when?: QueryWhen
    startDate?: number
    endDate?: number
    period?: TimePeriod
    year?: number
    sd?: string
    ed?: string

    supplier?: string
    customer?: string
    productOf?: string
    source?: string
    part?: string
    product?: string
    inventory?: string
    org?: string
    taxonomy?: string
    'data-request'?: string
    'data-import'?: string
    transportType?: string
    category?: string
    unitType?: string
    elementType?: string
    inventoryTypes?: InventoryType[]
    activityStates?: ActivityState

    productVisibility?: ProductVisibility[]
    minProductVisibility?: ProductVisibility

    excludeIds?: string[]

    locationTypes?: GoogleMapsType[]
    geometryLocationTypes?: GoogleMapsGeometryLocationType[]

    quality?: DataQuality[]

    method?: ElectricityMethod

    archived?: boolean

    db?: boolean
    admin?: boolean
}

export type VariableNodeState = ActivityState | DataRequestState

export interface CO2ByYear {
    year: number
    co2e: number
    marketCo2e?: number
}

export interface FriendlyNumber {
    value: number
    name: string
}

export type TimePeriod = 'day' | 'week' | 'month' | 'quarter' | 'year'

export const timePeriodList: KeyValuePair<TimePeriod>[] = [
    { name: 'Day', value: 'day' },
    // { name: 'Week', value: 'week' },
    { name: 'Month', value: 'month' },
    { name: 'Quarter', value: 'quarter' },
    { name: 'Year', value: 'year' },
]

export interface TimePeriodFormat {
    date: string
    short: string
    friendly: string
    incrementBy: number
    manipulateType: ManipulateType
    period: TimePeriod
}

export const DefaultQueryOptions: QueryOptions = {
    limit: 10,
    offset: 0,
}

export type VisualDirection = 'horizontal' | 'vertical'

export type D3Data = {
    key: string
    keyLabel?: string
    name: string
    valueLabel: string
    amount?: number
    color?: string
    barSize?: number
    className?: string
    style?: CSSProperties
    order?: number
}

export type D3Margin = {
    top: number
    right: number
    bottom: number
    left: number
}

export type GeographyRegionOrSubregion =
    | 'Africa'
    | 'Americas'
    | 'Asia'
    | 'Europe'
    | 'Oceania'
    | 'Australia and New Zealand'
    | 'Central Asia'
    | 'Eastern Asia'
    | 'Eastern Europe'
    | 'Latin America and the Caribbean'
    | 'Melanesia'
    | 'Micronesia'
    | 'Northern Africa'
    | 'Northern America'
    | 'Northern Europe'
    | 'Polynesia'
    | 'South-eastern Asia'
    | 'Southern Asia'
    | 'Southern Europe'
    | 'Sub-Saharan Africa'
    | 'Western Asia'
    | 'Western Europe'

export type UUID_V4 = string // f4b1225a-bd44-4c8e-861d-079e4e1dfd69
export type URN_ID = string // urn:uuid:f81d4fae-7dec-11d0-a765-00a0c91e6bf6
export type Decimal = number
export type KgCO2e = Decimal
export type UTC_ISO_8601 = string // 2018-05-17T17:37:00Z
export type ISO_3166_2_Subdivision_Code = string // US-CA, FR-75, etc.
export type ISO_3166_CC = string // US, NO, etc.

export class UnknownError extends Error {}
