import { useCallback, useContext, useEffect, useState } from 'react'
import TaxonomyService, { Taxonomy } from '../services/taxonomy'
import { Product } from '../services/product'
import { FootprintSearchConfig, UIOptionActionType } from '../services/ui'
import { Part } from '../services/part'
import { ApplicationContext } from '../context'
import { TransportType } from '../services/transport'

export interface FootprintSelector {
    searchConfig?: FootprintSearchConfig
    onSelectProduct: (footprint?: Product, taxonomy?: Taxonomy, searchConfig?: FootprintSearchConfig) => void
    onSelectPart?: (part?: Part, taxonomy?: Taxonomy, searchConfig?: FootprintSearchConfig) => void
    onSelectTransport?: (transport?: TransportType, taxonomy?: Taxonomy, searchConfig?: FootprintSearchConfig) => void
    onSelectTaxonomy?: (taxonomy?: Taxonomy, searchConfig?: FootprintSearchConfig) => void
}

export const useFootprintSelector = (props: FootprintSelector) => {
    const context = useContext(ApplicationContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [searchConfig, setSearchConfig] = useState<FootprintSearchConfig | undefined>(props.searchConfig)

    const reset = useCallback(() => {
        context.dispatch({ type: UIOptionActionType.ClearFootprintSearch })
        setIsOpen(false)
        setSearchConfig(undefined)
    }, [])

    useEffect(() => {
        const fsc = context.stores.ui?.footprintSearchConfig
        if (!isOpen || fsc?.instanceId !== searchConfig?.instanceId) {
            return
        }
        let _tx = TaxonomyService.getById(fsc?.queryOptions?.taxonomy)
        if (fsc?.footprint) {
            props.onSelectProduct(fsc?.footprint, _tx, searchConfig)
        } else if (props.onSelectPart && fsc?.part) {
            props.onSelectPart(fsc?.part, _tx, searchConfig)
        } else if (props.onSelectTransport && fsc?.transportType) {
            if (!_tx) _tx = TaxonomyService.getByPath('transport')
            props.onSelectTransport(fsc?.transportType, _tx, searchConfig)
        } else if (props.onSelectTaxonomy && _tx) {
            props.onSelectTaxonomy(_tx, searchConfig)
        }
        if (fsc?.footprint || fsc?.part || fsc?.transportType) {
            reset()
        }
    }, [
        searchConfig,
        context.stores.ui?.footprintSearchConfig?.instanceId,
        context.stores.ui?.footprintSearchConfig?.footprint,
        context.stores.ui?.footprintSearchConfig?.part,
        context.stores.ui?.footprintSearchConfig?.transportType,
        context.stores.ui?.footprintSearchConfig?.queryOptions?.taxonomy,
        context.stores.ui?.taxonomyReady,
    ])

    return function showFootprintModal(searchConfig?: FootprintSearchConfig) {
        setIsOpen(true)
        const sc = { ...props.searchConfig, ...searchConfig, visible: true }
        setSearchConfig(sc)
        context.dispatch({ type: UIOptionActionType.SetFootprintSearchConfig, payload: sc })
    }
}
