import { useContext, useMemo } from 'react'
import { InventoryService } from '../services/inventory'
import { Product } from '../services/product'
import { ApplicationContext } from '../context'

export const useProduct = (props: { product?: Product | null; productId?: string; condition?: boolean }) => {
    const context = useContext(ApplicationContext)
    return useMemo(() => {
        if (props.condition === false) return undefined
        const id = props.productId || props.product?.uuid || ''
        // if (id === ProductService.newId) return ProductService.getEmptyProduct()
        return InventoryService.getById(id)?.originalProduct || props.product || undefined
    }, [
        props.productId,
        props.product?.uuid,
        context.stores.inventory.updated,
        context.stores.products.preview,
        props.condition,
    ])
}
