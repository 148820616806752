import Utils from '../services/utils'
import { ReactNode, useContext, useMemo, useState } from 'react'
import Tooltip, { TooltipProps } from './Tooltip'
import { Check, CopySimple } from '@phosphor-icons/react'
import { VariableServicesContext } from '../services'

interface CopyAnalyticsData {
    type: string
}

const Copy = (props: {
    value?: string
    disabled?: boolean
    copyIcon?: ReactNode
    iconPlacement?: 'before' | 'after'
    loading?: boolean
    view?: 'icon' | 'text' | 'small-text' | 'button' | 'plain-text'
    tooltipText?: ReactNode
    tooltipProps?: Partial<TooltipProps>
    buttonText?: ReactNode
    buttonClassName?: string
    extraClassName?: string
    wrapText?: boolean
    analyticsData?: CopyAnalyticsData
}) => {
    const { analyticsService } = useContext(VariableServicesContext)
    const [copied, setCopied] = useState<boolean>(false)

    const view = useMemo(() => props.view || 'small-text', [props.view])

    const copyIcon = useMemo(() => {
        if (copied) return <Check />
        return props.copyIcon || <CopySimple />
    }, [copied, props.copyIcon])

    const body = useMemo(() => {
        if (view === 'icon') {
            return copyIcon
        }
        if (view === 'button') {
            return (
                <span className={props.buttonClassName || 'btn btn-sm btn-light text-nowrap'}>
                    {props.iconPlacement === 'before' && <>{copyIcon} </>}
                    {props.buttonText || 'Share'}
                    {props.iconPlacement !== 'before' && <> {copyIcon}</>}
                </span>
            )
        }
        if (view === 'small-text' || view === 'plain-text') {
            return (
                <span className={['d-inline-block', props.extraClassName].join(' ')}>
                    {props.value}
                    {view === 'small-text' ? <> {copyIcon}</> : null}
                </span>
            )
        }
        return (
            <>
                <span className={['px-2 py-1 overflow-auto', props.wrapText === false ? '' : 'text-nowrap'].join(' ')}>
                    {!props.value ? <span className='spinner-border spinner-border-sm nt-2' /> : props.value}
                </span>
                <span className='border-start px-2 py-1 text-center text-body'>{copyIcon}</span>
            </>
        )
    }, [props.value, view, props.wrapText, props.buttonText, props.buttonClassName, copyIcon])

    const className = useMemo(() => {
        if (view === undefined || view === 'text') {
            return `bg-light border d-inline-flex align-items-center max-w-100 ${props.extraClassName || 'w-auto'}`
        }
        return props.extraClassName || ''
    }, [view])

    return (
        <Tooltip
            tooltipClassName='small text-start text-nowrap'
            tooltipContent={
                copied ? (
                    <span>
                        Copied <Check color={Utils.bodyColor} />
                    </span>
                ) : (
                    props.tooltipText || 'Click to copy value'
                )
            }
            disabled={props.disabled || props.loading}
            trigger='hover'
            positioning='fixed'
            className={className}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                Utils.copyToClipboard(props.value, () => Utils.setTrueFor(setCopied, 1500))
                if (props.analyticsData) analyticsService.track('Copied link', props.analyticsData)
            }}
            {...props.tooltipProps}
        >
            {body}
        </Tooltip>
    )
}

export default Copy
