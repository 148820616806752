import { ByproductType, ProcessingType } from '../../services/processing'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ApplicationContext } from '../../context'
import InputField from './InputField'
import Delete from '../Delete'
import { AmountInput } from './Amount'
import { AddSourceProduct } from '../Part/AddSourceProduct'
import { MiniPassport } from '../MiniPassport'
import Utils from '../../services/utils'
import CO2e from '../CO2e'
import { useByproductType } from '../../hooks/useByproductType'
import { ND } from '../../types'

export const ByproductItem = (props: {
    processingType: ProcessingType
    byproduct: ByproductType
    extraClassName?: string
    onChange: (byproduct: ByproductType) => void
    onRemove: (byproduct: ByproductType) => void
}) => {
    const context = useContext(ApplicationContext)
    const [highlightClassName, setHighlightClassName] = useState<string>('')
    const nameRef = useRef<any>()
    const inputRef = useRef<any>()

    const byproductType = useByproductType({ byproductType: props.byproduct })

    const pSave = useCallback(
        (properties?: Partial<ByproductType>) => {
            props.onChange({ ...byproductType, ...properties })
        },
        [byproductType, props.onChange],
    )

    useEffect(() => {
        if (byproductType?.uuid && context.stores.processingType?.byproductId === byproductType?.uuid) {
            setHighlightClassName('bg-secondary bg-opacity-25')
            setTimeout(() => {
                if (!byproductType?.name) {
                    nameRef.current?.focus()
                } else {
                    inputRef.current?.focus()
                }
            }, 301)
            setTimeout(() => setHighlightClassName('transition-all-1s'), 1000)
        }
    }, [])

    return (
        <div className={['d-flex flex-column gap-2', highlightClassName, props.extraClassName].join(' ')}>
            <div className='row'>
                <div className='col small d-flex align-items-center justify-content-between gap-1'>
                    <InputField
                        passedRef={nameRef}
                        placeholder='Name'
                        className='variable-form-control bg-light w-100'
                        defaultValue={byproductType?.name}
                        onChanged={(name) => pSave({ name })}
                    />
                    <Delete
                        deleteFn={async () => byproductType && props.onRemove(byproductType)}
                        className='btn btn-xs text-muted text-primary-hover'
                        iconOnly={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-4 small'>
                    <span className='d-block ps-1 pt-2'>Amount</span>
                </div>
                <div className='col-8'>
                    <AmountInput
                        amount={byproductType?.amount || { unit: byproductType?.footprint?.unit }}
                        inputFieldProps={{
                            passedRef: inputRef,
                            className: 'variable-form-control bg-light text-end',
                        }}
                        unitSelectorProps={{ unitType: byproductType?.footprint?.unit?.type }}
                        onChange={(amount) => pSave({ amount })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-4 small'>
                    <span className='d-block ps-1 pt-2'>Target</span>
                </div>
                <div className='col-8'>
                    {byproductType && (
                        <AddSourceProduct
                            hidden={!!byproductType?.footprint?.uuid}
                            placeholder='Select'
                            btnClassName='btn btn-sm btn-outline-primary w-100'
                            btnHoverClassName=' '
                            btnActiveClassName='btn-primary'
                            targetType={ND.ByproductType}
                            node={byproductType}
                            onDone={(footprint) => pSave({ footprint })}
                        />
                    )}
                    {!!byproductType?.footprint?.uuid && (
                        <div className='d-flex align-items-center justify-content-between gap-2 hover-parent-direct'>
                            <MiniPassport hidden={!byproductType.footprint} product={byproductType.footprint} />
                            <Delete
                                className='btn btn-xs text-muted text-primary-hover show-on-hover'
                                iconOnly={true}
                                deleteFn={async () => pSave({ footprint: null })}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='row small'>
                <div className='col-4'>Total {Utils.co2e}</div>
                <div className='col-8'>
                    <CO2e co2e={byproductType?.co2e} />
                </div>
            </div>
        </div>
    )
}
