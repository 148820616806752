import { Selector, SelectorProps } from './Selector'
import ProductService from '../../services/product'
import { Inventory, InventoryService } from '../../services/inventory'
import { StandardAttributes } from '../../types'

export const InventorySelector = (
    props: StandardAttributes & Omit<SelectorProps, 'onSelect'> & { onSelect: (inventory: Inventory) => void },
) => {
    return (
        <Selector
            placeholder={ProductService.elementTitle()}
            placement='bottom-start'
            options={InventoryService.getList()}
            tooltipStyle={{ maxWidth: '400px' }}
            {...props}
        />
    )
}
