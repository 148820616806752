import Utils from '../services/utils'
import UnitService, { UnitSize } from '../services/unit'
import { HTMLAttributes, useContext, useMemo } from 'react'
import { PrettyNumber, PrettyNumberProps } from './PrettyNumber'
import ProductService, { Product, ProductVisibility } from '../services/product'
import AuthenticationService from '../services/authentication'
import { UnitHeader } from './UnitHeader'
import { ApplicationContext } from '../context'
import Tooltip from './Tooltip'
import { PlanLabel } from './Navigation'
import { SubscriptionService } from '../services/subscription'
import { VariableServicesContext } from '../services'
import Button from './Input/Button'
import { CO2eDisplay } from '../types'
import { Part } from '../services/part'

const prettyUnits = [
    { threshold: 1_000_000_000_000, unitCode: 'gt' },
    { threshold: 1_000_000_000, unitCode: 'mt' },
    { threshold: 1_000_000, unitCode: 't' },
    { threshold: 0.1, unitCode: 'g' },
]

export type UnitString = 'gt' | 'mt' | 't' | 'kg' | 'g'

export interface CO2eProps extends Omit<PrettyNumberProps, 'num' | 'friendlyNumbers' | 'friendlyLargeNumbers'> {
    co2e?: string | number | null
    co2eDisplay?: CO2eDisplay
    updating?: boolean
    spinnerProps?: HTMLAttributes<HTMLSpanElement>
    product?: Product | null
    part?: Part | null
    isPremium?: boolean
    showPremiumWarning?: boolean
    unitSize?: UnitSize
    shortUnit?: boolean
    className?: string
    numberClassName?: string
    unitClassName?: string
    unitsClassName?: string
    functionalUnitClassName?: string
    hidden?: boolean
    showUnit?: boolean
    functionalUnit?: string
    onClick?: () => void
}

const CO2e = (props: CO2eProps) => {
    const context = useContext(ApplicationContext)
    const { companyService } = useContext(VariableServicesContext)

    const co2e = useMemo(() => {
        if (props.product) return ProductService.getCO2e(props.product, props.co2eDisplay)
        if (props.part) return props.part.liveCo2e
        return props.co2e
    }, [props.co2e, props.product, props.part, props.co2eDisplay])

    const co2eNumber = useMemo(() => parseFloat(co2e?.toString()?.trim() || '0'), [co2e])

    const inUnit = useMemo(() => {
        if (props.unitSize === 'large') return context.stores.unitLarge || 't'
        return context.stores.unitSmall || 'kg'
    }, [props.unitSize, context.stores.unitLarge, context.stores.unitSmall])

    const prettyUnit = useMemo(() => {
        const foundUnit = prettyUnits.find((item) => {
            if (inUnit && item.unitCode === inUnit) {
                return true
            } else if (item.threshold > 1 && Math.abs(co2eNumber) > item.threshold) {
                return true
            } else {
                return item.threshold < 1 && Math.abs(co2eNumber) < item.threshold
            }
        })
        return UnitService.unitByCode[inUnit || foundUnit?.unitCode || 'kg']
    }, [inUnit, co2eNumber, UnitService.unitByCode])

    const unit = useMemo(
        () => (co2eNumber === 0 && !inUnit ? UnitService.unitByCode['kg'] : prettyUnit),
        [co2eNumber, prettyUnit, inUnit, UnitService.unitByCode],
    )

    const co2eValue = useMemo(() => co2eNumber * (unit?.toBaseUnit || 1), [co2eNumber, unit?.toBaseUnit])

    const prettyNumber = useMemo(() => {
        let num: number | string | undefined | null = co2eValue
        if (co2e === undefined) num = undefined
        if (co2e === null) num = null
        return (
            <PrettyNumber
                className={props.numberClassName}
                decimalClassName={props.decimalClassName}
                onZero={props.onZero || props.part ? 'dash' : undefined}
                precision={props.precision}
                showZeroesToPrecision={props.showZeroesToPrecision !== false}
                showZeroesToNumber={props.showZeroesToNumber}
                prefix={props.prefix}
                pluralizePrefix={props.pluralizePrefix}
                suffix={props.suffix}
                pluralizeSuffix={props.pluralizeSuffix}
                surround={props.surround}
                num={num}
                onUndefined={props.onUndefined || 'dash'}
                undefinedValue={props.undefinedValue}
            />
        )
    }, [
        co2eValue,
        co2e,
        props.showZeroesToPrecision,
        props.showZeroesToNumber,
        props.precision,
        props.numberClassName,
        props.decimalClassName,
        props.zeroValue,
        props.onZero,
        props.prefix,
        props.pluralizePrefix,
        props.suffix,
        props.pluralizeSuffix,
        props.surround,
        props.onUndefined,
        props.undefinedValue,
        props.product,
        props.part,
    ])

    const premiumProduct = useMemo(() => {
        if (SubscriptionService.isPremium(context.stores.company?.subscription)) return null
        if (!companyService.isMyCompany(props.product?.productOf)) return null
        if (props.isPremium === false || !props.showPremiumWarning) return null
        if (co2e !== null) return null
        return (
            <Tooltip
                trigger='hover'
                placement='top-start'
                interactive={true}
                closeOnInteraction={true}
                tooltipClassName='small p-2'
                tooltipStyle={{ width: '300px' }}
                tooltipContent={
                    <span>
                        <p className='mb-1'>
                            {props.product?.name || `This ${ProductService.elementTitle().toLowerCase()}`} is using
                            premium data.
                        </p>
                        <PlanLabel buttonClassName='btn btn-secondary btn-xs' /> to get access to the Premium database,
                        or remove the line items labeled as premium to get the {Utils.co2e} value.
                    </span>
                }
                className={props.className || 'd-inline-block'}
            >
                <span className='premium-label'>Premium</span>
            </Tooltip>
        )
    }, [props.showPremiumWarning, props.product, context.stores.company?.subscription, props.className, co2e])

    const premiumValue = useMemo(() => {
        if (!props.isPremium && props.product?.co2e !== null && co2e !== null) return null
        if (
            props.product?.visibility === ProductVisibility.AUTHENTICATED_USERS &&
            !AuthenticationService.isOnboarded()
        ) {
            return (
                <span hidden={props.hidden} className='btn btn-sm btn-secondary'>
                    Sign up
                </span>
            )
        }
        return (
            <span hidden={props.hidden} className='premium-label'>
                Premium
            </span>
        )
    }, [props.product, co2e, props.hidden, props.isPremium])

    if (props.hidden || !UnitService.units?.length) return null

    return (
        premiumProduct ||
        premiumValue || (
            <Button
                element='span'
                className={[
                    props.className,
                    'position-relative text-nowrap',
                    props.updating ? 'opacity-50' : '',
                    props.onClick ? 'clickable' : 'cursor-text',
                ].join(' ')}
                onClick={props.onClick}
            >
                {prettyNumber}
                <span className={props.unitsClassName}>
                    {props.showUnit !== false && (
                        <UnitHeader
                            extraClassName={props.unitClassName || 'ms-1'}
                            unitSize={props.unitSize || 'small'}
                        />
                    )}
                    {props.functionalUnit !== undefined && (
                        <span className={props.functionalUnitClassName}>/ {props.functionalUnit}</span>
                    )}
                </span>
                {props.updating && (
                    <span
                        className='position-absolute spinner-border spinner-border-sm ms-1 fs-base text-muted'
                        style={{ marginTop: '2px' }}
                        {...props.spinnerProps}
                    />
                )}
            </Button>
        )
    )
}

export default CO2e
