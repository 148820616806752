import { CSSProperties, ReactNode, useCallback, useContext, useMemo } from 'react'
import { Selector } from '../Input/Selector'
import * as PopperJS from '@popperjs/core'
import { ApplicationContext } from '../../context'
import { LCACode, UseStageCategory, UseStageCategoryState, UseStageCategoryType } from '../../services/useStage'
import InputService from '../../services/input'

export const LifecycleStageSelector = (props: {
    hidden?: boolean
    disabled?: boolean
    className?: string
    extraClassName?: string
    style?: CSSProperties
    placeholder?: string
    placement?: PopperJS.Placement
    category?: UseStageCategory
    onChange: (useStageCategory: UseStageCategory) => void
}) => {
    const context = useContext(ApplicationContext)
    let useStageCategory: UseStageCategory
    let currentType: string
    const headerClassName = 'mt-1 px-2 py-1 text-muted bg-light'

    const options = useMemo(
        () => InputService.getUseStageList().filter((us) => us.state === UseStageCategoryState.Visible),
        [context.stores.ui?.useStagesReady],
    )

    const renderItem = useCallback((option: UseStageCategory, item: ReactNode, id: string) => {
        let extra: ReactNode
        if (option.type !== useStageCategory?.type || option.useStage.name !== useStageCategory?.useStage?.name) {
            useStageCategory = option
            if (option.code === LCACode.A4) {
                extra = <div className={headerClassName}>Downstream: {option.useStage.name}</div>
            } else if (option.type === UseStageCategoryType.DOWNSTREAM) {
                extra = <div className={headerClassName}>Downstream: {option.useStage.name}</div>
            } else if (option.type !== currentType) {
                extra = <div className={headerClassName}>{option.type}</div>
            }
            currentType = option.type
        }
        return (
            <span key={id}>
                {extra}
                {item}
            </span>
        )
    }, [])

    const renderItemValue = useCallback(
        (o: UseStageCategory) => (
            <span className='d-flex align-items-center justify-content-between gap-2'>
                <span>{o.name}</span>
                <span className='text-end text-alt-font'>{o.code}</span>
            </span>
        ),
        [],
    )

    return (
        <Selector
            ariaLabel='Life-cycle stage'
            placeholder={props.placeholder || 'Life-cycle stage'}
            placement={props.placement}
            hidden={props.hidden}
            disabled={props.disabled}
            className={props.className}
            extraClassName={props.extraClassName || 'text-overflow-ellipsis'}
            style={props.style || { width: '8rem' }}
            onSelect={props.onChange}
            option={props.category}
            options={options}
            renderValueLabel={(o) => `${o.code}: ${o.name}`}
            renderItem={renderItem}
            renderItemValue={renderItemValue}
        />
    )
}
